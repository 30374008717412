import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";
import { getNumber } from "../../utils/number";

const SkeletonTypography = ({ loading, children, ...props }) => {
  return loading ? (
    <Skeleton
      variant="text"
      width={40}
      className="skeleton"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        margin: 0
      }}
    />
  ) : (
    <Typography {...props}>{children}</Typography>
  );
};

export default ({ drop, loading, dailyBudget, total }) => {
  return (
    <List disablePadding>
      <ListItem dense disableGutters>
        <ListItemText primary="Tile" />
        <SkeletonTypography variant="body2" loading={loading}>
          {drop && drop.quadkey ? drop.quadkey : "Any"}
        </SkeletonTypography>
      </ListItem>
      <Divider />
      <ListItem dense disableGutters>
        <ListItemText primary="Email" />
        <SkeletonTypography variant="body2" loading={loading}>
          {drop && drop.email ? drop.email : "Any"}
        </SkeletonTypography>
      </ListItem>
      <Divider />
      <ListItem dense disableGutters>
        <ListItemText primary="Link" />
        <SkeletonTypography variant="body2" loading={loading}>
          {drop && drop.link ? drop.link : "None"}
        </SkeletonTypography>
      </ListItem>
      <Divider />
      <ListItem dense disableGutters>
        <ListItemText primary="Note" />
        <SkeletonTypography
          variant="body2"
          loading={loading}
          className="pl-5"
          style={{ wordBreak: "break-all" }}
        >
          {drop && drop.message ? drop.message : "None"}
        </SkeletonTypography>
      </ListItem>
      <Divider />
      <ListItem dense disableGutters>
        <ListItemText primary="Amount" />
        <SkeletonTypography variant="body2" loading={loading}>
          {drop && drop.amount}
        </SkeletonTypography>
      </ListItem>
      <Divider />
      {/* <ListItem dense disableGutters>
        <ListItemText primary="Quantity" />
        <SkeletonTypography variant="body2" loading={loading}>
          {drop && drop.quantity}
        </SkeletonTypography>
      </ListItem>
      <Divider /> */}
      {dailyBudget ? (
        <>
          <ListItem>
            <ListItemText primary={<strong>Daily Budget</strong>} />
            <SkeletonTypography variant="body2" loading={loading}>
              {getNumber(dailyBudget)}
            </SkeletonTypography>
          </ListItem>
          <Divider />
        </>
      ) : null}
      {total ? (
        <>
          <ListItem>
            <ListItemText primary={<strong>Total</strong>} />
            <SkeletonTypography variant="body2" loading={loading}>
              {getNumber(total)}
            </SkeletonTypography>
          </ListItem>
          <Divider />
        </>
      ) : null}
    </List>
  );
};
