import React, { FC, createContext, useContext } from "react";
import Fab from "@material-ui/core/Fab";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";
import Slide from "@material-ui/core/Slide";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { useBooleanControls } from "../../utils/hooks";
import { trackInfo } from "../../utils/track";
import { useBalanceTransfer } from "../User/BalanceTransfer";
import { useUserInfo } from "../../context";

type IAdvancedDropBottomNavContext = [
  boolean,
  {
    setTrue: (v?: any) => any;
    setFalse: (v?: any) => any;
  }
];

export const AdvancedDropBottomNavContext = createContext<
  IAdvancedDropBottomNavContext
>([
  false,
  {
    setTrue: () => console.warn("AdvancedDropBottomNavContext not initialized"),
    setFalse: () => console.warn("AdvancedDropBottomNavContext not initialized")
  }
]);

export const useAdvancedDropBottomNav = () =>
  useContext(AdvancedDropBottomNavContext);

export const AddFab = ({ onClick }: { onClick: () => void }) => {
  const user = useUserInfo()

  const fab = <Fab
    onClick={onClick}
    color="primary"
    disabled={!user?.bossAdmin}
    style={{
      width: 64,
      height: 64,
      padding: 14,
      border: "3px solid #eee"
    }}
  >
    <AddIcon />
  </Fab>

  return (
    <Slide in={Boolean(user)} direction="left">
      {user?.bossAdmin ? fab : <Tooltip 
        title={
          <Typography>We are currently not allowing new sponsored drops</Typography>}>
            <Box>{fab}</Box>
          </Tooltip>
        }
    </Slide>
  );
};

export const AdvancedDropBottomNavProvider: FC = ({ children }) => {
  const [open, modalControls] = useBooleanControls(false);
  const [{ bossBalance }, balanceControls] = useBalanceTransfer();
  const setTrue = () => {
    if (bossBalance) {
      trackInfo("Open Advanced Drop Creator");
      modalControls.setTrue();
    } else {
      balanceControls.handleBalanceRequired(() => {
        trackInfo("Balance Added, Open Advanced Drop Creator");
        balanceControls.setFalse();
        modalControls.setTrue();
      });
    }
  };
  return (
    <AdvancedDropBottomNavContext.Provider
      value={[open, { setFalse: modalControls.setFalse, setTrue }]}
    >
      {children}
    </AdvancedDropBottomNavContext.Provider>
  );
};

export const AdvancedDropBottomNav = () => {
  const [value, navbarControls] = useAdvancedDropBottomNav();
  return (
    <div
      className="position-fixed pb-5 pr-5"
      style={{ bottom: 0, right: 0, zIndex: 1 }}
    >
      <AddFab onClick={navbarControls.setTrue} />{" "}
    </div>
  );
};
