import get from "lodash/get";

export const numberFormat = (n?: number, c?: number) => {
  const digits = typeof c === "number" ? c : 2;
  return (Number(n) || 0).toLocaleString(undefined, {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits
  });
};

export const getNumber = (record: any, source: string, c?: number) => {
  const n = get(record, source);
  return n ? numberFormat(n, c) : "";
};
