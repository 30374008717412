import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { InfoDisplay } from "@xyo-network/coin-forms/build/lib/components/Alert/Display";
import Fab from "@material-ui/core/Fab";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import SendIcon from "@material-ui/icons/Send";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import { useMutation } from "@apollo/react-hooks";
import get from "lodash/get";
import React, { useState, createContext, FC, useContext, useRef } from "react";
import { useAlertContext, useAuth } from "../../context";
import { useBooleanControls } from "../../utils/hooks";
import { numberFormat } from "../../utils/number";
import { trackInfo, trackError } from "../../utils/track";
import gql from "graphql-tag";

interface IBalanceTransferState {
  open: boolean;
  loading: string;
  coinBalance: number;
  bossBalance: number;
  deposit: number;
  withdraw: number;
}

type IBalanceTransferActions = {
  handleDepositChange: (ev: any) => any;
  handleDepositMax: (ev: any) => any;
  handleDepositSubmit: (ev: any) => any;
  handleWithdrawChange: (ev: any) => any;
  handleWithdrawMax: (ev: any) => any;
  handleWithdrawSubmit: (ev: any) => any;
  handleBalanceRequired: (ev: any) => any;
} & ReturnType<typeof useBooleanControls>[1];
type IBalanceTransferContext = [IBalanceTransferState, IBalanceTransferActions];

const notInitialized = () =>
  console.warn("BalanceTransferProvider not initialized") as any;

const BalanceTransferContext = createContext<IBalanceTransferContext>([
  {
    open: false,
    loading: "",
    coinBalance: 0,
    bossBalance: 0,
    deposit: 0,
    withdraw: 0,
  },
  {
    toggle: notInitialized,
    setTrue: notInitialized,
    setFalse: notInitialized,
    handleDepositChange: notInitialized,
    handleDepositMax: notInitialized,
    handleDepositSubmit: notInitialized,
    handleWithdrawChange: notInitialized,
    handleWithdrawMax: notInitialized,
    handleWithdrawSubmit: notInitialized,
    handleBalanceRequired: notInitialized,
  },
]);

export const useBalanceTransfer = () => useContext(BalanceTransferContext);
const parseNumber = (n: number) => parseFloat(n.toFixed(2));

export const BalanceTransferProvider: FC = ({ children }) => {
  const { user, userInfo, refetchUserInfo } = useAuth();
  const [transferBalance, transferBalanceState] = useMutation(TRANSFER_BALANCE);
  const [{}, { setSuccess, setError, setInfo }] = useAlertContext();
  const [loading, setLoading] = useState("");
  const [open, modalControls] = useBooleanControls(false);
  const [withdraw, setWithdraw] = useState(0);
  const [deposit, setDeposit] = useState(0);
  const onBalanceAddedRef = useRef((amount: any) => {});
  const xyoBossBalance = get(userInfo, "xyoBossBalance") || 0;
  const xyoBossGifted = get(userInfo, "xyoBossGifted") || 0;
  const xyoCollected = get(userInfo, "xyoCollected") || 0;
  const xyoGifted = get(userInfo, "xyoGifted") || 0;
  const handleWithdrawMax = (ev: any) => {
    setWithdraw(parseNumber(xyoBossBalance - xyoBossGifted));
    if (xyoBossGifted) {
      setInfo(
        `${numberFormat(
          xyoBossGifted
        )}COIN can not be withdrawn because it was gifted.`
      );
    }
  };
  const handleDepositMax = (ev: any) => {
    setDeposit(parseNumber(xyoCollected - xyoGifted));
  };
  const handleWithdrawChange = (ev: any) => {
    const value = Number(ev.target.value);
    if (isNaN(value)) return;
    if (value > xyoBossBalance - xyoBossGifted) {
      setError(
        `Only ${numberFormat(
          xyoBossBalance - xyoBossGifted
        )} available to withdraw.`
      );
    } else {
      setWithdraw(ev.target.value);
    }
  };
  const handleDepositChange = (ev: any) => {
    const value = Number(ev.target.value);
    if (isNaN(value)) return;
    if (value > xyoCollected - xyoGifted) {
      setError(
        `Only ${numberFormat(xyoCollected - xyoGifted)} available to deposit.`
      );
    } else {
      setDeposit(ev.target.value);
    }
  };
  const handleBalanceRequired = (onBalanceAdded: (amount: any) => {}) => {
    trackInfo("Open Advanced Drop Creator No Balance");
    onBalanceAddedRef.current = onBalanceAdded;
    modalControls.setTrue();
  };
  const handleWithdrawSubmit = async (ev: any) => {
    if (!user) return;
    try {
      setWithdraw(0);
      setLoading("withdraw");
      await transferBalance({
        variables: {
          from: "xyoBossBalance",
          to: "xyoCollected",
          value: Number(withdraw),
        },
      });
      await refetchUserInfo();
      setLoading("");
      setSuccess(`Successful withdrawal for ${numberFormat(withdraw)}`);
      trackInfo("Successful withdrawal", { withdraw: numberFormat(withdraw) });
    } catch (e) {
      setError(e);
      setLoading("");
      trackError("Withdrawal error", e);
    }
  };
  const handleDepositSubmit = async (ev: any) => {
    if (!user) return;
    try {
      setDeposit(0);
      setLoading("deposit");
      await transferBalance({
        variables: {
          from: "xyoCollected",
          to: "xyoBossBalance",
          value: Number(deposit),
        },
      });
      await refetchUserInfo();
      setLoading("");
      setSuccess(`Successful deposit of ${numberFormat(deposit)}`);
      trackInfo("Successful deposit", { deposit: numberFormat(deposit) });
      if (deposit) {
        onBalanceAddedRef.current(deposit);
        onBalanceAddedRef.current = () => {};
      }
    } catch (e) {
      setError(e);
      setLoading("");
      trackError("Deposit error", e);
    }
  };
  const state = {
    open,
    loading,
    deposit,
    withdraw,
    coinBalance: xyoCollected - xyoGifted,
    bossBalance: xyoBossBalance,
  };
  const actions = {
    ...modalControls,
    handleDepositChange,
    handleDepositMax,
    handleDepositSubmit,
    handleWithdrawChange,
    handleWithdrawMax,
    handleWithdrawSubmit,
    handleBalanceRequired,
  };
  return (
    <BalanceTransferContext.Provider value={[state, actions]}>
      {children}
      <BalanceTransferDialog />
    </BalanceTransferContext.Provider>
  );
};

function BalanceTransferDialog() {
  const [
    { open, loading, coinBalance, bossBalance, deposit, withdraw },
    {
      setFalse,
      handleDepositChange,
      handleDepositMax,
      handleDepositSubmit,
      handleWithdrawChange,
      handleWithdrawMax,
      handleWithdrawSubmit,
    },
  ] = useBalanceTransfer();
  return (
    <Dialog open={open} onClose={() => setFalse()}>
      <DialogTitle>COIN Boss Transfer</DialogTitle>
      <DialogContent>
        <Typography paragraph>Deposit</Typography>
        <Box mb={2}>
          <Grid container spacing={1}>
            <Grid item xs>
              <TextField
                className="m-0"
                value={numberFormat(coinBalance)}
                label="COIN Balance"
                variant="outlined"
                margin="dense"
                disabled={true}
                fullWidth
              />
            </Grid>
            <Grid item xs>
              <TextField
                className="m-0"
                value={deposit}
                onChange={handleDepositChange}
                helperText={
                  <Typography
                    component="span"
                    color="secondary"
                    onClick={handleDepositMax}
                    style={{ fontSize: "inherit", cursor: "pointer" }}
                  >
                    Max Amount
                  </Typography>
                }
                label="Deposit Amount"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item>
              <Tooltip title="Transfer From COIN Balance">
                <div>
                  <Fab
                    size="small"
                    onClick={handleDepositSubmit}
                    disabled={!deposit || !!loading}
                  >
                    {loading === "deposit" ? (
                      <CircularProgress />
                    ) : (
                      <SendIcon />
                    )}
                  </Fab>
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>

        <Typography paragraph>Withdraw</Typography>
        <Box mb={4}>
          <Grid container spacing={1}>
            <Grid item xs>
              <TextField
                className="m-0"
                value={numberFormat(bossBalance)}
                label="BOSS Balance"
                variant="outlined"
                margin="dense"
                disabled={true}
                fullWidth
              />
            </Grid>
            <Grid item xs>
              <TextField
                className="m-0"
                value={withdraw}
                onChange={handleWithdrawChange}
                helperText={
                  <Typography
                    component="span"
                    color="secondary"
                    onClick={handleWithdrawMax}
                    style={{ fontSize: "inherit", cursor: "pointer" }}
                  >
                    Max Amount
                  </Typography>
                }
                label="Withdrawal Amount"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item>
              <Tooltip title="Transfer To COIN Balance">
                <div>
                  <Fab
                    size="small"
                    onClick={handleWithdrawSubmit}
                    disabled={!withdraw || !!loading}
                  >
                    {loading === "withdraw" ? (
                      <CircularProgress />
                    ) : (
                      <SendIcon />
                    )}
                  </Fab>
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}

function waitFor(timer = 0) {
  return new Promise((res) => setTimeout(() => res(), timer));
}

const USER_FRAGMENT = gql`
  fragment UserInfo on UserInfo {
    id
    lastMaphash
    xyoRedeemed
    xyoPending
    xyoRedeemPending
    xyoDropped
    xyoCollected
    xyoGifted
    xyoPickedUp
    xyoGeoclaimed
    xyoHodlRewards
    xyoBossBalance
    xyoEnabled
    hodlLastCollectedYYYYMMDD
    recordVersion
    remineMarker
    recentCount
    color
    referrer
    checkInDate
    created
    appVersion
    referrerUid
    addedToDrip
    mineCountNoSentinel
    ibeacons
    remineCount
    subscriptionInfo
    osDevMode
    ownerUID
    osVersion
    mineCount
    recentMarker
    mineCountSentinel
    direction
    activeSubscription
    subActive
    ip
    lastMinedYYYYMMDD
    verifiedAddressesTotal
    sentinels
    random
    lastMined
    lastSync
    ssid
    os
    deviceId
    deviceName
    speed
  }
`;

const TRANSFER_BALANCE = gql`
  mutation TransferBalance($from: String!, $to: String!, $value: Float!) {
    transferBalance(from: $from, to: $to, value: $value) {
      ...UserInfo
    }
  }

  ${USER_FRAGMENT}
`;
