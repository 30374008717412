import staticService from "@mapbox/mapbox-sdk/services/static";
import { origin } from "quadkeytools";
import React, { useMemo } from "react";
import { featureCollection, polygonType, quadkeyType } from "../../utils/map";
import get from "lodash/get";

const mapboxClient = staticService({
  accessToken: process.env.REACT_APP_MAPBOX
});

const appendProperties = properties => obj => ({ ...obj, properties });

export const QuadkeyMap = ({ quadkey, style }) => {
  const imgSrc = useMemo(() => {
    try {
      const { lng, lat } = origin(quadkey);
      const path = featureCollection(quadkeyType)([
        {
          quadkey
        }
      ]);
      const coordinates = get(path, "features[0].geometry.coordinates[0]");
      const url = mapboxClient
        .getStaticImage({
          ownerId: "mapbox",
          styleId: "dark-v10",
          width: 300,
          height: 300,
          position: {
            pitch: 60,
            bearing: 180,
            zoom: quadkey.length - 2,
            coordinates: [lng, lat]
          },
          overlays: [
            {
              path: {
                strokeOpacity: 0.7,
                strokeColor: "#F89D42",
                strokeWidth: 4,
                coordinates
              }
            }
          ]
        })
        .url();
      return url;
    } catch (e) {
      console.warn("Static image", e.message);
      return "";
    }
  }, [quadkey]);
  return (
    <div
      style={{
        ...style,
        backgroundImage: `url("${imgSrc}")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      }}
    ></div>
  );
};

export const BoundsMap = ({ coordinates, style }) => {
  const imgSrc = useMemo(() => {
    const geoJson = featureCollection(({ coordinates, ...properties }) => ({
      type: "Feature",
      properties,
      geometry: {
        type: "Polygon",
        coordinates
      }
    }))([{ coordinates, "fill-opacity": 0.7, fill: "#5D3A7B" }]);

    return `${mapboxClient
      .getStaticImage({
        ownerId: "mapbox",
        styleId: "dark-v10",
        width: 300,
        height: 300,
        position: "auto",
        overlays: [{ geoJson }]
      })
      .url()}?access_token=${process.env.REACT_APP_MAPBOX}`;
  }, [coordinates]);
  return (
    <div
      style={{
        ...style,
        backgroundImage: `url("${imgSrc}")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      }}
    ></div>
  );
};

export const MultiPolygonMap = ({ coordinates, ...props }) => {
  const imgSrc = useMemo(() => {
    const geoJson = featureCollection(polygonType)(coordinates);

    return `${mapboxClient
      .getStaticImage({
        ownerId: "mapbox",
        styleId: "dark-v10",
        width: 300,
        height: 300,
        position: "auto",
        overlays: [{ geoJson }]
      })
      .url()}?access_token=${process.env.REACT_APP_MAPBOX}`;
  }, [coordinates]);
  return <img src={imgSrc} {...props} />;
};

export const GlobalMap = ({ style }) => {
  const imgSrc = useMemo(() => {
    return `${mapboxClient
      .getStaticImage({
        ownerId: "mapbox",
        styleId: "dark-v10",
        width: 300,
        height: 300,
        position: {
          zoom: 0.02,
          coordinates: [-9, -9]
        }
      })
      .url()}?access_token=${process.env.REACT_APP_MAPBOX}`;
  }, []);
  return (
    <div
      style={{
        ...style,
        backgroundImage: `url("${imgSrc}")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      }}
    ></div>
  );
};
