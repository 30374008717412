import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { useBooleanControls } from "../../utils/hooks";
import { useAdvancedDropBottomNav } from "../Layout";
import { UserBalance } from "../User";
import { CREATE_DROP } from "./gql";
import DropListDetails from "./ListDetails";
import { DropTemplateForm } from "./TemplateForm";
import { CreateDropStepForm } from "./TemplateStepForm";
import { useSnackbar } from "../Snackables";
import {
  ContentDialog,
  ConfirmDialog,
  useContentDialogStyles
} from "./MenuCard";
import { trackInfo } from "../../utils/track";

export const CreateGeodropDialog = () => {
  const classes = useContentDialogStyles();
  const [open, modalControls] = useAdvancedDropBottomNav();
  return (
    <ContentDialog
      classes={{
        paper: classes.mainDrawer
      }}
      title="Create Sponsored Geodrop"
      titleRight={<UserBalance />}
      open={open}
      onCancel={modalControls.setFalse}
    >
      <CreateDropStepForm
        id="create-geodrop"
        onSuccess={modalControls.setFalse}
        onCancel={modalControls.setFalse}
        initial={{ amount: 10, quantity: 1 }}
      />
    </ContentDialog>
  );
};

export const CreateGeodrop = ({ id, onSuccess, onError, initial }) => {
  const [variables, setVariables] = useState(initial);
  const [open, modalControls] = useBooleanControls(false);
  const [{}, { setSnackbar }] = useSnackbar();
  const [createDrop, create] = useMutation(CREATE_DROP, {
    variables: {},
    update: (cache, result) => {
      trackInfo("Drop created", result);
      setSnackbar({
        message: "Drop created successfully"
      });
    }
  });

  const onSubmit = variables => {
    setVariables(variables);
    modalControls.setTrue();
  };

  const onConfirm = async () => {
    try {
      const result = await createDrop({ variables });
      modalControls.setFalse();
      if (result && onSuccess) onSuccess(result);
    } catch (e) {
      if (onError) onError(e);
    }
  };

  return (
    <>
      <div className="container py-3">
        <DropTemplateForm
          id={id}
          hasQuantity={true}
          onSubmit={onSubmit}
          initial={variables}
          error={create.error}
        />
      </div>
      <ConfirmDialog
        title="Confirm Geodrop Details"
        text={
          <>
            <DialogContentText>
              Your funds will be collected immediately after confirmation. Drops
              can be recalled up until they have been claimed by a user.
            </DialogContentText>
            <DropListDetails
              drop={variables}
              total={(variables.amount || 0) * (variables.quantity || 1)}
            />
          </>
        }
        open={open}
        loading={create.loading}
        onCancel={modalControls.setFalse}
        onConfirm={onConfirm}
      />
    </>
  );
};
