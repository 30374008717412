import React from "react";
import { parseMessage, linkExp } from "./helpers";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Link from "@material-ui/core/Link";
import AddIcon from "@material-ui/icons/Add";
import dropsImage from "../../assets/drops.png";
import Skeleton from "@material-ui/lab/Skeleton";
import { DropPreview } from "@xyo-network/coin-geo-drops/build/lib/components/DropPreview";
import cx from "classnames";

export default DropPreview;

const DropPreviewLegacy = ({ drop, loading, className }) => {
  return (
    <Card elevation={4} className={cx(className, "bg-light text-dark w-100")}>
      <div className="d-flex align-items-center p-2 pb-3">
        <img
          src={dropsImage}
          style={{ height: 22, backgroundSize: "contain" }}
        />
        <small className="text-muted ml-1" style={{ opacity: 0.6 }}>
          Sponsored
        </small>
        <div className="flex-grow-1 text-muted" />
        {drop && drop.amount && (
          <Typography
            variant="h5"
            component="h2"
            color="inherit"
            style={{ display: "flex", alignItems: "center" }}
          >
            <AddIcon style={{ width: 16, height: 16 }} />{" "}
            {(drop && drop.amount) || ""}
          </Typography>
        )}
      </div>
      <CardMedia
        image={drop && drop.imageUrl}
        style={{ height: 160, backgroundSize: "contain", textAlign: "center" }}
      >
        {drop && drop.imageUrl ? null : (
          <div
            className="w-100 h-100"
            style={{ backgroundColor: `rgba(0,0,0,0.8)` }}
          />
        )}
      </CardMedia>
      <CardContent className="text-center">
        <Typography color="inherit" gutterBottom>
          {(drop && drop.title) || "Geodrop Collected"}
        </Typography>
        {loading ? (
          <>
            <Skeleton
              variant="text"
              width={40}
              className="skeleton"
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                marginLeft: "auto",
                marginRight: "auto"
              }}
            />
            <Skeleton
              variant="text"
              width={160}
              className="skeleton"
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                marginLeft: "auto",
                marginRight: "auto"
              }}
            />
          </>
        ) : (
          <>
            <Typography
              color="inherit"
              variant="caption"
              dangerouslySetInnerHTML={{
                __html: parseMessage(
                  (drop && drop.message) ||
                    `Congrats! You just found a Geodrop box.`
                )
              }}
              style={{ wordBreak: "break-word" }}
            ></Typography>
          </>
        )}
      </CardContent>
      {drop && drop.link && drop.link.match(linkExp) && (
        <CardActions className="flex-column">
          <Button
            component="div"
            onClick={ev => {
              ev.stopPropagation();
              window.open(drop.link);
            }}
            style={{ textTransform: "none" }}
            size="small"
            color="secondary"
            variant="contained"
            className="text-white"
            fullWidth
          >
            {drop.buttonText || "Learn More"}
          </Button>
          {/* <Link className="text-muted mt-1 cursor-pointer text-underline-hover">
            No Thanks
          </Link> */}
        </CardActions>
      )}
    </Card>
  );
};
