import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import LinearProgress from "@material-ui/core/LinearProgress";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { ConfirmDialog, ErrorDisplay } from "../Geodrops/MenuCard";
import Menu from "@material-ui/core/Menu";
import { useAuth, bossClient } from "../../context";
import gql from "graphql-tag";
import cx from "classnames";
import capitalize from "lodash/capitalize";
import { useBooleanControls } from "../../utils/hooks";

export const AccountUsersList = () => {
  const [account, setAccount] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [deleteModal, deleteModalControls] = useBooleanControls(false);
  const [reinviteModal, reinviteModalControls] = useBooleanControls(false);
  const handleMenu = (account: Account) => (event: any) => {
    setAccount(account);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (fn: any) => {
    setAnchorEl(null);
    if (typeof fn === "function") fn();
  };
  const { initialized, userInfo } = useAuth();
  const { data, loading, error } = useQuery(ACCOUNTS, {
    skip: !userInfo
  });
  const [reinviteAccountMutation, reinviteAccount] = useMutation(
    ACCOUNT_INVITE,
    {
      refetchQueries: [{ query: ACCOUNTS }]
    }
  );
  const [deleteAccountMutation, deleteAccount] = useMutation(DELETE_ACCOUNT, {
    refetchQueries: [{ query: ACCOUNTS }]
  });
  return (
    <>
      {loading && <LinearProgress className="w-100" />}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Permissions</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {((data && data.accounts && data.accounts.data) || []).map(
            (account: any) => (
              <TableRow key={account.id}>
                <TableCell>{account.name}</TableCell>
                <TableCell>{account.email}</TableCell>
                <TableCell>
                  <Tooltip
                    title={
                      account.active ? "Primary" : capitalize(account.status)
                    }
                  >
                    <i
                      className={cx("fa fa-circle mr-2", {
                        "text-success": account.active,
                        "text-warning": account.status === "pending",
                        "text-danger": account.status === "declined"
                      })}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <IconButton onClick={handleMenu(account)}>
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={!!anchorEl}
        onClose={handleClose}
      >
        <MenuItem>
          <i className="fa fa-edit mr-2" /> Update
        </MenuItem>
        <MenuItem onClick={() => handleClose(deleteModalControls.setTrue)}>
          <i className="fa fa-times mr-2" /> Delete
        </MenuItem>
        {account &&
        (account.status === "declined" || account.status === "expired") ? (
          <MenuItem onClick={() => handleClose(reinviteModalControls.setTrue)}>
            <i className="fa fa-sync-alt mr-2" /> Reinvite
          </MenuItem>
        ) : null}
      </Menu>
      <ConfirmDialog
        open={account && deleteModal ? true : false}
        loading={deleteAccount.loading}
        title="Delete User"
        text=""
        content={
          <>
            <p>
              Are you sure you would like to remove {account && account.name}{" "}
              from {account && account.account}.
            </p>
            <ErrorDisplay className="" error={deleteAccount.error} />
          </>
        }
        onConfirm={() =>
          deleteAccountMutation({
            variables: { id: account && account.id },
            update: () => deleteModalControls.setFalse()
          })
        }
        onCancel={deleteModalControls.setFalse}
      />
      <ConfirmDialog
        open={account && reinviteModal ? true : false}
        loading={reinviteAccount.loading}
        title="Reinvite User"
        text=""
        content={
          <>
            <p>
              Are you sure you would like to reinvite {account && account.name}{" "}
              to {account && account.account}.
            </p>
            <ErrorDisplay className="" error={reinviteAccount.error} />
          </>
        }
        onConfirm={() =>
          reinviteAccountMutation({
            variables: { email: account && account.email },
            update: () => reinviteModalControls.setFalse()
          })
        }
        onCancel={reinviteModalControls.setFalse}
      />
    </>
  );
};

export const SelectAccount = ({ ...props }: TextFieldProps) => {
  const { initialized, userInfo } = useAuth();
  const [open, modalControls] = useBooleanControls(false);
  const { data, loading, error } = useQuery(MY_ACCOUNTS, {
    skip: !userInfo
  });
  const [setActiveAccount, update] = useMutation(SET_ACTIVE_ACCOUNT, {
    optimisticResponse: ({ id }) => ({
      setActiveAccount: {
        ...((data && data.myAccounts) || []).find(
          (account: any) => account.id === id
        ),
        active: true
      }
    }),
    onCompleted: async () => {
      bossClient.resetStore();
    },
    update: (cache, { data }) => {
      if (data && data.setActiveAccount) {
        const id = data.setActiveAccount.id;
        const result: any = cache.readQuery({ query: MY_ACCOUNTS });
        const myAccounts = ((result && result.myAccounts) || []).map(
          (v: any) => ({
            ...v,
            active: v.id === id
          })
        );
        cache.writeQuery({ query: MY_ACCOUNTS, data: { myAccounts } });
      }
    }
  });
  const activeAccount = ((data && data.myAccounts) || []).find(
    ({ active }: any) => active
  );
  const onChange = (ev: any) => {
    const id = ev.target.value;
    if (id) setActiveAccount({ variables: { id } });
  };
  return (
    <>
      <TextField
        {...props}
        select
        fullWidth
        label="Account"
        variant="outlined"
        value={(activeAccount && activeAccount.id) || ""}
        onChange={onChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {(loading || update.loading) && <CircularProgress size={10} />}
            </InputAdornment>
          )
        }}
      >
        {((data && data.myAccounts) || []).map((option: any) => (
          <MenuItem key={option.id} value={option.id}>
            <i
              className={cx("fa fa-circle mr-2", {
                "text-success": option.active
              })}
            />{" "}
            {option.account}
          </MenuItem>
        ))}
        <MenuItem onClick={() => modalControls.setTrue()}>
          <i className="fa fa-plus mr-2" /> Create Account
        </MenuItem>
      </TextField>
    </>
  );
};

const ACCOUNT_FRAGMENT = gql`
  fragment Account on Account {
    id
    uid
    status
    account
    active
    created
    expires
    name
    email
    read_user
  }
`;

export const ACCOUNT = gql`
  query Account($id: ObjectId) {
    account(id: $id) {
      ...Account
    }
  }

  ${ACCOUNT_FRAGMENT}
`;

export const ACCOUNTS = gql`
  query Accounts {
    accounts {
      data {
        ...Account
      }
    }
  }

  ${ACCOUNT_FRAGMENT}
`;

export const PENDING_ACCOUNTS = gql`
  query PendingAccounts {
    pendingAccounts {
      ...Account
    }
  }

  ${ACCOUNT_FRAGMENT}
`;

export const MY_ACCOUNTS = gql`
  query MyAccounts {
    myAccounts {
      ...Account
    }
  }

  ${ACCOUNT_FRAGMENT}
`;

export const SET_ACTIVE_ACCOUNT = gql`
  mutation SetActiveAccount($id: String!) {
    setActiveAccount(id: $id) {
      ...Account
    }
  }

  ${ACCOUNT_FRAGMENT}
`;

export const CREATE_ACCOUNT = gql`
  mutation CreateAccount($account: String!) {
    createAccount(input: { account: $account }) {
      ...Account
    }
  }

  ${ACCOUNT_FRAGMENT}
`;

export const ACCEPT_INVITE = gql`
  mutation AcceptInvite($id: ObjectId!) {
    acceptInviteToAccount(id: $id) {
      ...Account
    }
  }

  ${ACCOUNT_FRAGMENT}
`;

export const DECLINE_INVITE = gql`
  mutation DeclineInvite($id: ObjectId!) {
    declineInviteToAccount(id: $id) {
      ...Account
    }
  }

  ${ACCOUNT_FRAGMENT}
`;

export const ACCOUNT_INVITE = gql`
  mutation InviteUserToAccount($email: String!) {
    inviteUserToAccount(email: $email) {
      ...Account
    }
  }

  ${ACCOUNT_FRAGMENT}
`;

export const DELETE_ACCOUNT = gql`
  mutation DeleteAccount($id: ObjectId!) {
    deleteAccount(id: $id) {
      ...Account
    }
  }

  ${ACCOUNT_FRAGMENT}
`;
