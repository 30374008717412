import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Drawer from "@material-ui/core/Drawer";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import MoreIcon from "@material-ui/icons/MoreVert";
import ErrorIcon from "@material-ui/icons/Error";
import WarningIcon from "@material-ui/icons/Warning";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import { parseError } from "../../utils/error";
import cx from "classnames";

export const useContentDialogStyles = makeStyles(theme =>
  createStyles({
    mainDrawer: {
      maxWidth: 1200,
      width: "90vw"
    },
    smDrawer: {
      maxWidth: 600,
      width: "70vw"
    },
    subDrawer: {
      maxWidth: 900,
      width: "80vw",
      [theme.breakpoints.down("sm")]: {
        width: "85vw"
      }
    }
  })
);

export const useContentDialogContentStyles = makeStyles(theme =>
  createStyles({
    content: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(6),
      backgroundColor: theme.palette.background.default
    }
  })
);

export const useTocStyle = makeStyles(theme =>
  createStyles({
    toc: {
      [theme.breakpoints.up("md")]: {
        position: "sticky",
        top: 0
      }
    }
  })
);

export const MenuCard = ({ className, title, children, controls }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenu = event => {
    if (event && event.stopPropagation) event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = event => {
    if (event && event.stopPropagation) event.stopPropagation();
    setAnchorEl(null);
  };
  return (
    <Paper className={cx("list-group-item rounded-0-sm text-white", className)}>
      <div className="d-flex align-items-center">
        <Typography variant="h6">{title}&nbsp;</Typography>
        <div className="flex-grow-1" />
        <IconButton onClick={handleMenu} size="small" component="div">
          <MoreIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={!!anchorEl}
          onClose={handleClose}
        >
          {controls(handleClose)}
        </Menu>
      </div>
      <Divider className="my-2" />
      {children}
    </Paper>
  );
};

export const ContentDialog = ({
  open,
  title,
  titleRight,
  children,
  content = "",
  actions,
  classes = {},
  maxWidth = "md",
  onCancel
}) => {
  const contentClasses = useContentDialogContentStyles();
  useEffect(() => {
    if (open) {
      document.body.style.position = "fixed";
      document.body.style.height = "100vh";
      document.body.style.width = "100vw";
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.height = "initial";
      document.body.style.overflow = "initial";
      document.body.style.position = "initial";
    }
  }, [open]);
  return (
    <Drawer
      open={open}
      onClose={onCancel}
      classes={classes}
      aria-labelledby="action-dialog-title"
      scroll="body"
      ModalProps={{
        keepMounted: false
      }}
    >
      <div
        id="action-dialog-title"
        className="d-flex align-items-center py-2 px-4"
        style={{ borderBottom: "1px solid #000!important", minHeight: 68 }}
      >
        <Typography
          variant="h6"
          className="flex-grow-1"
          style={{ lineHeight: 1 }}
        >
          {title}
        </Typography>
        {titleRight}
      </div>
      {content && (
        <DialogContent className={contentClasses.content}>
          {content}
        </DialogContent>
      )}
      {children}
      {actions}
    </Drawer>
  );
};

export const ActionDialog = ({
  cancelText = "Cancel",
  onCancel,
  action,
  ...props
}) => {
  return (
    <ContentDialog
      {...props}
      onCancel={onCancel}
      actions={
        <DialogActions style={{ borderTop: "1px solid #000!important" }}>
          <Button onClick={onCancel} color="primary">
            {cancelText}
          </Button>
          {action}
        </DialogActions>
      }
    />
  );
};

export const ConfirmDialog = ({
  title,
  text,
  content = <div />,
  open,
  loading,
  onCancel,
  onConfirm,
  cancelText = "Cancel",
  confirmText = "Confirm"
}) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="card-remove-title"
      scroll="body"
    >
      <div
        id="card-remove-title"
        className="d-flex align-items-center py-2 px-4"
      >
        <Typography
          variant="h6"
          className="flex-grow-1"
          style={{ lineHeight: 1 }}
        >
          {title}
        </Typography>
      </div>
      <DialogContent>{text || content}</DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {cancelText}
        </Button>
        <LoadingButton loading={loading} onClick={onConfirm}>
          {confirmText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export const LoadingButton = ({
  children,
  disabled,
  loading,
  className,
  ...props
}) => {
  return (
    <Button
      color="primary"
      disabled={disabled || loading}
      className={cx("d-flex align-items-center", className)}
      {...props}
    >
      {children}{" "}
      {loading && <span className="spinner-border spinner-border-sm ml-2" />}
    </Button>
  );
};

export const AlertDisplay = ({ className, message, icon }) => {
  const [show, setShow] = useState(!!message);
  useEffect(() => {
    setShow(!!message);
  }, [message]);
  if (!show) return null;
  return (
    <SnackbarContent
      className={className}
      message={
        <span id="client-snackbar" className="d-flex align-items-center">
          {icon}
          <span dangerouslySetInnerHTML={{ __html: message }} />
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={() => setShow(false)}
        >
          <CloseIcon style={{ fontSize: 20 }} />
        </IconButton>
      ]}
    />
  );
};

export const ErrorDisplay = ({ className, error }) => {
  const message = parseError(error);

  return (
    <AlertDisplay
      className={cx("bg-danger text-white", className)}
      message={message}
      icon={<ErrorIcon className="mr-2" style={{ fontSize: 20 }} />}
    />
  );
};

export const WarningDisplay = ({ className, message, icon }) => {
  return (
    <AlertDisplay
      className={cx(
        "border border-warning bg-transparent text-white",
        className
      )}
      message={message}
      icon={
        icon || (
          <WarningIcon className="mr-2 text-warning" style={{ fontSize: 20 }} />
        )
      }
    />
  );
};

export const InfoDisplay = ({ className, message }) => {
  return (
    <AlertDisplay
      className={cx("border border-info bg-dark text-white", className)}
      message={message}
      icon={<InfoIcon className="mr-2 text-info" style={{ fontSize: 20 }} />}
    />
  );
};

export const SuccessDisplay = ({ className, message }) => {
  return (
    <AlertDisplay
      className={cx("border border-success bg-success text-white", className)}
      message={message}
      icon={<CheckIcon className="mr-2 text-white" style={{ fontSize: 20 }} />}
    />
  );
};

export const Toc = ({ children }) => {
  const classes = useTocStyle();
  return <div className={classes.toc}>{children}</div>;
};

export default MenuCard;
