import React from "react";

export const InterfaceGraphic = ({ className }) => {
  return (
    <svg
      version="1.1"
      id="a777089b-0abc-4895-8bdb-d70d2134474e"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 980.5 770.9"
      className={className}
    >
      <title>mobile_interface</title>
      <rect y="701" fill="#EE7268" width="912" height="2" />
      <path
        stroke="#424242"
        strokeWidth="2px"
        fill="#262523"
        d="M122.2,197.7h-6l-25.5,92.5c0,0-0.5,61.5,5.5,91.5c0,0,10-13,17-13s13.5-65.5,13.5-65.5
	S154.2,209.7,122.2,197.7z"
      />
      <path
        fill="#A0616A"
        d="M254.2,374.7c0,0-16,45-1,48s23-35,19-40S254.2,374.7,254.2,374.7z"
      />
      <path
        fill="#A0616A"
        d="M94.6,365.4c0,0,4.5,47.5,19.4,43.9s6.1-41.4,0.3-44.3S94.6,365.4,94.6,365.4z"
      />
      <path
        fill="#A0616A"
        d="M133.2,643.7c0,0,7,34-5,43s4,28,4,28l38-40c0,0-16-25-15-40L133.2,643.7z"
      />
      <polygon
        fill="#A0616A"
        points="193.2,674.7 189.2,701.7 188.2,720.7 210.2,718.7 213.2,679.7 "
      />
      <path
        fill="#424242"
        stroke="#262523"
        strokeWidth="2px"
        d="M108.6,370.2c0,0-12.3,4.5-11.3,17.5s4,54,4,54s-9,88,0,118s26,90,26,90s26,5,32-14c0,0-1-65-12-79
	s-3-18-2-20s4-5,4-8s1-14,1-18c0-4-1-3,1-9s4-8,4-9s17-54,17-54l22,123c0,0-5,121-3,121s24,8,32,0c0,0,20-104,16-110s-5-7-4-13
	s3-9,1-12s-5-13-2-16s4-17,4-17s17-98,6-112S108.6,370.2,108.6,370.2z"
      />
      <path
        stroke="#424242"
        strokeWidth="2px"
        fill="#262523"
        d="M142.2,687.7c0,0-12-15-16-8c-4,7-10,18-10,18s-45,39-20,40s41-17,41-17s4-20,16-20l2,5l22-14
	c0,0-3.1-26.6-12-25.8L142.2,687.7z"
      />
      <path
        stroke="#424242"
        strokeWidth="2px"
        fill="#262523"
        d="M184.2,717.7c0,0,26-10,29,0s3,15,3,15s22,36,0,38s-32-12-32-12s3-17,0-19S184.2,717.7,184.2,717.7z"
      />
      <circle fill="#A0616A" cx="202.2" cy="130.7" r="35" />
      <path
        fill="#A0616A"
        d="M176.2,138.7l-7,39l49,12c0,0-11.5-28.5,1-41C222.4,145.5,176.2,138.7,176.2,138.7z"
      />
      <path
        stroke="#424242"
        strokeWidth="2px"
        fill="#262523"
        d="M172.2,161.1c-2.4,0.5-4.3,2.2-5,4.6c-0.9,2.9-2.2,5.6-4,8c0,0-47,22-47,25s19,67,19,67s1,40-7,49
	s-16,42-14,45s-14,10-8,15s90,53,140,31c0,0,1-9,0-15s6-26,4-33s0-79,0-79l10.3-85.3c0.4-3.3-1.6-6.5-4.9-7.5
	c-11.5-3.5-38.3-17.8-41.9-14.8C209,175.2,179.2,172.5,172.2,161.1z"
      />
      <path
        stroke="#424242"
        strokeWidth="2px"
        fill="#262523"
        d="M251.2,185.7L251.2,185.7c3-1.2,6.4-0.2,8.2,2.3c6.2,8.5,19.2,27.5,20.8,39.7c2,16,8,59,7,65s-2,7,0,10
	s1,2,0,5s-13,86-13,86s-8-18-23-12c0,0,2-16,0-21s-4-8-2-12s0-43-1-47s0-25,0-25l-9-41L251.2,185.7z"
      />
      <path
        stroke="#424242"
        strokeWidth="2px"
        fill="#262523"
        d="M195.7,136.7l1.2-8.2c0.3-1.9,1.4-4.4,3.3-3.9c1.6,0.4,5.1,1.8,5.6,1.4c2.2-1.5,5.5,1.2,7.7-0.2
	c1-0.6,1.6-1.8,2.7-1.9c0.7,0,1.4,0.4,2,0.9c1.5,1.3,3.2,2.2,5.1,2.8c1.9,0.5,4,0,5.4-1.5c0.2-0.4,0.6-0.7,1-0.9
	c0.5-0.1,0.9-0.1,1.4,0.1l5.8,1.6c1.7-6.3,0.6-13-3-18.5c-2.4-3.2-5.2-6-8.5-8.3c-3.6-2.7-7.3-5.3-11.2-7.8
	c-4.2-2.7-8.7-5.2-13.6-5.9c-4.9-0.7-10.4,0.6-13.5,4.5c-1.5-1.1-2.5-2.5-3.1-4.2c-1.3,1.2-1.4,3.2-0.2,4.5c0.1,0.1,0.3,0.3,0.4,0.4
	l-4.9-3.2c-1.4,1.5,1.3,4.1,0.1,5.7c-0.4,0.4-0.8,0.7-1.3,0.9c-2.7,1-5.1,2.6-7.2,4.6c-2.8,2.8-4.4,6.7-5.7,10.5
	c-2.9,8.4-5.1,18.1-0.9,26c0.5,0.9,6.7,15.7,7.6,16.2c2.8,1.9,1-2.8,3,0c1.6,2.2,1.3-6.1,3.6-4.3c1.9,1.3,4.4,1.5,6.4,0.3
	c1-0.7,0.7-4.4,0.1-7.9c-0.3-2.2,1.2-4.3,3.4-4.6c0.5-0.1,1-0.1,1.5,0.1C192.7,136.4,195.6,137,195.7,136.7z"
      />
      <path
        fill="#262523"
        d="M740.3,139V84.5c-34.2-7.9-63.5-29.7-80.9-60.2l0,0c-4.1,3-9.1,4.7-14.2,4.7H602
	C625.4,87.5,678,129.3,740.3,139z"
      />
      <path
        fill="#262523"
        d="M740.3,81.4V29.1c0-16.1-13-29.1-29.1-29.1c0,0,0,0,0,0h-42v5.1c0,6.5-2.7,12.8-7.4,17.3
	C678.6,52.2,707,73.6,740.3,81.4z"
      />
      <path
        fill="#262523"
        d="M538.4,29h-27c-13.2,0-24-10.7-24-24V0H448c-16.1,0-29.1,13-29.1,29.1c0,0,0,0,0,0v648.2
	c0,16.1,13,29.1,29,29.1c0,0,0.1,0,0.1,0h263.2c1.2,0,2.4-0.1,3.6-0.2c14.6-1.8,25.5-14.2,25.5-28.9V200.2
	C644.6,189.1,564.8,120.8,538.4,29z"
      />
      <path
        fill="#262523"
        d="M598.7,29h-57.1c26.3,90,104.7,157,198.7,168.1v-55C676.3,132.3,622.4,89.2,598.7,29z"
      />
      <circle
        opacity="0.1"
        enableBackground="new    "
        cx="689.1"
        cy="89.3"
        r="30.2"
      />
      <circle
        opacity="0.1"
        enableBackground="new    "
        cx="570"
        cy="175.1"
        r="30.2"
      />
      <circle
        opacity="0.1"
        enableBackground="new    "
        cx="615.6"
        cy="262.9"
        r="30.2"
      />
      <ellipse
        transform="matrix(0.1602 -0.9871 0.9871 0.1602 138.8497 773.3697)"
        opacity="0.1"
        enableBackground="new    "
        cx="523.9"
        cy="305.1"
        rx="30.2"
        ry="30.2"
      />
      <circle
        opacity="0.1"
        enableBackground="new    "
        cx="600.1"
        cy="424.2"
        r="30.2"
      />
      <g id="leaves">
        <path
          opacity="0.5"
          enableBackground="new    "
          d="M514.2,696.4c0.7-0.7,17.9-18.1,32.5-59.5c13.4-37.9,26.9-104.1,15.2-203.8
		c-22.1-188.9,91.2-309.2,92.4-310.4l-3.4-3.2c-0.3,0.3-29.4,30.9-55.4,84.7c-34.3,71.3-47.6,150.9-38.3,229.5
		c22.1,188.6-45.7,258.7-46.4,259.4L514.2,696.4z"
        />
        <path
          opacity="0.5"
          enableBackground="new    "
          d="M496.2,693.2c0,0,47-65.1-27.8-140.8L496.2,693.2z"
        />
        <path
          opacity="0.5"
          enableBackground="new    "
          d="M532.6,700.7c0,0,32.1-73.6,135.4-47.7L532.6,700.7z"
        />
        <path
          opacity="0.5"
          enableBackground="new    "
          d="M808.7,693.4c0,0,47-65.1-27.8-140.8L808.7,693.4z"
        />
        <path
          opacity="0.5"
          enableBackground="new    "
          d="M845.1,695.9c0,0,32.1-73.6,135.4-47.7L845.1,695.9z"
        />
      </g>
      <path
        fill="#EE7268"
        d="M582.5,202.1c-63.8,0-115.5,51.7-115.5,115.5c0,55.2,39.1,102.8,93.3,113.4c0.2,0,0.4,0.1,0.6,0.1
	c3.3,0.6,6.7,1.1,10.1,1.4c0.2,0,0.4,0,0.6,0.1c3.3,0.3,6.7,0.5,10.1,0.5c0.2,0,0.4,0,0.6,0c3.4,0,6.8-0.1,10.1-0.4
	c0.2,0,0.4,0,0.6-0.1c3.4-0.3,6.8-0.8,10.1-1.4c0.2,0,0.4-0.1,0.6-0.1c62.7-11.9,103.8-72.3,92-135
	C685.6,241.6,638,202.1,582.5,202.1z"
      />
      <path
        fill="#424242"
        stroke="#262523"
        strokeWidth="2px"
        d="M693,317.6c0,53.1-37.8,98.7-89.9,108.6c-0.2,0-0.4,0.1-0.6,0.1c-3.2,0.6-6.4,1-9.7,1.3
	c-0.2,0-0.4,0-0.6,0.1c-3.2,0.3-6.4,0.4-9.7,0.4c-0.2,0-0.4,0-0.6,0c-3.3,0-6.5-0.2-9.7-0.5c-0.2,0-0.4,0-0.6-0.1
	c-3.3-0.3-6.5-0.8-9.7-1.4c-0.2,0-0.4-0.1-0.6-0.1c-59.9-11.7-99-69.7-87.3-129.6s69.7-99,129.6-87.3
	C655.6,219.3,693,264.7,693,317.6z"
      />
      <rect x="453" y="492" fill="#EE7268" width="254" height="50" />
      <g id="dials">
        <path
          fill="#EE7268"
          d="M566.9,409.6c-2.6,0-4.7-2.1-4.7-4.7s2.1-4.7,4.7-4.7c2.6,0,4.7,2.1,4.7,4.7l0,0
		C571.5,407.5,569.5,409.6,566.9,409.6z M566.9,401.2c-2.1,0-3.7,1.7-3.7,3.7s1.7,3.7,3.7,3.7c2.1,0,3.7-1.7,3.7-3.7l0,0
		C570.6,402.8,568.9,401.2,566.9,401.2L566.9,401.2z"
        />
        <rect x="582.5" y="282.7" fill="#EE7268" width="0.9" height="138.1" />
        <rect x="582.5" y="223.4" fill="#EE7268" width="0.9" height="40.8" />
        <rect x="534.5" y="268.4" fill="#EE7268" width="0.9" height="138.2" />
        <rect x="600.2" y="268.4" fill="#EE7268" width="0.9" height="138.2" />
        <rect x="550.4" y="253.1" fill="#EE7268" width="0.9" height="128.3" />
        <rect x="566.4" y="218.1" fill="#EE7268" width="0.9" height="178" />
        <rect x="613.7" y="224.6" fill="#EE7268" width="0.9" height="27.7" />
        <rect x="613.7" y="270.8" fill="#EE7268" width="0.9" height="126.7" />
        <rect x="629.6" y="251.2" fill="#EE7268" width="0.9" height="16.3" />
        <rect x="629.6" y="285.8" fill="#EE7268" width="0.9" height="81" />
        <rect x="629.6" y="383.4" fill="#EE7268" width="0.9" height="14.1" />
        <path
          fill="#EE7268"
          d="M550.8,394.7c-2.6,0-4.7-2.1-4.7-4.7s2.1-4.7,4.7-4.7c2.6,0,4.7,2.1,4.7,4.7l0,0
		C555.5,392.6,553.4,394.7,550.8,394.7z"
        />
        <path
          fill="#EE7268"
          d="M582.5,277.3c-2.6,0-4.7-2.1-4.7-4.7s2.1-4.7,4.7-4.7c2.6,0,4.7,2.1,4.7,4.7l0,0
		C587.2,275.2,585.1,277.3,582.5,277.3z M582.5,268.9c-2.1,0-3.7,1.7-3.7,3.7s1.7,3.7,3.7,3.7c2.1,0,3.7-1.7,3.7-3.7l0,0
		C586.2,270.6,584.6,268.9,582.5,268.9z"
        />
        <path
          fill="#EE7268"
          d="M583,223.7c-2.6,0-4.7-2.1-4.7-4.7s2.1-4.7,4.7-4.7c2.6,0,4.7,2.1,4.7,4.7l0,0
		C587.6,221.6,585.5,223.7,583,223.7z M583,215.3c-2.1,0-3.7,1.7-3.7,3.7s1.7,3.7,3.7,3.7c2.1,0,3.7-1.7,3.7-3.7l0,0
		C586.7,217,585,215.3,583,215.3z"
        />
        <path
          fill="#EE7268"
          d="M630,379.8c-2.6,0-4.7-2.1-4.7-4.7s2.1-4.7,4.7-4.7c2.6,0,4.7,2.1,4.7,4.7l0,0
		C634.7,377.7,632.6,379.8,630,379.8z M630,371.4c-2.1,0-3.7,1.7-3.7,3.7s1.7,3.7,3.7,3.7c2.1,0,3.7-1.7,3.7-3.7l0,0
		C633.7,373.1,632.1,371.4,630,371.4z"
        />
        <path
          fill="#EE7268"
          d="M535,262.4c-2.6,0-4.7-2.1-4.7-4.7s2.1-4.7,4.7-4.7c2.6,0,4.7,2.1,4.7,4.7l0,0
		C539.6,260.3,537.5,262.4,535,262.4z"
        />
        <path
          fill="#EE7268"
          d="M630,281.5c-2.6,0-4.7-2.1-4.7-4.7s2.1-4.7,4.7-4.7c2.6,0,4.7,2.1,4.7,4.7l0,0
		C634.7,279.4,632.6,281.5,630,281.5z M630,273.1c-2.1,0-3.7,1.7-3.7,3.7s1.7,3.7,3.7,3.7c2.1,0,3.7-1.7,3.7-3.7l0,0
		C633.7,274.8,632.1,273.1,630,273.1z"
        />
        <path
          fill="#EE7268"
          d="M614.2,265.6c-2.6,0-4.7-2.1-4.7-4.7s2.1-4.7,4.7-4.7c2.6,0,4.7,2.1,4.7,4.7l0,0
		C618.8,263.6,616.8,265.6,614.2,265.6z"
        />
      </g>
    </svg>
  );
};
