import { createMuiTheme } from "@material-ui/core/styles";

export const backgroundColor = "#262525";
export const primaryMain = "#ee7268";
export const secondaryMain = "#32c688";

export const createTheme = (type: any = "dark") =>
  createMuiTheme({
    typography: {
      fontFamily: [
        '"Titillium Web"',
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(",")
    },
    palette: {
      type,
      background: {
        default: backgroundColor
      },
      primary: { main: primaryMain, contrastText: "#ffffff" },
      secondary: { main: secondaryMain, contrastText: "#000000" }
    }
  });

export const darkTheme = createTheme("dark");
export const lightTheme = createTheme("light");
