import React, { useState } from "react";
import { storage } from "firebase/app";
import { useUser } from "../../context/auth";
import { useBooleanControls } from "../../utils/hooks";
import get from "lodash/get";
import cx from "classnames";
import "firebase/storage";

export const uploadFile = async (path, file, metadata) => {
  const storageRef = storage().ref();
  const ref = storageRef.child(path);
  await ref.put(file, metadata);
  const url = await ref.getDownloadURL();
  return url;
};

export const verifyUploadParams = (user, file) => {
  if (!user || !user.uid) throw new Error("Must be logged in");
  const fileName = get(file, "name");
  const contentType = get(file, "type");
  return { fileName, contentType };
};

export const uploadProfilePhoto = async (user, file) => {
  const { fileName, contentType } = verifyUploadParams(user, file);
  const path = `coin/images/${user.uid}/${fileName}`;
  const photoURL = await uploadFile(path, file, { contentType });
  await user.updateProfile({ photoURL });
  return photoURL;
};

export const uploadGeoDropPhoto = async (user, file) => {
  if (!user || !user.uid) throw new Error("Must be logged in");
  const fileName = get(file, "name");
  const type = get(file, "type");
  const path = `coin/images/${user.uid}/geodrops/${fileName}`;
  const dataUrl = await readFile(file);
  await validateSize(dataUrl, 512, 512);
  // const classification = await classifyImage(dataUrl);
  const photoURL = await uploadFile(path, file, { contentType: type });
  await detectModerationLabels(photoURL);
  return photoURL;
};

export const createUploadHook = onUpload => {
  return (onSuccess, onError) => {
    const user = useUser();
    const [src, setSrc] = useState("");
    const [uploading, setUploading] = useState(false);
    const uploadCoinImageFile = async ev => {
      try {
        const file = ev.target.files[0];
        if (!file) return;
        setUploading(true);
        const src = await onUpload(user, file);
        if (onSuccess) onSuccess(src);
      } catch (err) {
        if (onError) onError(err);
      }
      setUploading(false);
    };
    return [uploading, uploadCoinImageFile];
  };
};

export const useUploadProfilePhoto = createUploadHook(uploadProfilePhoto);
export const useUploadGeoDropPhoto = createUploadHook(uploadGeoDropPhoto);

export const UploadField = ({
  name,
  label,
  value,
  uploading,
  onChange,
  className,
  accept = "image/png",
  error
}) => {
  const [isHover, hoverControls] = useBooleanControls(false);
  const [isDragover, dragoverControls] = useBooleanControls(false);
  return (
    <div className={cx("form-group", className)}>
      <label className="d-block" htmlFor={`${name}-file`}>
        {label} <span className="text-danger">{error}</span>
      </label>
      <div
        className={cx(
          "position-relative rounded d-flex align-items-center justify-content-center",
          {
            "border-primary": isDragover || error
          }
        )}
        style={{
          border: "1px solid",
          borderColor: isDragover
            ? ""
            : isHover
            ? "rgba(255, 255, 255, 1)"
            : "rgba(255, 255, 255, 0.23)",
          height: 200
        }}
      >
        {uploading ? (
          <span className="spinner-border spinner-border-sm" />
        ) : value ? (
          <img
            src={value}
            className="h-100 w-100"
            style={{ objectFit: "contain", objectPosition: "center" }}
          />
        ) : (
          "Drop Image Here"
        )}
        <input
          type="file"
          accept={accept}
          id={`${name}-file`}
          onMouseEnter={hoverControls.setTrue}
          onMouseLeave={hoverControls.setFalse}
          onTouchStart={hoverControls.setTrue}
          onTouchEnd={hoverControls.setFalse}
          onDragOver={dragoverControls.setTrue}
          onDragEnter={dragoverControls.setTrue}
          onDragLeave={dragoverControls.setFalse}
          onDragEnd={dragoverControls.setFalse}
          onDrop={dragoverControls.setFalse}
          onChange={onChange}
          className="opacity-0 position-absolute w-100 h-100 top-0 left-0 cursor-pointer"
        />
      </div>
      <input name={name} hidden={true} value={value} readOnly={true} />
    </div>
  );
};

export const UploadGeoDropImageField = ({ onChange, name, ...props }) => {
  const [error, setError] = useState("");
  const [uploading, handleChange] = useUploadGeoDropPhoto(
    value => {
      setError("");
      onChange({ target: { name, value } });
    },
    err => {
      console.log({ err });
      setError(err.message);
    }
  );
  return (
    <UploadField
      name={name}
      onChange={handleChange}
      uploading={uploading}
      {...props}
      error={error || props.error}
    />
  );
};

let classifier;

function modelLoaded() {
  console.log("Model Loaded!");
}

function readFile(file) {
  return new Promise((res, rej) => {
    var fr = new FileReader();
    fr.onload = function() {
      res(fr.result);
    };
    fr.readAsDataURL(file);
  });
}

function classifyImage(src) {
  if (!classifier)
    classifier = window.ml5.imageClassifier("MobileNet", modelLoaded);

  const img = document.createElement("img");
  img.src = src;
  return classifier.classify(img);
}

async function validateSize(src, width, height) {
  const img = await loadImage(src);
  if (!img) throw new Error("Image not found");
  if (img.width !== width) throw new Error(`Image width must be ${width}px`);
  if (img.height !== height)
    throw new Error(`Image height must be ${height}px`);
  return true;
}

function loadImage(src) {
  return new Promise((res, rej) => {
    const img = new Image();
    img.onload = () => res(img);
    img.onerror = e => rej(e);
    img.src = src;
  });
}

async function detectModerationLabels(url) {
  const res = await fetch(
    `https://vision.coinapp.co/detectModerationLabels?url=${encodeURIComponent(
      url
    )}`
  );
  const data = await res.json();
  if (data && data.ModerationLabels) {
    data.ModerationLabels.forEach(({ Confidence, Name }) => {
      if (Confidence >= 50) throw new Error(`${Name} Discovered`);
    });
  }
  console.log({ data });
}
