export const linkExp = /((http|https)\:\/\/)([a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,4}(\?[a-zA-Z0-9\-\_\&\=]+)?)(\/\S*)?/;

export function parseMessage(text) {
  const matched = text.match(linkExp);
  if (matched && matched[1]) {
    return text.replace(linkExp, `<a href="${matched[0]}">${matched[0]}</a>`);
  } else if (matched) {
    return text.replace(linkExp, `<a href="//${matched[0]}">${matched[0]}</a>`);
  }
  return text;
}

export function frequencyToHumanReadable(frequency) {
  switch (frequency) {
    case "minutely":
      return "Every Minute";
    case "quarter_hour":
      return "Every 15 Minutes";
    case "half_hour":
      return "Every 30 Minutes";
    case "hourly":
      return "Every Hour";
    default:
      return "Every Day";
  }
}

const MINUTELY = 60 * 1000;
const QUARTER_HOUR = 15 * MINUTELY;
const HALF_HOUR = 30 * MINUTELY;
const HOURLY = 60 * MINUTELY;
const DAILY = 24 * HOURLY;

export function frequencyToMs(frequency) {
  switch (frequency) {
    case "minutely":
      return MINUTELY;
    case "quarter_hour":
      return QUARTER_HOUR;
    case "half_hour":
      return HALF_HOUR;
    case "hourly":
      return HOURLY;
    case "daily":
      return DAILY;
    default:
      return DAILY;
  }
}
