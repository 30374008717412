import gql from "graphql-tag";

export const DROP_FRAGMENT = gql`
  fragment Drop on Drop {
    id
    dropUid
    variant
    dropTime
    dropYYYYMMDD
    quadkey
    fullQuadkey
    latitude
    longitude
    lonlat
    email
    asset
    amount
    fee
    message
    claimed
    collected
    receiptDelivered
    claimedTime
    claimedUid
    collectedTime
    receiptMessage
    receiptTime
    link
    linkClicked
    linkClickedTime
    converted
    conversionTime
    advancedId
    condition {
      advancedId
      variant
      collected
    }
  }
`;

export const DROP_TEMPLATE_FRAGMENT = gql`
  fragment DropTemplate on DropTemplate {
    title
    amount
    template
    dropUid
    latitude
    longitude
    link
    buttonText
    utm_source
    utm_campaign
    utm_medium
    imageUrl
    quadkey
    fullQuadkey
    email
    message
    createdTime
    condition {
      advancedId
      variant
      collected
    }
  }
`;

export const ADVANCED_DROP_FRAGMENT = gql`
  fragment AdvancedDrop on AdvancedDrop {
    id
    title
    account
    dropUid
    active
    status
    createdTime
    updatedTime
    maxSpend
    currentBalance
    currentGiftBalance
    currentGiftSpend
    currentSpend
    maxDailySpend
    currentDailySpend
    startTime
    endTime
    rejectionReason
    lastExecutionTime
    lastExecutionError
    lastExecutionErrorTime
    executing
    executingStarted
    executingFinished
  }
`;

export const DROPS = gql`
  query UserDropsPage(
    $page: Int
    $perPage: Int
    $field: String
    $filter: String
  ) {
    drops(
      filter: $filter
      pagination: { page: $page, perPage: $perPage }
      sort: { field: $field, order: -1 }
    ) {
      total
      page
      data {
        ...Drop
      }
    }
  }
  ${DROP_FRAGMENT}
`;

export const DROP_TEMPLATES = gql`
  query DropTemplates {
    dropTemplates {
      id
      ...DropTemplate
    }
  }
  ${DROP_TEMPLATE_FRAGMENT}
`;

export const ADVANCED_DROP = gql`
  query AdvancedUserDrop($id: ObjectId!) {
    advancedDrop(id: $id) {
      ...AdvancedDrop
      drop {
        id
        ...DropTemplate
      }
      variants {
        ...DropTemplate
      }
    }
  }

  ${ADVANCED_DROP_FRAGMENT}
  ${DROP_TEMPLATE_FRAGMENT}
`;

export const ADVANCED_DROPS = gql`
  query AdvancedUserDrops(
    $page: Int
    $perPage: Int
    $field: String
    $yyyymmdd: IntFilter
    $search: AdvancedDropSearch
  ) {
    advancedDrops(
      search: $search
      pagination: { page: $page, perPage: $perPage }
      sort: { field: $field, order: -1 }
    ) {
      total
      page
      data {
        ...AdvancedDrop
        drop {
          id
          ...DropTemplate
        }
        variants {
          ...DropTemplate
        }
        advancedDropDailyStats(yyyymmdd: $yyyymmdd) {
          _id
          advancedId
          variant
          yyyymmdd
          linkClickedRate
        }
      }
    }
  }

  ${ADVANCED_DROP_FRAGMENT}
  ${DROP_TEMPLATE_FRAGMENT}
`;

export const ADVANCED_DROPS_ADMIN = gql`
  query AdvancedUserDropsAdmin(
    $page: Int
    $perPage: Int
    $field: String
    $order: Int
    $search: AdvancedDropSearch
  ) {
    advancedDropsAdmin(
      search: $search
      pagination: { page: $page, perPage: $perPage }
      sort: { field: $field, order: $order }
    ) {
      total
      page
      data {
        ...AdvancedDrop
        drop {
          id
          ...DropTemplate
        }
        variants {
          ...DropTemplate
        }
      }
    }
  }

  ${ADVANCED_DROP_FRAGMENT}
  ${DROP_TEMPLATE_FRAGMENT}
`;

export const CURRENT_EXECUTING_DROPS = gql`
query CurrentExecutingDrops(
  $page: Int
  $perPage: Int
  $field: String
  $order: Int
) {
  advancedDropsAdmin(
    search: {
      executing: true
    }
    pagination: { page: $page, perPage: $perPage }
    sort: { field: $field, order: $order }
  ) {
    total
    page
    data {
      ...AdvancedDrop
      drop {
        id
        ...DropTemplate
      }
      variants {
        ...DropTemplate
      }
    }
  }
}
${ADVANCED_DROP_FRAGMENT}
${DROP_TEMPLATE_FRAGMENT}
`

export const RESET_EXECUTING_ADVANCED_DROPS = gql`
mutation resetExecutingAdvancedDrops {
  resetExecutingAdvancedDrops {
    ...AdvancedDrop
    drop {
      id
      ...DropTemplate
    }
    variants {
      ...DropTemplate
    }
  }
}

${ADVANCED_DROP_FRAGMENT}
${DROP_TEMPLATE_FRAGMENT}
`

export const CREATE_DROP = gql`
  mutation CreateDrop(
    $template: String
    $amount: Float
    $latitude: Float
    $longitude: Float
    $fee: Float
    $link: String
    $buttonText: String
    $utm_source: String
    $utm_campaign: String
    $utm_medium: String
    $imageUrl: String
    $quadkey: String
    $fullQuadkey: String
    $email: String
    $message: String
    $quantity: Int
  ) {
    createDrops(
      quantity: $quantity
      input: {
        template: $template
        amount: $amount
        latitude: $latitude
        longitude: $longitude
        fee: $fee
        link: $link
        buttonText: $buttonText
        utm_source: $utm_source
        utm_campaign: $utm_campaign
        utm_medium: $utm_medium
        imageUrl: $imageUrl
        quadkey: $quadkey
        fullQuadkey: $fullQuadkey
        email: $email
        message: $message
      }
    ) {
      ...Drop
    }
  }
  ${DROP_FRAGMENT}
`;

export const CREATE_DROP_TEMPLATE = gql`
  mutation CreateDropTemplate(
    $template: String
    $amount: Float
    $latitude: Float
    $longitude: Float
    $link: String
    $buttonText: String
    $utm_source: String
    $utm_campaign: String
    $utm_medium: String
    $imageUrl: String
    $quadkey: String
    $fullQuadkey: String
    $email: String
    $message: String
  ) {
    createDropTemplate(
      input: {
        template: $template
        amount: $amount
        latitude: $latitude
        longitude: $longitude
        link: $link
        buttonText: $buttonText
        utm_source: $utm_source
        utm_campaign: $utm_campaign
        utm_medium: $utm_medium
        imageUrl: $imageUrl
        quadkey: $quadkey
        fullQuadkey: $fullQuadkey
        email: $email
        message: $message
      }
    ) {
      id
      ...DropTemplate
    }
  }
  ${DROP_TEMPLATE_FRAGMENT}
`;

export const UPDATE_DROP_TEMPLATE = gql`
  mutation UpdateDropTemplate(
    $id: ObjectId!
    $template: String
    $amount: Float
    $latitude: Float
    $longitude: Float
    $link: String
    $buttonText: String
    $utm_source: String
    $utm_campaign: String
    $utm_medium: String
    $imageUrl: String
    $quadkey: String
    $fullQuadkey: String
    $email: String
    $message: String
  ) {
    updateDropTemplate(
      id: $id
      input: {
        template: $template
        amount: $amount
        latitude: $latitude
        longitude: $longitude
        link: $link
        buttonText: $buttonText
        utm_source: $utm_source
        utm_campaign: $utm_campaign
        utm_medium: $utm_medium
        imageUrl: $imageUrl
        quadkey: $quadkey
        fullQuadkey: $fullQuadkey
        email: $email
        message: $message
      }
    ) {
      id
      ...DropTemplate
    }
  }
  ${DROP_TEMPLATE_FRAGMENT}
`;

export const CREATE_ADVANCED_DROP = gql`
  mutation CreateAdvancedDrop(
    $options: AdvancedOptionsInput!
    $drop: DropInput!
    $variants: [DropInput]
  ) {
    createAdvancedDrop(options: $options, input: $drop, variants: $variants) {
      ...AdvancedDrop
      drop {
        id
        ...DropTemplate
      }
      variants {
        ...DropTemplate
      }
    }
  }

  ${ADVANCED_DROP_FRAGMENT}
  ${DROP_TEMPLATE_FRAGMENT}
`;

export const UPDATE_ADVANCED_DROP = gql`
  mutation UpdateAdvancedDrop(
    $id: ObjectId!
    $options: AdvancedOptionsInput
    $drop: DropInput
    $variants: [DropInput]
  ) {
    updateAdvancedDrop(
      id: $id
      options: $options
      input: $drop
      variants: $variants
    ) {
      ...AdvancedDrop
      drop {
        id
        ...DropTemplate
      }
      variants {
        ...DropTemplate
      }
    }
  }

  ${ADVANCED_DROP_FRAGMENT}
  ${DROP_TEMPLATE_FRAGMENT}
`;

export const DEPOSIT_ADVANCED_DROP = gql`
  mutation DepositAdvancedDrop($id: ObjectId!, $amount: Float!) {
    depositAdvancedDrop(id: $id, amount: $amount) {
      ...AdvancedDrop
      drop {
        id
        ...DropTemplate
      }
      variants {
        ...DropTemplate
      }
    }
  }

  ${ADVANCED_DROP_FRAGMENT}
  ${DROP_TEMPLATE_FRAGMENT}
`;

export const WITHDRAW_ADVANCED_DROP = gql`
  mutation WithdrawAdvancedDrop($id: ObjectId!, $amount: Float!) {
    withdrawAdvancedDrop(id: $id, amount: $amount) {
      ...AdvancedDrop
      drop {
        id
        ...DropTemplate
      }
      variants {
        ...DropTemplate
      }
    }
  }

  ${ADVANCED_DROP_FRAGMENT}
  ${DROP_TEMPLATE_FRAGMENT}
`;

export const RECALL_DROP = gql`
  mutation RecallDrop($id: ObjectId!) {
    recallDrop(id: $id) {
      ...Drop
    }
  }
  ${DROP_FRAGMENT}
`;

export const REMOVE_DROP_TEMPLATE = gql`
  mutation RemoveDropTemplate($id: ObjectId!) {
    removeTemplate(id: $id) {
      id
      ...DropTemplate
    }
  }
  ${DROP_TEMPLATE_FRAGMENT}
`;

export const REMOVE_ADVANCED_DROP = gql`
  mutation RemoveAdvancedDrop($id: ObjectId!) {
    removeAdvancedDrop(id: $id) {
      ...AdvancedDrop
    }
  }
  ${ADVANCED_DROP_FRAGMENT}
`;

export const APPROVE_ADVANCED_DROPS = gql`
  mutation ApproveAdvancedDrops($ids: [ObjectId!]!) {
    approveAdvancedDrops(ids: $ids) {
      ...AdvancedDrop
    }
  }
  ${ADVANCED_DROP_FRAGMENT}
`;

export const REJECT_ADVANCED_DROPS = gql`
  mutation RejectAdvancedDrops($ids: [ObjectId!]!, $reason: String) {
    rejectAdvancedDrops(ids: $ids, reason: $reason) {
      ...AdvancedDrop
    }
  }
  ${ADVANCED_DROP_FRAGMENT}
`;

export const ADVANCED_DROP_DAILY_STATS_PAGE = gql`
  query AdvancedDropDailyStatsPage(
    $advancedId: ObjectId!
    $lastYYYYMMDD: Int
    $perPage: Int
  ) {
    advancedDropDailyStatsPage(
      lastYYYYMMDD: $lastYYYYMMDD
      search: { advancedId: $advancedId }
      pagination: { perPage: $perPage }
      sort: { field: "yyyymmdd", order: -1 }
    ) {
      total
      data {
        _id
        advancedId
        variant
        yyyymmdd
        count
        dailySpend
        claimed
        collected
        people: collected
        linkClicked
        converted
      }
    }
  }
`;

export const ADVANCED_DROP_DAILY_STATS = gql`
  query AdvancedDropDailyStats(
    $advancedId: ObjectIdFilter
    $yyyymmdd: IntFilter
  ) {
    advancedDropDailyStats(advancedId: $advancedId, yyyymmdd: $yyyymmdd) {
      _id
      advancedId
      variant
      yyyymmdd
      count
      dailySpend
      claimed
      collected
      people: collected
      linkClicked
      converted
    }
  }
`;

export const ADVANCED_DROP_HOURLY_STATS = gql`
  query AdvancedDropHourlyStats(
    $advancedId: ObjectIdFilter
    $yyyymmddhh: IntFilter
  ) {
    advancedDropHourlyStats(advancedId: $advancedId, yyyymmddhh: $yyyymmddhh) {
      _id
      advancedId
      variant
      yyyymmddhh
      count
      dailySpend
      claimed
      collected
      people: collected
      linkClicked
      converted
    }
  }
`;

export const DROP_SUMMARY_ADMIN = gql`
  query DropSummary($advancedId: ObjectId, $gte: Date!, $lte: Date!) {
    dropSummaryAdmin(advancedId: $advancedId, dateGte: $gte, dateLte: $lte) {
      dailySpend
      count
      claimed
      collected
      linkClicked
      converted
    }
    implementations(advancedId: $advancedId) {
      url
      implemented
    }
  }
`;

export const DROP_SUMMARY = gql`
  query DropSummary($advancedId: ObjectId, $gte: Date!, $lte: Date!) {
    totalDrops: drops(
      filter: "dropsCreated"
      search: { advancedId: $advancedId, dropTime: { GTE: $gte, LTE: $lte } }
    ) {
      total
    }

    claimed: drops(
      filter: "dropsCreated"
      search: { advancedId: $advancedId, claimedTime: { GTE: $gte, LTE: $lte } }
    ) {
      total
    }

    engagements: drops(
      filter: "dropsCreated"
      search: {
        advancedId: $advancedId
        collectedTime: { GTE: $gte, LTE: $lte }
        collected: true
      }
    ) {
      total
    }

    linkClicks: drops(
      filter: "dropsCreated"
      search: {
        advancedId: $advancedId
        linkClickedTime: { GTE: $gte, LTE: $lte }
        linkClicked: true
      }
    ) {
      total
    }

    conversions: drops(
      filter: "dropsCreated"
      search: {
        advancedId: $advancedId
        conversionTime: { GTE: $gte, LTE: $lte }
        converted: true
      }
    ) {
      total
    }

    implementations(advancedId: $advancedId) {
      url
      implemented
    }
  }
`;

export const LOCATION_SUMMARY_QUERY = gql`
  query LocationSummary(
    $uid: String
    $timeframe: StringFilter
    $near: NearFilter
    $geoWithin: GeoWithinFilter
  ) {
    locationSummary(
      search: { timeframe: $timeframe, uid: $uid }
      near: $near
      geoWithin: $geoWithin
    ) {
      total
      count
      data {
        uid
        timeframe
        lat
        lon
        count
      }
    }
  }
`;

export const DROP_FENCES = gql`
  query DropFences {
    geoFences {
      _id
      uid
      name
      coordinates
    }
  }
`;

export const CREATE_DROP_FENCE = gql`
  mutation CreateDropFence($input: GeoFenceInput!) {
    createGeoFence(input: $input) {
      _id
      uid
      name
      coordinates
    }
  }
`;

export const REMOVE_DROP_FENCE = gql`
  mutation RemoveDropFence($id: ObjectId!) {
    removeGeoFence(_id: $id) {
      _id
      uid
      name
      coordinates
    }
  }
`;

export const ADVANCED_DROP_EXECUTION_FRAGMENT = gql`
  fragment AdvancedDropExecution on AdvancedDropExecution {
    _id
    status
    advancedIds
    advancedId
    executionDate
    executionAccount
    executionUid
    executionMs
    executionError
    currentlyAvailable
    currentlySpendable
    quadkeys
    emails
    claimedUids
    previousBossBalance
    currentBossBalance
    total
    quantity
    counts
  }
`;

export const ADVANCED_DROP_EXECUTION_PAGE = gql`
  query AdvancedDropExecutionPage(
    $page: Int
    $perPage: Int
    $search: AdvancedDropExecutionSearch
  ) {
    advancedDropsExecution(
      pagination: { page: $page, perPage: $perPage }
      sort: { field: "executionDate", order: -1 }
      search: $search
    ) {
      total
      page
      data {
        ...AdvancedDropExecution
      }
    }
  }

  ${ADVANCED_DROP_EXECUTION_FRAGMENT}
`;

export const ADVANCED_DROP_EXECUTION_TIME = gql`
  query AdvancedDropExecutionTime(
    $page: Int
    $perPage: Int
    $search: AdvancedDropExecutionSearch
  ) {
    advancedDropsExecution(
      pagination: { page: $page, perPage: $perPage }
      sort: { field: "executionDate", order: -1 }
      search: $search
    ) {
      total
      page
      data {
        _id
        executionDate
        executionMs
      }
      stats {
        mean
        count
        stdDev
        p50
        p75
        p90
        p95
      }
    }
  }
`;

export const ADVANCED_DROP_EXECUTION = gql`
  query AdvancedDropExecution($id: ObjectId!) {
    advancedDropExecution(id: $id) {
      ...AdvancedDropExecution
      log
    }
  }
  ${ADVANCED_DROP_EXECUTION_FRAGMENT}
`;

export const EXECUTE_ADVANCED_DROP = gql`
  mutation ExecuteAdvancedDrop($advancedId: ObjectId!) {
    executeAdvancedDrop(id: $advancedId) {
      ...AdvancedDropExecution
      log
    }
  }
  ${ADVANCED_DROP_EXECUTION_FRAGMENT}
`;

export const UPSERT_RING_RECENT = gql`
  mutation upsertRingRecent($input: RingRecentInput!) {
    upsertRingRecent(input: $input) {
      _id
      hasLatLon
      isMining
      collectDrop
      clearExistingMaphash
      accountXyoCollected
      uid
      email
      ipAddress
      fullMaphash
      minMaphash
      maxMaphash
      latitude
      longitude
      os
      osVersion
      osDevMode
      sentinels
      altitude
      accuracy
      ibeacons
      appVersion
      speed
      direction
      isAutoMining
      isAnonymous
      deviceId
      deviceName
      ssid
      referrer
      referrerUid
      mob
      color
      date
      currentTime
      yyyymmdd
      activeSubscription
      claimCampaign
      claimQuadkey
      xyoEnabled
      adQueue
      adsViewed
      adsDisabled
      previousSubscriber
      mineCount
      xyoCollected
      xyoRedeemed
      uidDaysOld
      expireSeconds
      expireEpoch
      type
    }
  }
`;

export const CRYPTO_PRICE_CURRENT = gql`
  query CryptoPriceCurrent {
    cryptoPriceCurrent {
      XYO
      BTC
      ETH
    }
  }
`;
