import React, {
  createContext,
  useContext,
  Children,
  useReducer,
  useEffect,
  useRef
} from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { FadeIn, SlideInRight, SlideInLeft } from "../../components/Animate";

export default ({ children, prev, step, setStep }) => {
  const validSteps = useRef({});
  const steps = Children.toArray(children);
  useEffect(() => {
    validSteps.current[step] = true;
  }, [step]);
  return (
    <div>
      <Stepper activeStep={step} className="px-0 pt-0 pb-3 bg-transparent">
        {steps.map((step, i) =>
          step.props.label ? (
            <Step
              key={step.props.label}
              onClick={() => setStep && validSteps.current[i] && setStep(i)}
              className={
                setStep && validSteps.current[i] ? "cursor-pointer" : ""
              }
            >
              <StepLabel>{step.props.label}</StepLabel>
            </Step>
          ) : null
        )}
      </Stepper>
      {steps.reduce((activeStep, stepCmp, i) => {
        if (activeStep) return activeStep;
        if (step === i && typeof prev !== "number")
          return <SlideInLeft key={i}>{stepCmp}</SlideInLeft>;
        if (step === i && step > prev)
          return <FadeIn key={i}>{stepCmp}</FadeIn>;
        if (step === i && step <= prev)
          return <FadeIn key={i}>{stepCmp}</FadeIn>;
        return null;
      }, null) || <div />}
    </div>
  );
};
