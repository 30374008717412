import Button from "@material-ui/core/Button";
import ButtonBase from "@material-ui/core/ButtonBase";
import Checkbox from "@material-ui/core/Checkbox";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import PlusIcon from "@material-ui/icons/Add";
import get from "lodash/get";
import moment from "moment";
import React, { useRef, useState, createContext, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import { useUserInfo } from "../../context/auth";
import Form, { FormField } from "../Form";
import StepForm from "../Form/Steps";
import { useSnackbar } from "../Snackables";
import {
  ADVANCED_DROPS,
  CREATE_ADVANCED_DROP,
  UPDATE_ADVANCED_DROP,
} from "./gql";
import { frequencyToHumanReadable } from "./helpers";
import DropListDetails from "./ListDetails";
import { AdvancedListDetails } from "./AdvancedListDetails";
import { SlideInLeft, SlideInRight } from "../Animate";
import { WarningDisplay, useContentDialogContentStyles } from "./MenuCard";
import DropPreview from "./Preview";
import {
  DropFormFields,
  DropLocationFields,
  pickAdvancedDropUpdate,
  createDropValidator,
  createDropOptionsValidator,
  DateTimeSelect,
  TextFieldPerspective,
  ScrollProvider,
  DropDetailsForm,
} from "./TemplateForm";
import { trackInfo } from "../../utils/track";

const DropStepFormBase = ({
  id,
  initialDrop,
  initialVariants,
  initialOptions,
  onConfirm,
  onCancel,
  loading,
}) => {
  const classes = useContentDialogContentStyles();
  const contentRef = useRef(null);
  const [active, setActive] = useState(0);
  const [step, setStep] = useState(0);
  const [drop, setDrop] = useState(initialDrop);
  const [variants, setVariants] = useState(initialVariants);
  const [options, setOptions] = useState(initialOptions);
  const variantOffset = variants ? variants.length : 0;
  const setVariant = (i, values) =>
    setVariants(
      []
        .concat(variants || [])
        .map((v, j) => (i === j ? { ...v, ...values } : v))
    );
  const addVariant = () =>
    setVariants(
      []
        .concat(variants || [])
        .concat(
          variants && variants.length
            ? { ...variants[variants.length - 1] }
            : { ...drop }
        )
    );
  const removeVariant = (i) => {
    setVariants([].concat(variants || []).filter((v, j) => i !== j));
  };
  return (
    <ScrollProvider value={contentRef.current}>
      <DialogContent className={classes.content} ref={contentRef}>
        <StepForm step={step} setStep={setStep}>
          <DropAdvancedForm
            id={id}
            label="Options"
            drop={drop}
            initial={options}
            onSubmit={(opts) => {
              setStep(step + 1);
              setOptions(opts);
            }}
          />
          <DropAdSetForm
            id={id}
            label="Details"
            drop={drop}
            step={step}
            active={active}
            options={options}
            variants={variants}
            setActive={setActive}
            setStep={setStep}
            setDrop={setDrop}
            setVariant={setVariant}
            addVariant={addVariant}
            removeVariant={removeVariant}
          />
          <DropDetailsConfirm
            label="Confirm"
            drop={drop}
            options={options}
            variants={variants}
          />
        </StepForm>
      </DialogContent>
      <DialogActions>
        {step === 1 && active ? (
          <>
            <div style={{ width: 290 }} />
            <Button
              size="small"
              color="primary"
              className="mr-auto"
              onClick={() => {
                removeVariant(active - 1);
                setActive(active - 1);
              }}
            >
              Remove
            </Button>
          </>
        ) : null}
        {step ? (
          <Button onClick={() => setStep(step - 1)} color="primary">
            Back
          </Button>
        ) : (
          <Button onClick={onCancel} color="primary">
            Cancel
          </Button>
        )}
        {step === 2 ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              onConfirm(pickAdvancedDropUpdate({ ...options, drop, variants }))
            }
            disabled={loading}
          >
            Confirm{" "}
            {loading && (
              <span className="spinner-border spinner-border-sm ml-2" />
            )}
          </Button>
        ) : (
          <Button color="primary" form={id} type="submit" variant="contained">
            Next
          </Button>
        )}
      </DialogActions>
    </ScrollProvider>
  );
};

export const UpdateDropStepForm = ({
  id,
  advancedDrop,
  onSuccess,
  onCancel,
}) => {
  const [{}, { setSnackbar }] = useSnackbar();
  const [updateAdvancedDrop, advanced] = useMutation(UPDATE_ADVANCED_DROP, {
    variables: {},
    update: (cache, result) => {
      trackInfo("Updated Advanced Drop", result);
      return setSnackbar({
        message: "Advanced drop updated successfully",
      });
    },
  });
  const onConfirm = async ({ options, drop, variants }) => {
    await updateAdvancedDrop({
      variables: {
        id: advancedDrop.id,
        variants,
        drop,
        options,
      },
    });
    if (onSuccess) {
      onSuccess();
    }
  };
  return advancedDrop ? (
    <DropStepFormBase
      id={id}
      loading={advanced.loading}
      error={advanced.error}
      onCancel={onCancel}
      onConfirm={onConfirm}
      initialDrop={{ ...advancedDrop.drop }}
      initialOptions={{ ...advancedDrop }}
      initialVariants={advancedDrop.variants || []}
    />
  ) : null;
};

export const CreateDropStepForm = ({ id, initial, onSuccess, onCancel }) => {
  const refetchQueries = [
    {
      query: ADVANCED_DROPS,
      variables: {
        page: 1,
        perPage: 10,
        field: "updatedTime",
        order: -1,
      },
    },
  ];
  const [{}, { setSnackbar }] = useSnackbar();
  const [createAdvancedDrop, advanced] = useMutation(CREATE_ADVANCED_DROP, {
    refetchQueries,
    variables: {},
    update: (cache, result) => {
      trackInfo("Created Advanced Drop", result);
      return setSnackbar({
        message: "Advanced drop created successfully",
      });
    },
  });
  const onConfirm = async ({ options, drop, variants }) => {
    await createAdvancedDrop({
      variables: {
        variants,
        drop,
        options: {
          active: true,
          ...options,
        },
      },
    });
    if (onSuccess) {
      onSuccess();
    }
  };
  return (
    <DropStepFormBase
      id={id}
      loading={advanced.loading}
      error={advanced.error}
      onCancel={onCancel}
      onConfirm={onConfirm}
      initialDrop={initial}
      initialVariants={[]}
      initialOptions={{
        // maxSpend: 10,
        maxDailySpend: 10,
        startTime: new Date().toString(),
      }}
    />
  );
};

export const DropAdSetForm = ({
  id,
  step,
  active,
  setActive,
  drop,
  options,
  variants,
  setDrop,
  setVariant,
  addVariant,
  removeVariant,
  setStep,
}) => {
  return (
    <div className="row flex-md-row-reverse">
      <div className="col">
        <StepForm step={active} setStep={setActive}>
          <DropDetailsForm
            id={id}
            currentBalance={(options && options.currentBalance) || 0}
            onSubmit={(values) => {
              if (!variants || !variants.length) {
                setStep(step + 1);
              } else {
                setActive(active + 1);
              }
              setDrop(values);
            }}
            onChange={({ values }) => setDrop(values)}
            initial={drop}
          />
          {(variants || []).map((variant, i) => (
            <DropDetailsForm
              id={id}
              key={i}
              currentBalance={(options && options.currentBalance) || 0}
              onSubmit={(values) => {
                setVariant(i, values);
                if (active === variants.length) {
                  setStep(step + 1);
                } else {
                  setActive(active + 1);
                }
              }}
              onChange={({ values }) => setVariant(i, values)}
              initial={variant}
            />
          ))}
        </StepForm>
      </div>
      <DropVariationPreview
        active={active}
        setActive={setActive}
        drop={drop}
        variants={variants}
        addVariant={addVariant}
      />
    </div>
  );
};

export const DropAdvancedForm = ({ id, onSubmit, initial, drop }) => {
  const userInfo = useUserInfo();
  const prevEndDate = useRef(null);
  const [ongoing, setOngoing] = useState(!initial.endTime);
  const xyoBossBalance =
    (get(userInfo, "xyoBossBalance") || 0) +
    (get(initial, "currentBalance") || 0);
  return (
    <Form
      id={id}
      onSubmit={onSubmit}
      initial={initial}
      validator={createDropOptionsValidator(userInfo)}
    >
      {({ values, handleChange }) => (
        <>
          <FormField
            id="title"
            name="title"
            component={TextField}
            helperText="Only visible to you, users will not see this field"
            label="Campaign Title"
            variant="outlined"
            margin="normal"
            fullWidth
          />
          {/* <FormField
            id="maxSpend"
            name="maxSpend"
            component={TextField}
            label="Total Budget"
            variant="outlined"
            margin="normal"
            type="number"
            min={10}
            max={xyoBossBalance}
            fullWidth
          />
          <Slider
            min={10}
            max={xyoBossBalance}
            valueLabelDisplay="auto"
            value={values.maxSpend}
            onChange={(_, v) =>
              handleChange({ target: { name: "maxSpend" } }, v)
            }
          /> */}
          <FormField
            id="maxDailySpend"
            name="maxDailySpend"
            component={TextField}
            label="Daily Budget"
            variant="outlined"
            margin="normal"
            type="number"
            min={10}
            max={xyoBossBalance}
            fullWidth
          />
          <Slider
            min={10}
            max={xyoBossBalance}
            valueLabelDisplay="auto"
            value={values.maxDailySpend}
            onChange={(_, v) =>
              handleChange({ target: { name: "maxDailySpend" } }, v)
            }
          />
          <FormField
            id="startTime"
            name="startTime"
            component={DateTimeSelect}
            label="Start Time"
            variant="inline"
            inputVariant="outlined"
            helperText={
              <Tooltip title="Actual start time is dependent on content approval, which can take up to three business days.">
                <span>
                  When to start the advanced drop{" "}
                  <i className="fa fa-info ml-1" />
                </span>
              </Tooltip>
            }
            margin="normal"
            fullWidth
          />
          <div className="d-flex align-items-center">
            <FormControlLabel
              control={
                <Checkbox
                  id="ongoing"
                  name="ongoing"
                  type="checkbox"
                  checked={ongoing}
                  onChange={(ev) => {
                    const value = ev.target.checked;
                    setOngoing(value);
                    handleChange(
                      { target: { name: "endTime" } },
                      value ? "" : moment().add(1, "day").toString()
                    );
                  }}
                  variant="inline"
                  margin="normal"
                />
              }
              label="Run as ongoing"
            />
          </div>
          {!ongoing && (
            <FormField
              id="endTime"
              name="endTime"
              component={DateTimeSelect}
              label="End Time"
              variant="inline"
              inputVariant="outlined"
              helperText="When to end the advanced drop"
              margin="normal"
              fullWidth
            />
          )}
        </>
      )}
    </Form>
  );
};

const DropDetailsConfirm = ({ drop, options, variants, loading }) => {
  const advancedDrop = { ...options, variants, drop };
  const message = getDropDetailsConfirmationWarning(drop, options);
  return (
    <>
      <AdvancedListDetails advancedDrop={advancedDrop} />
      <WarningDisplay message={message} />
      <Divider className="mb-4" />
      <div className="d-flex flex-wrap justify-content-center">
        <div className="px-3 mb-4" style={{ width: 290 }}>
          <Typography variant="caption">Ad #1</Typography>
          <DropPreview
            drop={advancedDrop && advancedDrop.drop}
            loading={loading}
          />
        </div>
        {((advancedDrop && advancedDrop.variants) || []).map((variant, i) => (
          <div className="px-3 mb-4" key={i} style={{ width: 290 }}>
            <Typography variant="caption">Ad #{i + 2}</Typography>
            <DropPreview drop={variant} loading={loading} />
          </div>
        ))}
      </div>
    </>
  );
};

const DropVariationPreview = ({
  active,
  drop,
  variants,
  setActive,
  addVariant,
}) => {
  return (
    <div
      className="my-4 px-md-3 d-flex flex-column align-items-center mx-auto mx-md-0"
      style={{ width: 290 }}
    >
      {[drop].concat(variants).map((d, i) =>
        active === i ? (
          <SlideInRight className="w-100" key={i}>
            <ButtonBase
              key={i}
              className="w-100 mb-4"
              variant="contained"
              TouchRippleProps={{
                style: { color: "rgba(0,0,0,0.4)" },
              }}
            >
              <DropPreview drop={d} />
            </ButtonBase>
          </SlideInRight>
        ) : null
      )}
      <Grid container spacing={1}>
        {variants && variants.length
          ? [drop].concat(variants).map((d, i) => (
              <Grid item key={i} xs={4}>
                <Button
                  size="small"
                  className="position-relative mb-1 px-0 summary-card"
                  style={{ minWidth: "100%" }}
                  onClick={() => setActive(i)}
                  color={active === i ? "primary" : "default"}
                  margin="normal"
                >
                  <span>Ad #{i + 1}</span>
                </Button>
              </Grid>
            ))
          : null}
        {variants && variants.length >= 5 ? null : (
          <Grid item>
            <Button
              size="small"
              onClick={() => {
                addVariant();
                setActive(variants ? variants.length + 1 : 1);
              }}
            >
              <PlusIcon className="mr-2" />{" "}
              {variants && variants.length ? "" : "Add Variation"}
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

const getDropDetailsConfirmationWarning = (drop, options) => {
  let message = "This drop will repeat";
  if (options && options.frequency)
    message += ` <b>${frequencyToHumanReadable(
      options.frequency
    ).toLowerCase()}</b>`;
  else message += " <b>daily</b>";
  if (options && options.endTime)
    message += ` until <b>${new Date(options.endTime).toLocaleString()}</b>.`;
  else message += " until <b>canceled</b>.";
  return message;
};
