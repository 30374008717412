import React, { useEffect } from "react";
import { WelcomeText, Footer, FooterLinks } from "../../components/Layout";
import { ui, auth, firebaseUI } from "../../context/auth";
import { withRouter } from "react-router-dom";
import { fromSearch } from "../../utils";

export default withRouter(({ history }) => {
  const { redirect } = fromSearch(history.location.search);
  useEffect(() => {
    ui.start("#auth-container", {
      credentialHelper: firebaseUI.auth.CredentialHelper.NONE,
      signInOptions: [auth.EmailAuthProvider.PROVIDER_ID],
      callbacks: {
        signInSuccessWithAuthResult: () => {
          setImmediate(() => history.push((redirect as string) || "/"));
          return false;
        }
      }
    });
  }, [redirect]);
  return (
    <>
      <div className="mb-4" />
      <WelcomeText className="text-center text-white my-5 px-1" />
      <div className="container" style={{ paddingBottom: "15vh" }}>
        <div id="auth-container" style={{ minHeight: 215 }} />
      </div>
      <Footer />
      <FooterLinks />
    </>
  );
});
