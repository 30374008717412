import React, { FC, useState, useLayoutEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import BossLogo from "../Logo/BossLogoBeta";
import MoreIcon from "@material-ui/icons/MoreVert";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import Settings from "@material-ui/icons/Settings";
import ListItemText from "@material-ui/core/ListItemText";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import { UserBalance, UserInfoDisplay, SelectAccount } from "../User";
import { useAdvancedDropBottomNav } from "../Layout/BottomNav";
import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useAuth } from "../../context/auth";
import size from "lodash/size";
import get from "lodash/get";
import gql from "graphql-tag";
import MenuItem from "@material-ui/core/MenuItem";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";
import Fab from "@material-ui/core/Fab";

export const Navbar = () => {
  const { user } = useAuth();
  return (
    <div
      className="navbar navbar-expand-lg px-1 px-sm-2 flex-nowrap"
      style={{ zIndex: 1 }}
    >
      <Link className="navbar-brand mr-0" to="/">
        <BossLogo />
      </Link>
      {user && (
        <div className="ml-auto d-flex flex-nowrap">
          <UserBalance />
          <NotificationMenu />
          <AccountMenu />
        </div>
      )}
    </div>
  );
};

export const AccountMenu = () => {
  const { signOut } = useAuth();
  const [supportOpen, setSupportOpen] = useState(false);
  const [accountEl, setAccountEl] = useState(null);
  const handleAccountMenu = (event: any) => {
    setAccountEl(event.currentTarget);
  };
  const handleClose = (fn: any) => {
    setAccountEl(null);
    if (typeof fn === "function") fn();
  };
  const handleCloseSupport = () => {
    setSupportOpen(false);
  };
  const handleSuccess = () => {
    setSupportOpen(false);
  };
  return (
    <>
      <IconButton className="mx-1 py-1 px-2" onClick={handleAccountMenu}>
        <MoreIcon />
      </IconButton>
      <Menu
        id="account-appbar"
        anchorEl={accountEl}
        open={!!accountEl}
        onClose={handleClose}
        style={{ width: 260 }}
      >
        <div className="p-3">
          <UserInfoDisplay>
            {/* <div className="mt-4 w-100">
              <SelectAccount />
            </div> */}
            <div className="d-flex mt-4">
              <Button
                className="mr-2"
                onClick={() => handleClose(signOut)}
                variant="contained"
                color="primary"
              >
                Logout <i className="fa fa-sign-out-alt ml-2" />
              </Button>
              <Tooltip title="Contact Support">
                <Fab
                  component="a"
                  color="secondary"
                  href="https://support.coinapp.co/hc/en-us/requests/new"
                  target="_blank"
                >
                  <ContactSupportIcon />
                </Fab>
              </Tooltip>
              {/* <Button
                component={Link}
                to="/account"
                variant="contained"
                onClick={() => handleClose(() => {})}
              >
                <Settings />
              </Button> */}
            </div>
          </UserInfoDisplay>
        </div>
      </Menu>
    </>
  );
};
export const NotificationMenu = () => {
  const history = useHistory();
  const { initialized, userInfo } = useAuth();
  const [open, modalControls] = useAdvancedDropBottomNav();
  const [notificationEl, setNotificationEl] = useState(null);
  const { data, loading, error } = useQuery(NOTIFICATIONS, {
    skip: !userInfo,
  });
  const [click] = useMutation(CLICKED_NOTIFICATION, {
    refetchQueries: [{ query: NOTIFICATIONS }],
  });
  const [read] = useMutation(READ_NOTIFICATIONS, {
    // refetchQueries: [{ query: NOTIFICATIONS }]
  });
  const handleNotificationMenu = (event: any) => {
    setNotificationEl(event.currentTarget);
    read({
      variables: {
        ids: notifications.map(({ _id }: any) => _id),
      },
    });
  };
  const handleNotificationClick = async ({ _id, type, payload }: any) => {
    setNotificationEl(null);
    click({
      variables: {
        id: _id,
      },
    });
    if (type === "route" && payload) {
      history.push(payload);
    } else {
      modalControls.setTrue();
    }
  };
  const handleClose = (fn: any) => {
    setNotificationEl(null);
    if (typeof fn === "function") fn();
  };
  const notifications = get(data, "notifications") || [];
  const badgeCount = size(notifications.filter(({ viewed }: any) => !viewed));
  return (
    <>
      {notifications.length ? (
        <IconButton className="ml-4 mx-1" onClick={handleNotificationMenu}>
          <Badge badgeContent={badgeCount} color="primary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
      ) : null}
      <Menu
        id="notification-appbar"
        anchorEl={notificationEl}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!notificationEl}
        onClose={handleClose}
        style={{ width: 260, padding: 0 }}
        classes={{ list: "p-0" }}
      >
        {notifications.length ? (
          notifications.map((notification: any, i: number) => [
            <MenuItem
              key={notification._id || i}
              onClick={() => handleNotificationClick(notification)}
              style={{ width: 240 }}
            >
              {notification.imageUrl && (
                <ListItemAvatar>
                  <Avatar src={notification.imageUrl} />
                </ListItemAvatar>
              )}
              <ListItemText
                primary={notification.title}
                secondary={notification.subTitle}
                primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
                secondaryTypographyProps={{ style: { whiteSpace: "normal" } }}
              />
            </MenuItem>,
            <Divider key={`${notification._id || i}_divider`} />,
          ])
        ) : (
          <ListItem style={{ width: 240 }}>
            <ListItemText
              primary="Nothing here"
              secondary="You are all caught up."
            />
          </ListItem>
        )}
      </Menu>
    </>
  );
};

export const WelcomeText: FC<{ className?: string }> = ({ className }) => (
  <h1 className={className} style={{ letterSpacing: 2 }}>
    Get Paid to Explore the World!
  </h1>
);

const NOTIFICATION = gql`
  fragment Notification on Notification {
    _id
    uid
    viewed
    clicked
    createdDate
    viewedDate
    clickedDate
    imageUrl
    title
    subTitle
    type
    payload
  }
`;

export const NOTIFICATIONS = gql`
  query Notifications {
    notifications {
      ...Notification
    }
  }

  ${NOTIFICATION}
`;

export const READ_NOTIFICATIONS = gql`
  mutation ReadNotifications($ids: [ObjectId!]!) {
    readNotifications(ids: $ids) {
      ...Notification
    }
  }

  ${NOTIFICATION}
`;

export const CLICKED_NOTIFICATION = gql`
  mutation ClickedNotification($id: ObjectId!) {
    clickedNotification(id: $id) {
      ...Notification
    }
  }

  ${NOTIFICATION}
`;
