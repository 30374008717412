import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 7690 2760"
    preserveAspectRatio="xMidYMid meet"
    style={{ height: 42 }}
  >
    <g id="layer101" fill="#ee7268" stroke="none">
      {" "}
      <path d="M1135 2744 c-657 -115 -1135 -689 -1135 -1364 0 -676 482 -1254 1139 -1364 119 -20 328 -20 449 -1 247 40 439 131 688 328 l22 17 37 -94 c20 -52 43 -100 50 -106 8 -6 26 -10 42 -8 25 3 31 13 74 123 l46 120 114 46 c84 35 118 54 129 73 14 23 13 27 -5 45 -11 11 -67 38 -125 61 -57 23 -107 43 -110 45 -4 2 13 44 37 92 91 186 132 352 140 569 10 275 -47 516 -174 739 -208 365 -562 615 -965 680 -115 19 -344 18 -453 -1z m428 -78 c111 -18 243 -57 225 -66 -7 -4 -32 -10 -55 -14 -124 -19 -330 -107 -451 -193 -89 -63 -220 -195 -281 -283 -257 -367 -293 -851 -92 -1247 169 -336 498 -579 856 -633 44 -7 98 -15 120 -19 l40 -7 -85 -33 c-159 -62 -259 -83 -430 -88 -182 -6 -288 9 -449 63 -326 109 -586 336 -741 649 -359 723 5 1588 771 1830 170 53 394 69 572 41z m459 -168 c60 -37 149 -100 145 -104 -1 -1 -42 -12 -91 -24 -187 -47 -337 -130 -466 -261 -127 -127 -203 -258 -256 -436 -38 -127 -45 -347 -16 -483 85 -397 403 -710 785 -772 39 -6 75 -14 79 -17 3 -3 -20 -27 -52 -52 -51 -40 -68 -48 -120 -54 -116 -13 -316 17 -455 70 -282 106 -524 347 -634 630 -63 163 -76 235 -75 425 1 144 4 178 27 262 55 207 152 376 301 523 50 50 120 110 156 133 143 94 319 161 475 181 124 15 140 13 197 -21z m259 -205 c81 -88 81 -71 -6 -165 -159 -170 -236 -311 -287 -518 -20 -82 -17 -356 5 -444 40 -159 114 -303 220 -427 l65 -76 -96 -38 c-125 -48 -142 -59 -142 -91 0 -31 -9 -29 -137 32 -283 136 -479 418 -512 738 -44 431 218 840 624 975 50 17 119 34 155 39 36 5 67 10 70 11 3 0 22 -16 41 -36z m164 -208 c209 -327 262 -734 143 -1104 -26 -83 -75 -201 -83 -201 -1 0 -13 27 -25 61 -23 61 -45 81 -82 71 -15 -4 -78 -133 -78 -161 0 -24 -20 -7 -79 67 -71 89 -141 224 -172 332 -26 93 -36 293 -20 399 27 171 107 338 227 472 34 39 121 119 128 119 3 0 21 -25 41 -55z m9 -1403 c31 -80 39 -88 134 -123 34 -13 62 -26 62 -29 0 -3 -35 -19 -78 -35 -83 -33 -85 -35 -135 -154 l-23 -55 -32 86 -33 87 -77 31 c-42 18 -80 35 -84 39 -4 3 30 21 76 39 l83 33 33 85 c18 47 36 81 40 77 3 -4 19 -41 34 -81z"></path>{" "}
      <path d="M5950 2420 l0 -190 85 0 c109 0 149 15 165 64 13 40 6 89 -16 106 -12 11 -10 17 12 43 23 27 25 37 20 77 -4 31 -14 52 -31 68 -24 20 -37 22 -131 22 l-104 0 0 -190z m190 132 c27 -22 30 -67 6 -90 -10 -10 -39 -18 -81 -20 l-65 -4 0 66 0 66 59 0 c42 0 65 -5 81 -18z m-12 -168 c15 -10 22 -25 22 -49 0 -43 -20 -55 -94 -55 l-56 0 0 60 0 60 53 0 c31 0 61 -6 75 -16z"></path>{" "}
      <path d="M6450 2420 l0 -190 120 0 120 0 0 25 c0 25 0 25 -95 25 l-95 0 0 60 0 60 80 0 c73 0 80 2 80 20 0 18 -7 20 -80 20 l-80 0 0 65 0 65 95 0 c88 0 95 1 95 20 0 19 -7 20 -120 20 l-120 0 0 -190z"></path>{" "}
      <path d="M7010 2445 l0 -165 -60 0 c-57 0 -60 -1 -60 -25 l0 -25 140 0 140 0 0 25 c0 24 -3 25 -55 25 l-55 0 0 165 0 165 -25 0 -25 0 0 -165z"></path>{" "}
      <path d="M7330 2603 c0 -3 18 -70 39 -147 22 -78 44 -160 51 -183 l11 -43 49 0 49 0 15 53 c55 194 86 308 86 317 0 5 -11 10 -25 10 -21 0 -26 -7 -36 -45 l-11 -45 -77 0 -77 0 -12 45 c-10 38 -16 45 -37 45 -14 0 -25 -3 -25 -7z m210 -139 c0 -4 -10 -43 -22 -88 -21 -80 -32 -105 -43 -94 -5 5 -54 167 -55 181 0 4 27 7 60 7 33 0 60 -3 60 -6z"></path>{" "}
      <path d="M4841 1935 c-151 -34 -218 -131 -243 -347 -13 -119 1 -301 31 -391 50 -154 149 -217 341 -217 178 0 281 64 329 202 59 171 53 460 -12 600 -50 109 -133 155 -291 163 -59 3 -113 0 -155 -10z m257 -117 c92 -43 127 -155 119 -383 -9 -267 -68 -350 -252 -350 -130 0 -193 44 -233 162 -22 69 -22 367 0 436 33 99 80 141 174 156 66 11 140 3 192 -21z"></path>{" "}
      <path d="M6050 1935 c-58 -7 -111 -14 -119 -14 -10 -1 -12 -12 -8 -46 2 -26 7 -48 9 -50 3 -3 40 0 84 5 123 16 254 15 295 -3 113 -47 132 -229 30 -282 -16 -8 -81 -28 -143 -44 -63 -16 -135 -39 -161 -51 -93 -44 -133 -120 -125 -240 8 -107 54 -173 148 -211 40 -16 285 -20 383 -6 l57 8 0 37 c0 62 -5 64 -106 52 -196 -22 -285 -10 -334 47 -26 29 -30 42 -30 89 0 99 26 120 209 169 149 39 198 61 234 104 39 47 50 94 45 186 -6 91 -27 142 -79 193 -63 61 -201 82 -389 57z"></path>{" "}
      <path d="M7220 1935 c-58 -7 -111 -14 -119 -14 -10 -1 -12 -12 -8 -46 2 -26 7 -48 9 -50 3 -3 40 0 84 5 123 16 254 15 295 -3 113 -47 132 -229 30 -282 -16 -8 -81 -28 -143 -44 -63 -16 -135 -39 -161 -51 -93 -44 -133 -120 -125 -240 8 -107 54 -173 148 -211 40 -16 285 -20 383 -6 l57 8 0 37 c0 62 -5 64 -106 52 -196 -22 -285 -10 -334 47 -26 29 -30 42 -30 89 0 99 26 120 209 169 149 39 198 61 234 104 39 47 50 94 45 186 -6 91 -27 142 -79 193 -63 61 -201 82 -389 57z"></path>{" "}
      <path d="M3370 1460 l0 -472 228 4 c258 4 287 11 346 80 26 30 37 55 45 104 19 107 -2 188 -61 238 l-40 35 30 15 c76 39 106 110 100 235 -3 76 -8 93 -33 131 -19 28 -48 53 -80 69 -48 25 -54 26 -292 29 l-243 3 0 -471z m459 352 c51 -25 72 -60 77 -128 7 -78 -15 -121 -76 -151 -40 -21 -58 -23 -197 -23 l-153 0 0 160 0 160 158 0 c130 0 163 -3 191 -18z m-22 -426 c51 -26 68 -60 68 -137 0 -53 -5 -74 -21 -96 -37 -50 -66 -58 -226 -61 l-148 -4 0 162 0 163 148 -5 c111 -4 155 -9 179 -22z"></path>{" "}
    </g>{" "}
    <g id="layer102" fill="#ee7268" stroke="none">
      {" "}
      <path d="M5950 2420 l0 -190 85 0 c109 0 149 15 165 64 13 40 6 89 -16 106 -12 11 -10 17 12 43 23 27 25 37 20 77 -4 31 -14 52 -31 68 -24 20 -37 22 -131 22 l-104 0 0 -190z m190 132 c27 -22 30 -67 6 -90 -10 -10 -39 -18 -81 -20 l-65 -4 0 66 0 66 59 0 c42 0 65 -5 81 -18z m-12 -168 c15 -10 22 -25 22 -49 0 -43 -20 -55 -94 -55 l-56 0 0 60 0 60 53 0 c31 0 61 -6 75 -16z"></path>{" "}
      <path d="M6450 2420 l0 -190 120 0 120 0 0 25 c0 25 0 25 -95 25 l-95 0 0 60 0 60 80 0 c73 0 80 2 80 20 0 18 -7 20 -80 20 l-80 0 0 65 0 65 95 0 c88 0 95 1 95 20 0 19 -7 20 -120 20 l-120 0 0 -190z"></path>{" "}
      <path d="M7010 2445 l0 -165 -60 0 c-57 0 -60 -1 -60 -25 l0 -25 140 0 140 0 0 25 c0 24 -3 25 -55 25 l-55 0 0 165 0 165 -25 0 -25 0 0 -165z"></path>{" "}
      <path d="M7330 2603 c0 -3 18 -70 39 -147 22 -78 44 -160 51 -183 l11 -43 49 0 49 0 15 53 c55 194 86 308 86 317 0 5 -11 10 -25 10 -21 0 -26 -7 -36 -45 l-11 -45 -77 0 -77 0 -12 45 c-10 38 -16 45 -37 45 -14 0 -25 -3 -25 -7z m210 -139 c0 -4 -10 -43 -22 -88 -21 -80 -32 -105 -43 -94 -5 5 -54 167 -55 181 0 4 27 7 60 7 33 0 60 -3 60 -6z"></path>{" "}
    </g>{" "}
  </svg>
);
