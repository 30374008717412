import React, { useState, FC, useEffect } from "react";
import { Footer, FooterLinks, SuccessContent } from "../../components/Layout";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Hidden from "@material-ui/core/Hidden";
import { LoadingButton } from "../../components/Geodrops/MenuCard";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { parseError, emailRegEx, localStore } from "../../utils";
import { SlideInUp } from "../../components/Animate";
import {
  InterfaceGraphic,
  StandOutGraphic,
  StatsGraphic
} from "../../components/Graphics";
import mapBgMobile from "../../components/Graphics/mapbg_3x.png";
import mapBgDesktop from "../../components/Graphics/mapbg_desktop.png";
import { useUser } from "../../context";
import { useAlertContext } from "../../context/alerts";
import { awsError } from "../../components/Error";

const completeStore = localStore<{ time: number; email: string }>(
  "early-access-complete"
);
const publicMessage =
  "Once public access is available we will send a message to the provided email.";

export default () => {
  const user = useUser();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [{}, { setError }] = useAlertContext() as any;
  const [completed, setCompleted] = useState(
    completeStore.get() || { email: "", time: "" }
  );
  const [bossAccessRequest, { loading, error }] = useMutation(
    BOSS_ACCESS_REQUEST
  );
  const onSubmit = async (ev: any) => {
    ev.preventDefault();
    if (!email) {
      setMessage("Email is required");
      return;
    }
    if (!emailRegEx.test(email)) {
      setMessage("Invalid email");
      return;
    }
    setMessage("");
    await bossAccessRequest({
      variables: { email }
    });
    addConversionPixel();
    const c = { email, time: Date.now() };
    setCompleted(c);
    completeStore.set(c);
  };
  useEffect(() => {
    if (user) setEmail(user.email || "");
  }, [user]);

  useEffect(() => {
    // setError({ message: awsError({ error }) })
  }, [])

  return (
    <>
      <Hidden mdUp>
        <img
          src={mapBgMobile}
          className="d-md-none"
          alt="mobile-map"
          style={{
            objectFit: "cover",
            objectPosition: "center",
            position: "fixed",
            top: 0,
            right: 0,
            width: "100%",
            height: "100%",
            filter: "grayscale(1)"
          }}
        />
      </Hidden>
      <div className="jumbotron bg-transparent rounded-0 position-relative">
        <div className="position-relative">
          <h2 className="text-center mb-0">COIN Boss</h2>
          <h4 className="text-center mb-0">Reach A Highly Engaged Audience!</h4>
        </div>
      </div>
      <div className="jumbotron bg-transparent rounded-0 position-relative">
        <Hidden smDown>
          <div
            // src={mapBgDesktop}
            className="d-none d-md-block"
            // alt="mobile-map"
            style={{
              objectFit: "cover",
              objectPosition: "center",
              position: "absolute",
              backgroundImage: `url(${mapBgDesktop})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              top: 0,
              right: 0,
              width: "100%",
              height: "100%",
              filter: "grayscale(1) brightness(0.9)",
              backgroundAttachment: "fixed"
            }}
          />
        </Hidden>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4 text-center mb-4 mb-md-0">
              <Hidden mdUp>
                <StandOutGraphic className="h-md-250px" />
                <h6 className="my-4">Grow your social media following!</h6>
              </Hidden>
              <Hidden smDown>
                <Card>
                  <CardContent>
                    <StandOutGraphic className="h-md-250px" />
                    <h6 className="my-4">Grow your social media following!</h6>
                  </CardContent>
                </Card>
              </Hidden>
            </div>
            <div className="col-12 col-md-4 text-center mb-4 mb-md-0">
              <Hidden mdUp>
                <InterfaceGraphic className="h-md-250px" />
                <h6 className="my-4">Drive engaged users to your business!</h6>
              </Hidden>
              <Hidden smDown>
                <Card>
                  <CardContent>
                    <InterfaceGraphic className="h-md-250px" />
                    <h6 className="my-4">
                      Drive engaged users to your business!
                    </h6>
                  </CardContent>
                </Card>
              </Hidden>
            </div>
            <div className="col-12 col-md-4 text-center mb-4 mb-md-0">
              <Hidden mdUp>
                <StatsGraphic className="h-md-250px" />
                <h6 className="my-4">Analyze and optimize your content!</h6>
              </Hidden>
              <Hidden smDown>
                <Card>
                  <CardContent>
                    <StatsGraphic className="h-md-250px" />
                    <h6 className="my-4">Analyze and optimize your content!</h6>
                  </CardContent>
                </Card>
              </Hidden>
            </div>
          </div>
        </div>
      </div>
      <div className="jumbotron bg-transparent rounded-0 position-relative">
        <h4 className="text-center">Sign Up for the Public Beta</h4>
        <div className="row">
          <div className="offset-md-3 offset-lg-4 col col-md-6 col-lg-4">
            <Card>
              <CardContent>
                {completed && completed.email ? (
                  <SlideInUp delay={0} config={{}}>
                    <SuccessContent>
                      <h4 className="mb-4">Success</h4>
                      {/* <ExplorerMotionGraphic /> */}
                    </SuccessContent>
                  </SlideInUp>
                ) : (
                  <form onSubmit={onSubmit}>
                    <TextField
                      name="email"
                      label="Email"
                      className="mb-2"
                      placeholder="example@gmail.com"
                      value={email}
                      onChange={ev => setEmail(ev.target.value)}
                      fullWidth
                    />
                    <LoadingButton
                      type="submit"
                      fullWidth
                      loading={loading}
                      disabled={false}
                      variant="contained"
                      color="secondary"
                      className="text-white"
                    >
                      Sign Up
                    </LoadingButton>
                    <p className="text-center text-danger mb-0">
                      {parseError(message || error)}
                    </p>
                  </form>
                )}
              </CardContent>
              <p className="text-center">{publicMessage}</p>
            </Card>
          </div>
        </div>
        <div className="d-none d-md-block" style={{ paddingBottom: "15vh" }} />
      </div>
      <Footer />
      <div className="position-relative bg-dark">
        <FooterLinks />
      </div>
    </>
  );
};

const BOSS_ACCESS_FRAGMENT = gql`
  fragment EarlyAccess on EarlyAccess {
    uid
    email
    granted
    rejected
    createdTime
    updatedTime
    grantedTime
    rejectedTime
  }
`;

const BOSS_ACCESS_LIST = gql`
  query BossEarlyAccess {
    bossEarlyAccess {
      ...EarlyAccess
    }
  }

  ${BOSS_ACCESS_FRAGMENT}
`;

const BOSS_ACCESS_REQUEST = gql`
  mutation BossAccessRequest($email: String!, $subscription: JSON) {
    bossEarlyAccess(email: $email, subscription: $subscription) {
      ...EarlyAccess
    }
  }

  ${BOSS_ACCESS_FRAGMENT}
`;

function addConversionPixel() {
  (window as any).coinTracker = (window as any).coinTracker || [];
  (window as any).coinTracker.push({ event: "convert" });
}
