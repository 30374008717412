import React, { useState, FC, useEffect } from "react";
import {
  InterfaceGraphic,
  StandOutGraphic,
  StatsGraphic
} from "../../components/Graphics";
import Dialog from "@material-ui/core/Dialog";
import Card from "@material-ui/core/Card";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import CardContent from "@material-ui/core/CardContent";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import { trackInfo } from "../../utils/track";

export default () => {
  const [open, showWelcomeMessage] = useState(false);
  useEffect(() => {
    const welcomeModalShown = localStorage.getItem("welcome-modal-shown");
    if (!welcomeModalShown) {
      showWelcomeMessage(true);
      trackInfo("Show welcome modal");
    }
  }, []);

  const handleClose = () => {
    showWelcomeMessage(false);
    localStorage.setItem("welcome-modal-shown", new Date().toISOString());
  };

  return (
    <Dialog open={open} maxWidth="lg" onClose={handleClose}>
      <DialogTitle>
        Welcome to the closed beta release of COIN Boss!
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          You can now reach a highly engaged audience with Sponsored Geodrops.
          Drive traffic to your websites, get followers for your socials, get
          installs for your apps, etc. The options are endless. Enjoy!
        </DialogContentText>
        <div className="row">
          <div className="col-12 col-md-4 text-center mb-4 mb-md-0">
            <Hidden mdUp>
              <StandOutGraphic className="h-md-250px" />
              <h6 className="my-4">Grow your social media following!</h6>
            </Hidden>
            <Hidden smDown>
              <Card>
                <CardContent>
                  <StandOutGraphic className="h-md-250px" />
                  <h6 className="my-4">Grow your social media following!</h6>
                </CardContent>
              </Card>
            </Hidden>
          </div>
          <div className="col-12 col-md-4 text-center mb-4 mb-md-0">
            <Hidden mdUp>
              <InterfaceGraphic className="h-md-250px" />
              <h6 className="my-4">Drive engaged users to your business!</h6>
            </Hidden>
            <Hidden smDown>
              <Card>
                <CardContent>
                  <InterfaceGraphic className="h-md-250px" />
                  <h6 className="my-4">
                    Drive engaged users to your business!
                  </h6>
                </CardContent>
              </Card>
            </Hidden>
          </div>
          <div className="col-12 col-md-4 text-center mb-4 mb-md-0">
            <Hidden mdUp>
              <StatsGraphic className="h-md-250px" />
              <h6 className="my-4">Analyze and optimize your content!</h6>
            </Hidden>
            <Hidden smDown>
              <Card>
                <CardContent>
                  <StatsGraphic className="h-md-250px" />
                  <h6 className="my-4">Analyze and optimize your content!</h6>
                </CardContent>
              </Card>
            </Hidden>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          Let's Begin
        </Button>
      </DialogActions>
    </Dialog>
  );
};
