import React, {
  useEffect,
  useState,
  useContext,
  createContext,
  useRef,
} from 'react'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

const defaultValue = {
  message: null,
  action: null,
}

const SnackbarContext = createContext([
  defaultValue,
  {
    setSnackbar: () => console.warn('Snackbar provider not initialized'),
    closeSnackbar: () => console.warn('Snackbar provider not initialized'),
  },
])

export const SnackbarProvider = ({ children }) => {
  const [value, setValue] = useState(defaultValue)
  const ref = useRef(defaultValue)
  const closeSnackbar = () => {
    ref.current = defaultValue
    setValue(defaultValue)
  }
  const setSnackbar = v => {
    setValue(v)
    ref.current = v
    return () => {
      if (ref.current === v) {
        setValue(defaultValue)
      }
    }
  }
  return (
    <SnackbarContext.Provider value={[value, { setSnackbar, closeSnackbar }]}>
      {children}
    </SnackbarContext.Provider>
  )
}

export const useSnackbar = () => {
  return useContext(SnackbarContext)
}

export default () => {
  const [snackbar, { setSnackbar, closeSnackbar }] = useSnackbar()
  return !!snackbar.message ? (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={true}
      message={snackbar.message}
      action={
        snackbar.action || [
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            className="p-2"
            onClick={closeSnackbar}
          >
            <CloseIcon />
          </IconButton>,
        ]
      }
    />
  ) : (
    <></>
  )
}
