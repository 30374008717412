import { useQuery } from "@apollo/react-hooks";
import ButtonBase from "@material-ui/core/ButtonBase";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CalendarIcon from "@material-ui/icons/DateRange";
import RefreshIcon from "@material-ui/icons/Refresh";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import { DatePicker } from "@material-ui/pickers";
import DateRange from "../Form/DateRange";
import cx from "classnames";
import moment from "moment";
import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  createContext,
  useContext,
  FC,
} from "react";
import { useBooleanControls } from "../../utils/hooks";
import { getNumber, numberFormat } from "../../utils/number";
import Code from "../Layout/Code";
import { DROP_SUMMARY, DROP_SUMMARY_ADMIN } from "./gql";
import { useAuth } from "../../context";
import get from "lodash/get";

const copy = require("copy-to-clipboard");

const GeodropSummaryRangeContext = createContext({
  gte: "",
  lte: "",
  rangeValue: "0,0",
  setGte: (value: string) =>
    console.warn("GeodropSummaryRangeProvider not supplied"),
  setLte: (value: string) =>
    console.warn("GeodropSummaryRangeProvider not supplied"),
  setRangeValue: (value: string) =>
    console.warn("GeodropSummaryRangeProvider not supplied"),
  setDateValues: (value: { lte: string; gte: string }) =>
    console.warn("GeodropSummaryRangeProvider not supplied") as any,
});

export const useGeodropSummaryRangeContext = () =>
  useContext(GeodropSummaryRangeContext);

export const GeodropSummaryRangeProvider: FC<{ initial?: string }> = ({
  children,
  initial = "0,0",
}) => {
  const [rangeValue, setRangeValue] = useState(initial);
  const [{ gte, lte }, setDateValues] = useState({ lte: "", gte: "" });
  const setGte = (value: string) =>
    setDateValues(({ lte }) => ({ gte: value, lte }));
  const setLte = (value: string) =>
    setDateValues(({ gte }) => ({ lte: value, gte }));
  useEffect(() => {
    if (rangeValue === "custom") return;
    const [gte, lte] = rangeValue.split(",").map((v) => Number(v));
    setDateValues({
      gte: moment.utc().subtract(gte, "days").startOf("day").toString(),
      lte: moment.utc().subtract(lte, "days").endOf("day").toString(),
    });
  }, [rangeValue]);
  return (
    <GeodropSummaryRangeContext.Provider
      value={{
        rangeValue,
        setRangeValue,
        gte,
        lte,
        setGte,
        setLte,
        setDateValues,
      }}
    >
      {children}
    </GeodropSummaryRangeContext.Provider>
  );
};

export const GeodropSummaryControls: FC<{
  title: string;
  onRefresh?: () => any;
  className?: string;
}> = ({ title = "", onRefresh, className = "" }) => {
  const { rangeValue, setRangeValue } = useGeodropSummaryRangeContext();
  return (
    <div
      className={cx(
        "d-flex align-items-center text-white px-2 px-lg-0",
        className
      )}
    >
      <h4 className="flex-grow-1 mb-0">{title}</h4>
      {onRefresh && (
        <IconButton onClick={onRefresh} size="small">
          <RefreshIcon />
        </IconButton>
      )}
      <TextField
        select
        value={rangeValue}
        onChange={(ev) => setRangeValue(ev.target.value)}
        className="ml-2"
        InputProps={{ startAdornment: <CalendarIcon className="mr-2" /> }}
      >
        <MenuItem value={"0,0"}>Today</MenuItem>
        <MenuItem value={"1,1"}>Yesterday</MenuItem>
        <MenuItem value={"6,0"}>7 Days</MenuItem>
        <MenuItem value={"30,0"}>1 Month</MenuItem>
        <MenuItem value={"60,0"}>2 Months</MenuItem>
        <MenuItem value={"90,0"}>3 Months</MenuItem>
      </TextField>
    </div>
  );
};

export const GeodropSummaryRangeControls: FC<{
  title?: string;
  onRefresh?: () => any;
  className?: string;
}> = ({ title = "", className = "", onRefresh }) => {
  const {
    rangeValue,
    gte,
    lte,
    setGte,
    setLte,
    setRangeValue,
    setDateValues,
  } = useGeodropSummaryRangeContext();
  return (
    <div
      className={cx(
        "d-flex align-items-center text-white px-2 px-lg-0",
        className
      )}
    >
      <h4 className="flex-grow-1 mb-0">{title}</h4>
      {onRefresh && (
        <IconButton onClick={onRefresh} size="small">
          <RefreshIcon />
        </IconButton>
      )}
      {rangeValue === "custom" && (
        <>
          <DateRange
            variant="inline"
            className="ml-2"
            InputProps={{ startAdornment: <CalendarIcon className="mr-2" /> }}
            value={gte}
            end={lte}
            onChange={(value: any) => {
              if (moment(value).isAfter(lte)) {
                setLte(
                  value
                    ? moment(value)
                        .endOf("day")
                        // .utc(true)
                        .toString()
                    : ""
                );
              } else if (moment(value).isBefore(gte)) {
                setGte(
                  value
                    ? moment(value)
                        .startOf("day")
                        // .utc(true)
                        .toString()
                    : ""
                );
              } else {
                setDateValues({
                  lte: moment(value)
                    .endOf("day")
                    // .utc(true)
                    .toString(),
                  gte: moment(value)
                    .startOf("day")
                    // .utc(true)
                    .toString(),
                });
              }
            }}
          />
        </>
      )}
      <TextField
        select
        value={rangeValue}
        onChange={(ev) => setRangeValue(ev.target.value)}
        className="ml-2"
        InputProps={{ startAdornment: <CalendarIcon className="mr-2" /> }}
      >
        <MenuItem value={"0,0"}>Today</MenuItem>
        <MenuItem value={"1,1"}>Yesterday</MenuItem>
        <MenuItem value={"6,0"}>7 Days</MenuItem>
        <MenuItem value={"30,0"}>1 Month</MenuItem>
        <MenuItem value={"60,0"}>2 Months</MenuItem>
        <MenuItem value={"90,0"}>3 Months</MenuItem>
        <MenuItem value={"custom"}>Custom</MenuItem>
      </TextField>
    </div>
  );
};

export const GeodropSummary = ({
  advancedId,
  active,
  setActive,
  skip,
}: {
  active?: string;
  setActive?: (v: string) => any;
  advancedId?: string;
  skip?: boolean;
}) => {
  const { initialized, userInfo } = useAuth();
  const { gte, lte } = useGeodropSummaryRangeContext();
  const variables = { gte, lte, advancedId };
  const { data, loading } = useQuery(DROP_SUMMARY, {
    variables,
    skip: !userInfo || !gte || !lte || skip,
    pollInterval: 60000,
  });
  return (
    <StatsSummary
      loading={loading}
      active={active}
      setActive={setActive}
      count={get(data, "totalDrops.total")}
      claimed={get(data, "claimed.total")}
      collected={get(data, "engagements.total")}
      linkClicked={get(data, "linkClicks.total")}
      converted={get(data, "conversions.total")}
      implementations={get(data, "implementations")}
    />
  );
};

export const GeodropSummaryAdmin = ({
  advancedId,
  active,
  setActive,
  skip,
}: {
  active?: string;
  setActive?: (v: string) => any;
  advancedId?: string;
  skip?: boolean;
}) => {
  const { initialized, userInfo } = useAuth();
  const { gte, lte } = useGeodropSummaryRangeContext();
  const variables = { gte, lte, advancedId };
  const { data, loading } = useQuery(DROP_SUMMARY_ADMIN, {
    variables,
    skip: !userInfo || !gte || !lte || skip,
  });
  const stats = get(data, "dropSummaryAdmin");
  const implementations = get(data, "implementations");
  return (
    <StatsSummary
      loading={loading}
      active={active}
      setActive={setActive}
      implementations={implementations}
      {...stats}
    />
  );
};

export function StatsSummary({
  loading,
  active,
  setActive,
  implementations,
  ...props
}: {
  loading?: boolean;
  active?: string;
  setActive?: (active: string) => any;
  count?: number;
  claimed?: number;
  collected?: number;
  linkClicked?: number;
  converted?: number;
  implementations?: { url: string; implemented: boolean }[];
}) {
  return (
    <div className="row px-2">
      <div className="col mb-4 px-2 d-flex align-items-center">
        <SummaryCard
          title={
            <span>
              <span className="d-none d-md-inline">Total</span> Drops
            </span>
          }
          type="count"
          active={active}
          loading={loading}
          setActive={setActive}
          icon={<i className="fa fa-layer-group mr-2" />}
          info={
            <div className="p-2">
              The number drops within the specified time period.
            </div>
          }
        >
          <SummaryValue loading={loading} record={props} source={"count"} />
        </SummaryCard>
      </div>
      <div className="d-flex align-items-center mb-4 d-flex align-items-center">
        <Paper className="p-1">
          {loading ? "--" : conversionRate(props.collected, props.count)}%
        </Paper>
      </div>
      {/* <div className="col mb-4 px-2 d-flex align-items-center">
        <SummaryCard
          title="Claimed"
          type="claimed"
          active={active}
          loading={loading}
          setActive={setActive}
          icon={<i className="fa fa-comments mr-2" />}
          info={
            <div className="p-2">
              The number times your content was claimed.
            </div>
          }
        >
          <SummaryValue loading={loading} record={props} source={"claimed"} />
        </SummaryCard>
      </div> */}
      <div className="col mb-4 px-2 d-flex align-items-center">
        <SummaryCard
          title="Collections"
          type="collected"
          active={active}
          loading={loading}
          setActive={setActive}
          icon={<i className="fa fa-comments mr-2" />}
          info={
            <div className="p-2">
              The number times your content was displayed.
            </div>
          }
        >
          <SummaryValue loading={loading} record={props} source={"collected"} />
        </SummaryCard>
      </div>
      <div className="d-flex align-items-center mb-4">
        <Paper className="p-1">
          {loading ? "--" : conversionRate(props.linkClicked, props.collected)}%
        </Paper>
      </div>
      {/* <div className="col mb-4 px-2 d-flex align-items-center">
        <SummaryCard
          title={
            <span>
              <span className="d-none d-md-inline">Unique</span> Users
            </span>
          }
          type="people"
          active={active}
          loading={loading}
          setActive={setActive}
          icon={<i className="fa fa-user-circle mr-2" />}
          info={
            <div className="p-2">
              The unique number people who received your content.
            </div>
          }
        >
          <SummaryValue
            loading={loading}
            record={data}
            source={"peopleReached"}
          />
        </SummaryCard>
      </div> */}
      <div className="col mb-4 px-2 d-flex align-items-center">
        <SummaryCard
          title={
            <span>
              <span className="d-none d-md-inline">Link</span> Clicks
            </span>
          }
          footer={
            <div className="bg-dark text-center">
              {loading ? "--" : conversionRate(props.linkClicked, props.count)}%
              of total
            </div>
          }
          type="linkClicked"
          active={active}
          loading={loading}
          setActive={setActive}
          icon={<i className="fa fa-mouse-pointer mr-2" />}
          info={<ClickEventDocumentation />}
        >
          <SummaryValue
            loading={loading}
            record={props}
            source={"linkClicked"}
          />
        </SummaryCard>
      </div>
      <div className="d-flex align-items-center mb-4">
        <Paper className="p-1">
          {loading ? "--" : conversionRate(props.converted, props.linkClicked)}%
        </Paper>
      </div>
      <div className="col mb-4 px-2 d-flex align-items-center">
        <SummaryCard
          title="Conversions"
          footer={
            <div className="bg-dark text-center">
              {loading ? "--" : conversionRate(props.converted, props.count)}%
              of total
            </div>
          }
          type="converted"
          active={active}
          loading={loading}
          setActive={setActive}
          icon={<i className="fa fa-clipboard-check mr-2" />}
          info={
            <ConversionEventDocumentation implementations={implementations} />
          }
        >
          <SummaryValue loading={loading} record={props} source={"converted"} />
        </SummaryCard>
      </div>
    </div>
  );
}

export function SummaryValue({ record, source, loading }: any) {
  return (
    <Typography variant="h5" component="h2" color="inherit">
      {loading ? "--" : getNumber(record, source, 0) || "--"}
    </Typography>
  );
}

export function SummaryCard({
  loading,
  title,
  icon,
  info,
  children,
  type,
  active,
  setActive,
  footer,
}: any) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => setAnchorEl(null);
  return (
    <>
      <ButtonBase
        className="w-100"
        onClick={(ev: any) => {
          ev.stopPropagation();
          if (info) {
            setActive ? setActive(type) : setAnchorEl(ev.target);
          }
        }}
      >
        <Card elevation={4} className="w-100 text-left">
          <LinearProgress
            value={type === active ? 100 : 0}
            variant={loading ? "indeterminate" : "determinate"}
          />
          <CardContent className="p-2 p-md-3">
            <Typography className="d-flex align-items-center" gutterBottom>
              {icon}
              <small
                className="flex-grow-1 pr-1"
                style={{ whiteSpace: "nowrap" }}
              >
                {title}
              </small>{" "}
              {info ? (
                <small>
                  <i
                    className="fa fa-info-circle cursor-pointer"
                    onClick={(ev: any) => {
                      ev.stopPropagation();
                      setAnchorEl(ev.target);
                    }}
                  />
                </small>
              ) : null}
            </Typography>
            {children}
          </CardContent>
          {footer}
        </Card>
      </ButtonBase>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        {info}
      </Menu>
    </>
  );
}

const useCopyControls = (): [boolean, (v: string) => void] => {
  const [copied, copiedControls] = useBooleanControls(false);
  useEffect(() => {
    if (copied) {
      const timerId = setTimeout(() => {
        copiedControls.setFalse();
      }, 5000);
      return () => {
        clearTimeout(timerId);
      };
    }
  }, [copied]);
  return [
    copied,
    (content) => {
      copy(content);
      copiedControls.setTrue();
    },
  ];
};

const ClickEventDocumentation = React.forwardRef(() => {
  const [copied, copyContent] = useCopyControls();
  return (
    <>
      <div className="p-2">
        <p className="mb-0">The number times your links were clicked.</p>
        {/* <p className="mb-0">
          <strong>Usage</strong>
        </p> */}
      </div>
      {/* <MenuItem
        className="flex-column align-items-start"
        onClick={ev => {
          ev.stopPropagation();
          copyContent(clickEvent);
        }}
      >
        <Code className="text-white my-2" content={clickEvent} />
        <div className={cx("profile-overlay", { "opacity-1": copied })}>
          COPIED
        </div>
      </MenuItem> */}
    </>
  );
});

const ConversionEventDocumentation = React.forwardRef(
  ({ implementations }: any) => {
    const [copied, copyContent] = useCopyControls();
    return (
      <>
        <div className="p-2">
          <p className="mb-0">The number of times your drops converted.</p>
          <p className="mb-0">
            <strong>
              Place the following tracking code in the head of your conversion
              page:
            </strong>
          </p>
        </div>
        <MenuItem
          className="flex-column align-items-start"
          onClick={(ev) => {
            ev.stopPropagation();
            copyContent(conversionEvent);
          }}
        >
          <Code className="text-white my-2" content={conversionEvent} />
          <div className={cx("profile-overlay", { "opacity-1": copied })}>
            COPIED
          </div>
        </MenuItem>
        <div className="list-group m-2">
          {(implementations || []).map(({ url, implemented }: any) => (
            <div
              className="list-group-item bg-transparent d-flex align-items-center"
              key={url}
            >
              <span className="flex-grow-1">{url}</span>
              <span className="d-none d-md-block ml-1">
                {implemented ? "implemented successfully" : "not implemented"}
              </span>
              <i
                className={cx("fa fa-check ml-1", {
                  "text-success": implemented,
                  "text-muted": !implemented,
                })}
              />
            </div>
          ))}
        </div>
      </>
    );
  }
);

const conversionRate = (num: number | undefined, den: number | undefined) => {
  if (!num || !den) return "--";
  return numberFormat((num / den) * 100);
};

const clickEvent = `<script src="https://t.coinapp.co/events.js"></script>
<script>
  /* Run this code when a click event occurs */
  window.coinTracker = window.coinTracker || []
  window.coinTracker.push({ event: "click" })
</script>
`;

const conversionEvent = `<script src="https://t.coinapp.co/events.js"></script>
<script>
  /* Run this code when a conversion event occurs */
  window.coinTracker = window.coinTracker || []
  window.coinTracker.push({ event: "convert" })
</script>
`;
