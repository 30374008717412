import { useMutation, useQuery } from "@apollo/react-hooks";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ButtonBase from "@material-ui/core/ButtonBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import PlusIcon from "@material-ui/icons/Add";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import MoreIcon from "@material-ui/icons/MoreVert";
import SendIcon from "@material-ui/icons/Send";
import { InfoDisplay } from "@xyo-network/coin-forms/build/lib/components/Alert/Display";
import get from "lodash/get";
import over from "lodash/over";
import times from "lodash/times";
import moment from "moment";
import { useAlertContext, useUserInfo } from "../../context";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router";
import { useAuth, useUser } from "../../context/auth";
import { enumerateDaysBetween, toYYYYMMDD } from "../../utils/date";
import { numberFormat } from "../../utils/number";
import { trackInfo, trackError } from "../../utils/track";
import { SparkChart, toSeries } from "../Charts";
import {
  TablePaginationActions,
  useAdvancedDropBottomNav,
  useScrollContext,
} from "../Layout";
import { UserBalance } from "../User";
import { AdvancedDropDebug } from "./AdvancedDebug";
import { AdvancedListDetails } from "./AdvancedListDetails";
import { AdvancedStats } from "./AdvancedStats";
import {
  ADVANCED_DROPS,
  CREATE_ADVANCED_DROP,
  DEPOSIT_ADVANCED_DROP,
  REMOVE_ADVANCED_DROP,
  UPDATE_ADVANCED_DROP,
  WITHDRAW_ADVANCED_DROP,
} from "./gql";
import {
  ActionDialog,
  ConfirmDialog,
  ContentDialog,
  LoadingButton,
  MenuCard,
  Toc,
  useContentDialogStyles,
} from "./MenuCard";
import DropPreview from "./Preview";
import {
  GeodropSummary,
  GeodropSummaryRangeControls,
  GeodropSummaryRangeProvider,
} from "./Summary";
import { pickAdvancedDropUpdate } from "./TemplateForm";
import { UpdateDropStepForm } from "./TemplateStepForm";

export const useAdvancedDropList = () => {
  const { initialized } = useAuth();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [variables, enumerated] = useMemo(() => {
    const end = moment().startOf("day");
    const start = moment().subtract(6, "days").startOf("day");
    const GTE = toYYYYMMDD(start.toDate());
    const LTE = toYYYYMMDD(end.toDate());
    const enumerated = enumerateDaysBetween(start, end).map(toYYYYMMDD);
    return [
      {
        page,
        perPage,
        field: "updatedTime",
        // yyyymmdd: { GTE, LTE },
        order: -1,
      },
      enumerated,
    ];
  }, [perPage, page]);
  const refetchQueries = [{ query: ADVANCED_DROPS, variables }];
  const { data, fetchMore, loading } = useQuery(ADVANCED_DROPS, {
    variables,
    skip: !initialized,
  });
  const list = get(data, "advancedDrops.data") || [];
  return {
    page,
    perPage,
    data,
    fetchMore,
    loading,
    refetchQueries,
    variables,
    enumerated,
    setPage,
    setPerPage,
    list,
  };
};

export const AdvancedDropList = ({
  page,
  setPage,
  perPage,
  setPerPage,
  data,
  fetchMore,
  loading,
  refetchQueries,
  variables,
  enumerated,
  list,
}: ReturnType<typeof useAdvancedDropList>) => {
  const { initialized } = useAuth();
  const user = useUserInfo();
  const classes = useContentDialogStyles();
  const scrollElement = useScrollContext();
  const [
    routerState,
    { goToList, goToDetails, goToUpdate, goToDebug },
  ] = useRouterState();
  const [removeOpen, setRemoveOpen] = useState<any>(null);
  const [duplicateOpen, setDuplicateOpen] = useState<any>(null);
  const [open, modalControls] = useAdvancedDropBottomNav();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (fn?: () => any) => {
    setAnchorEl(null);
    if (typeof fn === "function") fn();
  };

  const remoteRowCount = get(data, "advancedDrops.total");
  const changePage = (p: number) => {
    if (scrollElement) {
      scrollElement.scrollTop = 0;
    }
    setPage(p);
  };
  const handleChangePage = (event: any, newPage: number) => {
    changePage(newPage + 1);
  };
  const handleChangeRowsPerPage = (event: any) => {
    setPerPage(parseInt(event.target.value, 10));
    changePage(1);
  };
  const activeAdvancedDrop = useMemo(
    () => list.find((drop: any) => drop.id === routerState.id),
    [list, routerState.id]
  );
  const [removeAdvancedDrop, remove] = useMutation(REMOVE_ADVANCED_DROP, {
    refetchQueries,
    update: (cache, result) => {
      trackInfo("Removed Advanced Drop", result);
      return setRemoveOpen(null);
    },
    variables: { id: removeOpen && removeOpen.id },
  });
  const [duplicateAdvancedDrop, duplicate] = useMutation(CREATE_ADVANCED_DROP, {
    refetchQueries,
    update: (cache, result) => {
      trackInfo("Duplicated Advanced Drop", result);
      return setDuplicateOpen(null);
    },
    variables: pickAdvancedDropUpdate({
      ...duplicateOpen,
      title: `${(duplicateOpen && duplicateOpen.title) || ""} (Copy)`,
    }),
  });
  const menuControls = (advancedDrop: any) => (
    closeMenu: (ev: any) => any,
    hideView: boolean
  ) =>
    [
      hideView ? null : (
        <MenuItem
          key={"view"}
          onClick={over([closeMenu, () => goToDetails(advancedDrop.id)])}
        >
          <i className="fa fa-eye mr-2" /> View
        </MenuItem>
      ),
      <MakeInactive
        key={"inactivate"}
        advancedDrop={advancedDrop}
        refetchQueries={refetchQueries}
        update={over([
          closeMenu,
          () => trackInfo("Set inactive", advancedDrop),
        ])}
      />,
      <MakeActive
        key={"activate"}
        advancedDrop={advancedDrop}
        refetchQueries={refetchQueries}
        update={over([closeMenu, () => trackInfo("Set active", advancedDrop)])}
      />,
      <MenuItem
        key={"update"}
        disabled={!user?.bossAdmin}
        onClick={over([closeMenu, () => goToUpdate(advancedDrop.id)])}
      >
        <i className="fa fa-edit mr-2" /> Update
      </MenuItem>,
      process.env.NODE_ENV === "development" ? (
        <MenuItem
          key={"debug"}
          onClick={over([closeMenu, () => goToDebug(advancedDrop.id)])}
        >
          <i className="fa fa-bug mr-2" /> Debug
        </MenuItem>
      ) : null,
      <MenuItem
        key={"duplicate"}
        onClick={over([closeMenu, () => setDuplicateOpen(advancedDrop)])}
      >
        <i className="fa fa-copy mr-2" /> Duplicate
      </MenuItem>,
      <MenuItem
        key={"delete"}
        onClick={over([closeMenu, () => setRemoveOpen(advancedDrop)])}
      >
        <i className="fa fa-times mr-2" /> Delete
      </MenuItem>,
    ].filter((v) => v);
  return (
    <>
      <div className="d-flex align-items-center text-white px-2 px-lg-0 mb-3">
        <h4 className="flex-grow-1">Sponsored Geodrops</h4>
        <Button
          onClick={() => modalControls.setTrue()}
          className="ml-2"
          size="small"
        >
          <PlusIcon className="mr-2" /> Create
        </Button>
      </div>
      <div className="position-relative list-group">
        {!initialized || loading ? (
          times(perPage).map((_, i) => (
            <AdvancedDropCard
              enumerated={enumerated}
              menuControls={menuControls}
              loading={true}
              key={i}
            />
          ))
        ) : list.length ? (
          list.map((advancedDrop: any) => (
            <AdvancedDropCard
              enumerated={enumerated}
              key={advancedDrop.id}
              advancedDrop={advancedDrop}
              menuControls={menuControls}
              goToDetails={() => goToDetails(advancedDrop.id)}
            />
          ))
        ) : (
          <NoGeoDrops />
        )}
        {remoteRowCount ? (
          <TablePagination
            component="div"
            rowsPerPageOptions={[10]}
            count={remoteRowCount}
            rowsPerPage={variables.perPage}
            page={variables.page - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions as any}
          ></TablePagination>
        ) : null}
      </div>
      <GeodropSummaryRangeProvider initial="6,0">
        <ActionDialog
          action={null}
          classes={{ paper: classes.mainDrawer }}
          title={(activeAdvancedDrop && activeAdvancedDrop.title) || ""}
          onCancel={goToList}
          open={!!routerState.id}
          titleRight={
            <>
              <Box display="flex" alignItems="center">
                <GeodropSummaryRangeControls />
                <IconButton onClick={handleMenu} size="small" className="ml-2">
                  <MoreIcon />
                </IconButton>
              </Box>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={!!anchorEl}
                onClose={() => handleClose()}
              >
                {menuControls(activeAdvancedDrop)(handleClose, true)}
              </Menu>
            </>
          }
          cancelText="Close"
          maxWidth="lg"
          content={
            <AdvancedDropDetailView
              advancedId={activeAdvancedDrop && activeAdvancedDrop.id}
              advancedDrop={activeAdvancedDrop}
              loading={loading}
            />
          }
        />
      </GeodropSummaryRangeProvider>

      <ContentDialog
        actions={null}
        classes={{ paper: classes.subDrawer }}
        title="Debug Sponsored Geodrop"
        titleRight={<UserBalance />}
        open={!!routerState.id && !!routerState.debug}
        onCancel={() => goToDetails(activeAdvancedDrop.id)}
        maxWidth="lg"
      >
        <AdvancedDropDebug
          id="debug-advanced-geodrop"
          onCancel={() => goToDetails(activeAdvancedDrop.id)}
          advancedDrop={activeAdvancedDrop}
          loading={loading}
        />
      </ContentDialog>

      <ContentDialog
        actions={null}
        classes={{ paper: classes.subDrawer }}
        title="Update Sponsored Geodrop"
        titleRight={<UserBalance />}
        open={!!routerState.id && !!routerState.update}
        onCancel={() => goToDetails(activeAdvancedDrop.id)}
        maxWidth="lg"
      >
        <UpdateDropStepForm
          id="update-advanced-geodrop"
          onSuccess={() => goToDetails(activeAdvancedDrop.id)}
          onCancel={() => goToDetails(activeAdvancedDrop.id)}
          advancedDrop={activeAdvancedDrop}
        />
      </ContentDialog>

      <ConfirmDialog
        title="Confirm Copy"
        text="Are you sure you would like to create a copy?"
        open={!!duplicateOpen}
        loading={duplicate.loading}
        onCancel={() => setDuplicateOpen(null)}
        onConfirm={() => duplicateAdvancedDrop()}
      />

      <ConfirmDialog
        title="Confirm Delete"
        text={
          removeOpen && removeOpen.currentBalance
            ? `Are you sure you would like to delete? ${numberFormat(
                removeOpen.currentBalance
              )} COIN will be returned to your COIN Boss balance.`
            : "Are you sure you would like to delete?"
        }
        open={!!removeOpen}
        loading={remove.loading}
        onCancel={() => setRemoveOpen(null)}
        onConfirm={() => removeAdvancedDrop()}
      />
    </>
  );
};

function useRouterState(): [
  { id?: string; update?: boolean; debug?: boolean },
  IResourceRouterControls
] {
  const history = useHistory();
  const goToList = () => history.push(`/`);
  const goToDetails = (id: string) => history.push(`/${id}`);
  const goToDebug = (id: string) => history.push(`/${id}/debug`);
  const goToUpdate = (id: string) => history.push(`/${id}/update`);
  const state = useMemo(() => {
    const m = history.location.pathname.match(
      /\/([0-9a-fA-F]{24})\/?(update|debug)?/
    );
    if (!m) return {};
    if (m[1] && m[2]) return { id: m[1], [m[2]]: true };
    return { id: m[1] };
  }, [history.location.pathname]);
  return [state, { goToList, goToDetails, goToUpdate, goToDebug }];
}

export const AdvancedDropCard = ({
  loading,
  advancedDrop,
  goToDetails,
  enumerated,
  menuControls,
}: any) => {
  const user = useUser();
  const advancedDropDailyStats = get(advancedDrop, "advancedDropDailyStats");
  const title = (advancedDrop && advancedDrop.title) || "";
  const seriesData = toSeries(
    "linkClickedRate",
    advancedDropDailyStats,
    enumerated
  );
  return (
    <ButtonBase onClick={goToDetails} className="w-100 outline-0 mb-4">
      <MenuCard
        className="px-2 px-md-3 w-100"
        title={title}
        controls={menuControls(advancedDrop)}
      >
        <div className="d-flex">
          <div className="flex-grow-1 w-50">
            <AdvancedListDetails
              advancedDrop={advancedDrop}
              loading={loading}
            />
          </div>
          {seriesData.length ? (
            <div className="d-flex flex-column pl-4">
              <ListItem disableGutters>
                <Typography>Click-Through Rate (7d)</Typography>
              </ListItem>
              <div className="d-flex flex-column justify-content-center flex-grow-1">
                <SparkChart
                  height={150}
                  width={180}
                  data={seriesData}
                  library={{
                    tooltips: {
                      callbacks: {
                        label: (
                          { datasetIndex, yLabel }: any,
                          { datasets }: any
                        ) =>
                          `${get(datasets, `[${datasetIndex}].label`)} ${
                            numberFormat(yLabel) || 0
                          }%`,
                      },
                    },
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>
      </MenuCard>
    </ButtonBase>
  );
};

export const AdvancedDropVariantPreviews = ({
  advancedDrop,
  className,
  variant,
  loading,
}: any) => {
  return (
    <div style={{ width: 290, margin: "auto" }} className={className || ""}>
      <div className="mb-4" />
      <div className="mb-2">
        <Typography>Ad #1</Typography>
        <DropPreview
          drop={advancedDrop && advancedDrop.drop}
          loading={loading}
        />
      </div>
      {((advancedDrop && advancedDrop.variants) || []).map(
        (variant: any, i: number) => (
          <div key={i} className="mb-2">
            <Typography>Ad #{i + 2}</Typography>
            <DropPreview drop={variant} loading={loading} />
          </div>
        )
      )}
    </div>
  );
};

export const AdvancedDropDetailView = ({
  advancedId,
  advancedDrop,
  loading,
}: any) => {
  const [activeCount, setActiveCount] = useState("count");
  const [activeVariant, setActiveVariant] = useState(null);
  return (
    <>
      <div className="row flex-md-nowrap">
        <div className="px-3 mb-4 w-md-initial w-100">
          <Toc>
            <AdvancedListDetails
              advancedDrop={advancedDrop}
              loading={loading}
            />
            <AdvancedDropFunding
              advancedDrop={advancedDrop}
              loading={loading}
            />
            <AdvancedDropVariantPreviews
              advancedDrop={advancedDrop}
              loading={loading}
              className="d-none d-md-block"
            />
          </Toc>
        </div>
        <div className="col">
          <Toc>
            <GeodropSummary
              advancedId={advancedId}
              active={activeCount}
              setActive={setActiveCount}
              skip={!advancedId}
            />
            <Divider className="mb-4" />
            <div style={{ overflow: "auto", maxWidth: "100%" }}>
              {advancedDrop && (
                <AdvancedStats
                  advancedId={advancedId}
                  countBy={activeCount}
                  setCountBy={setActiveCount}
                />
              )}
            </div>
          </Toc>
        </div>
      </div>
      <AdvancedDropVariantPreviews
        advancedDrop={advancedDrop}
        loading={loading}
        className="d-md-none"
      />
    </>
  );
};

const MakeInactive = React.forwardRef(
  ({ advancedDrop, ...props }: any, ref) => {
    const [updateAdvancedDrop, updateState] = useMutation(
      UPDATE_ADVANCED_DROP,
      {
        variables: {},
        ...props,
      }
    );
    if (!advancedDrop || !advancedDrop.active) return null;
    return (
      <MenuItemLoading
        ref={ref}
        loading={updateState.loading}
        onClick={(ev: any) => {
          ev.stopPropagation();
          updateAdvancedDrop({
            variables: { id: advancedDrop.id, options: { active: false } },
          });
        }}
      >
        <i className="fa fa-pause mr-2" /> Set Inactive
      </MenuItemLoading>
    );
  }
);

const MakeActive = React.forwardRef(({ advancedDrop, ...props }: any, ref) => {
  const [updateAdvancedDrop, updateState] = useMutation(UPDATE_ADVANCED_DROP, {
    variables: {},
    ...props,
  });
  if (!advancedDrop || advancedDrop.active) return null;
  return (
    <MenuItemLoading
      ref={ref}
      loading={updateState.loading}
      onClick={(ev: any) => {
        ev.stopPropagation();
        updateAdvancedDrop({
          variables: { id: advancedDrop.id, options: { active: true } },
        });
      }}
    >
      <i className="fa fa-play mr-2" /> Set Active
    </MenuItemLoading>
  );
});

const MenuItemLoading = React.forwardRef(
  ({ children, onClick, loading }: any, ref) => {
    return (
      <MenuItem onClick={onClick}>
        {children}{" "}
        {loading && <div className="spinner-border spinner-border-sm ml-2" />}
      </MenuItem>
    );
  }
);

export const NoGeoDrops = () => {
  return (
    <Paper className="list-group-item text-center py-5 rounded-0-sm">
      <h4>No Advanced Drops Found</h4>
      <p>
        Read more about geodrops{" "}
        <a
          href="https://support.coinapp.co/hc/en-us/categories/360002099633"
          target="_blank"
        >
          here
        </a>
        .
      </p>
    </Paper>
  );
};

const advancedDropTitle = (advancedDrop: any) => {
  let str = "";
  if (!advancedDrop) return str;
  const { drop, startTime } = advancedDrop;
  if (!drop) return str;
  if (drop.amount) str += `Drop ${drop.amount}`;
  if (drop.quadkey) str += ` at ${drop.quadkey}`;
  if (drop.email) str += ` to ${drop.email}`;
  if (drop.quantity === 1) str += ` 1 time`;
  if (drop.quantity > 1) str += ` ${drop.quantity} times`;
  if (startTime) str += ` after ${new Date(startTime).toLocaleString()}`;
  return str;
};

interface IResourceRouterControls {
  goToList: () => any;
  goToDetails: (id: string) => any;
  goToUpdate: (id: string) => any;
  goToDebug: (id: string) => any;
}

interface IBooleanControls {
  setTrue: () => void;
  setFalse: () => void;
}

const parseNumber = (n: number) => parseFloat(n.toFixed(2));

const AdvancedDropFunding = ({ advancedDrop, loading }: any) => {
  const { userInfo, refetchUserInfo } = useAuth();
  const xyoBossBalance = get(userInfo, "xyoBossBalance") || 0;
  const advancedId = get(advancedDrop, "id");
  const currentBalance = get(advancedDrop, "currentBalance") || 0;
  const currentGiftBalance = get(advancedDrop, "currentGiftBalance") || 0;
  const [{}, { setSuccess, setError, setInfo }] = useAlertContext();
  const [open, setOpen] = useState(false);
  const [deposit, setDeposit] = useState(0);
  const [withdraw, setWithdraw] = useState(0);
  const [depositFunds, depositState] = useMutation(DEPOSIT_ADVANCED_DROP);
  const [withdrawFunds, withdrawState] = useMutation(WITHDRAW_ADVANCED_DROP);
  const handleWithdrawChange = (ev: any) => {
    const value = Number(ev.target.value);
    if (isNaN(value)) return;
    if (value > currentBalance) {
      setError(`Only ${numberFormat(currentBalance)} available to withdraw.`);
    } else {
      setWithdraw(ev.target.value);
    }
  };
  const handleDepositChange = (ev: any) => {
    const value = Number(ev.target.value);
    if (isNaN(value)) return;
    if (value > xyoBossBalance) {
      setError(`Only ${numberFormat(xyoBossBalance)} available to deposit.`);
    } else {
      setDeposit(ev.target.value);
    }
  };
  const handleWithdrawMax = () => {
    setWithdraw(parseNumber(currentBalance - currentGiftBalance));
    if (currentGiftBalance) {
      setInfo(
        `${numberFormat(
          currentGiftBalance
        )}COIN can not be withdrawn because it was gifted.`
      );
    }
  };
  const handleDepositMax = () => {
    setDeposit(parseNumber(xyoBossBalance));
  };
  const handleWithdrawSubmit = async () => {
    try {
      setWithdraw(0);
      await withdrawFunds({
        variables: {
          id: advancedId,
          amount: Number(withdraw),
        },
      });
      await refetchUserInfo();
      setSuccess(`Successful withdrawal for ${numberFormat(withdraw)}`);
      trackInfo("Successful withdrawal", { withdraw: numberFormat(withdraw) });
    } catch (e) {
      setError(e);
      trackError("Campaign Withdraw Error", e);
    }
  };
  const handleDepositSubmit = async () => {
    try {
      setDeposit(0);
      await depositFunds({
        variables: {
          id: advancedId,
          amount: Number(deposit),
        },
      });
      await refetchUserInfo();
      setSuccess(`Successful deposit for ${numberFormat(deposit)}`);
      trackInfo("Successful deposit", { deposit: numberFormat(deposit) });
    } catch (e) {
      setError(e);
      trackError("Campaign Deposit Error", e);
    }
  };
  return (
    <div className="row mt-4">
      <div className="col">
        <LoadingButton
          color="primary"
          variant="contained"
          loading={false}
          disabled={loading}
          onClick={() => setOpen(true)}
          className=""
          fullWidth
        >
          Withdraw
        </LoadingButton>
      </div>
      <div className="col">
        <LoadingButton
          color="secondary"
          variant="contained"
          loading={false}
          disabled={loading}
          onClick={() => setOpen(true)}
          className=""
          fullWidth
        >
          Deposit
        </LoadingButton>
      </div>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>COIN Boss Transfer</DialogTitle>
        <DialogContent>
          <Typography paragraph>Deposit</Typography>
          <Box mb={2}>
            <Grid container spacing={1}>
              <Grid item xs>
                <TextField
                  className="m-0"
                  value={numberFormat(xyoBossBalance)}
                  label="BOSS Balance"
                  variant="outlined"
                  margin="dense"
                  disabled={true}
                  fullWidth
                />
              </Grid>
              <Grid item xs>
                <TextField
                  className="m-0"
                  value={deposit}
                  onChange={handleDepositChange}
                  helperText={
                    <Typography
                      component="span"
                      color="secondary"
                      onClick={handleDepositMax}
                      style={{ fontSize: "inherit", cursor: "pointer" }}
                    >
                      Max Amount
                    </Typography>
                  }
                  label="Deposit Amount"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Tooltip title="Transfer From BOSS Balance">
                  <div>
                    <Fab
                      size="small"
                      onClick={handleDepositSubmit}
                      disabled={!deposit || depositState.loading}
                    >
                      {depositState.loading ? (
                        <CircularProgress />
                      ) : (
                        <SendIcon />
                      )}
                    </Fab>
                  </div>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>

          <Typography paragraph>Withdraw</Typography>
          <Box mb={4}>
            <Grid container spacing={1}>
              <Grid item xs>
                <TextField
                  className="m-0"
                  value={numberFormat(currentBalance)}
                  label="Campaign Balance"
                  variant="outlined"
                  margin="dense"
                  disabled={true}
                  fullWidth
                />
              </Grid>
              <Grid item xs>
                <TextField
                  className="m-0"
                  value={withdraw}
                  onChange={handleWithdrawChange}
                  helperText={
                    <Typography
                      component="span"
                      color="secondary"
                      onClick={handleWithdrawMax}
                      style={{ fontSize: "inherit", cursor: "pointer" }}
                    >
                      Max Amount
                    </Typography>
                  }
                  label="Withdrawal Amount"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Tooltip title="Transfer To BOSS Balance">
                  <div>
                    <Fab
                      size="small"
                      onClick={handleWithdrawSubmit}
                      disabled={!withdraw || withdrawState.loading}
                    >
                      {withdrawState.loading ? (
                        <CircularProgress />
                      ) : (
                        <SendIcon />
                      )}
                    </Fab>
                  </div>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>

        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};
