import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Rating from "@material-ui/lab/Rating";
import cx from "classnames";
import debounce from "lodash/debounce";
import { locationToQuadkey } from "quadkeytools";
import React, { Component } from "react";
import { renderToStringWithData } from "react-apollo";
import { bossClient } from "../../context/bossapi";
import { featureCollection, pointType, polygonType } from "../../utils/map";
import { LOCATION_SUMMARY_QUERY } from "../Geodrops/gql";
import { initializeGoogleMaps, initializeMapbox } from "./initialize";

export * from "./Static";

const poi = {
  staples: {
    lng: -118.2673,
    lat: 34.043
  }
};

export function mapBounds(coords, bounds) {
  return coords.reduce((acc, c) => {
    if (!acc) return new window.mapboxgl.LngLatBounds(c, c);
    return acc.extend(c);
  }, bounds);
}

export default class Map extends Component {
  precision = 20;
  circleSize = 120;
  metersPerPixel = 60000;
  state = {
    loaded: false
  };

  getInitialZoom() {
    return this.precision - 2;
  }

  getInitialBearing() {
    return 180;
  }

  getInitialPitch() {
    return 60;
  }

  async getInitialOptions() {
    const lonlat = this.props.userCenter && (await this.getUserLocation());
    const zoom = this.getInitialZoom();
    const bearing = this.getInitialBearing();
    const pitch = this.getInitialPitch();
    if (!lonlat)
      return {
        zoom,
        bearing,
        pitch,
        center: poi.staples
      };
    return {
      zoom,
      bearing,
      pitch,
      center: {
        lng: lonlat[0],
        lat: lonlat[1]
      }
    };
  }

  async componentDidMount() {
    if (!("mapboxgl" in window)) {
      await initializeMapbox();
    }
    if ("mapboxgl" in window && !this.map) {
      window.mapboxgl.accessToken = process.env.REACT_APP_MAPBOX;
      const options = await this.getInitialOptions();
      this.mapboxgl = window.mapboxgl;
      this.map = new window.mapboxgl.Map({
        container: this.props.id,
        style: "mapbox://styles/mapbox/dark-v9",
        attributionControl: false,
        ...options
      });
      this.map.on("load", () => {
        this.setState({ loaded: true });
        this.handleMapLoad();
      });
      this.map.on("drag", this.handleMapDrag.bind(this));
      this.map.on("zoom", this.handleMapZoom.bind(this));
      this.map.on("click", this.handleMapClick.bind(this));
    }
  }

  getCurrentQuadkey() {
    const { lat, lng } = this.map.getCenter();
    const zoom = Math.floor(this.map.getZoom());
    const detail = this.props.precision
      ? this.props.precision
      : Math.min(zoom + 2, this.precision);
    const quadkey = locationToQuadkey({ lat, lng }, detail);
    return quadkey;
  }

  getUserLocation() {
    return getUserLocation();
  }

  watchNavigatorLocation(fn) {
    return watchNavigatorLocation(fn);
  }

  handleMapLoad(v) {
    console.log("Map loaded", this.map, v);
  }

  handleMapDrag(v) {
    console.log("Map dragged", this.map, v);
  }

  handleMapZoom(v) {
    console.log("Map zoomed", this.map, v);
  }

  handleMapClick(v) {
    console.log("Map clicked", this.map, v);
    if (this.skipMapClick) {
      this.skipMapClick = false;
      return;
    }
    if (!this.props.drawPolygon) return;
    if (!this.selectedLocation) {
      this.selectedLocation = [];
    }
    const lnglat = [v.lngLat.lng, v.lngLat.lat];
    this.selectedLocation.push(lnglat);
    if (this.selectedLocation.length === 1) {
      const el = document.createElement("div");
      el.className = "close-loop-marker";
      const marker = new window.mapboxgl.Marker(el)
        .setLngLat(lnglat)
        .addTo(this.map);
      el.addEventListener("contextmenu", ev => {
        ev.stopPropagation();
        this.selectedLocation = [];
        this.drawLineString("selected-location", this.selectedLocation);
        this.drawCircles("selected-location-circles", this.selectedLocation, {
          "circle-radius": 4
        });
        marker.remove();
      });
      el.addEventListener("click", ev => {
        ev.stopPropagation();
        if (this.selectedLocation.length < 3) return;
        this.selectedLocation.push(this.selectedLocation[0]);
        if (!this.selectedLocations) this.selectedLocations = [];
        this.selectedLocations.push(this.selectedLocation);
        this.selectedLocation = [];
        this.drawMultiPolygon("selected-locations", this.selectedLocations);
        this.drawLineString("selected-location", this.selectedLocation);
        this.drawCircles("selected-location-circles", this.selectedLocation, {
          "circle-radius": 4
        });
        marker.remove();
        if (this.props.onSelectedLocationsChange) {
          this.props.onSelectedLocationsChange(this.selectedLocations);
        }
      });
    }
    this.drawCircles("selected-location-circles", this.selectedLocation, {
      "circle-radius": 4
    });
    this.drawLineString("selected-location", this.selectedLocation);
  }

  upsertSource(layer) {
    if (!this.map) return;
    const src = this.map.getSource(layer.id);
    if (src) {
      src.setData(layer.source.data);
    } else {
      this.map.addLayer(layer);
    }
  }

  getRadius() {
    return (
      (this.circleSize * this.metersPerPixel) / Math.pow(2, this.map.getZoom())
    );
  }

  drawCircles(
    id,
    coordinates,
    paint = {
      "circle-radius": 10,
      "circle-color": "#F89D42"
    }
  ) {
    if (!this.map) return;
    this.upsertSource({
      id,
      type: "circle",
      source: {
        type: "geojson",
        data: featureCollection(pointType)(coordinates)
      },
      paint
    });
  }

  drawLineString(
    id,
    coordinates,
    paint = {
      "line-color": "#F89D42",
      "line-width": 4
    }
  ) {
    if (!this.map) return;
    this.upsertSource({
      id,
      source: {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates
          }
        }
      },
      type: "line",
      layout: {
        "line-join": "round",
        "line-cap": "round"
      },
      paint
    });
  }

  drawMultiPolygon(
    id,
    coordinates,
    paint = { "fill-color": "#088", "fill-opacity": 0.4 }
  ) {
    if (!this.map) return;
    this.upsertSource({
      id,
      source: {
        type: "geojson",
        data: featureCollection(polygonType)(coordinates)
      },
      type: "fill",
      layout: {},
      paint
    });
  }

  render() {
    const {
      style,
      className,
      userCenter,
      debugMultiPolygon,
      onMultiPolygonChange,
      onMapDrag,
      watchUser,
      userMarker,
      ...props
    } = this.props;
    return (
      <div
        {...props}
        className={cx(className, { skeleton: !this.state.loaded })}
        style={{
          top: 0,
          left: 0,
          position: "absolute",
          height: "100%",
          width: "100%",
          overflow: "hidden",
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          ...style
        }}
      >
        {!this.state.loaded && (
          <div className="d-flex align-items-center justify-content-center h-100">
            <p>Initializing Map...</p>
          </div>
        )}
      </div>
    );
  }
}

export class PointsOfInterestMap extends Map {
  state = {
    search: "",
    results: []
  };
  markers = {};
  service = null;
  handleMapDrag() {
    this.removeMarkers();
    this.handleSearch(this.state.search);
  }
  handleChange = ev => {
    const search = ev.target.value;
    this.bounds = null; // Will force new bounds
    this.removeMarkers();
    this.setState({ search });
    this.handleSearch(search);
  };
  getPlacesService = async () => {
    if (this.service) return this.service;
    this.service = initializeGoogleMaps().then(
      () => new window.google.maps.places.PlacesService(this.attr)
    );
    return this.service;
  };
  handleSearch = debounce(async query => {
    const { lat, lng } = this.map.getCenter();
    const service = await this.getPlacesService();
    const radius = this.getRadius();
    const location = { lat, lng };
    const filter = { query, radius, location };
    if (filter.query) {
      service.textSearch(filter, this.handleResults);
    } else {
      service.nearbySearch(filter, this.handleResults);
    }
  }, 600);
  handleResults = results => {
    this.setState({ results });
    const coordinates = [];
    let bounds = null;
    results.forEach(result => {
      const { location } = result.geometry;
      const lnglat = [location.lng(), location.lat()];
      coordinates.push([
        nw(lnglat),
        ne(lnglat),
        se(lnglat),
        sw(lnglat),
        nw(lnglat)
      ]);
      if (!bounds) bounds = new window.mapboxgl.LngLatBounds(lnglat, lnglat);
      bounds = bounds.extend(lnglat);
      this.addMarker({
        ...result,
        lnglat
      });
    });
    if (this.props.onMultiPolygonChange) {
      this.props.onMultiPolygonChange(coordinates);
    }
    if (this.props.debugMultiPolygon) {
      this.drawMultiPolygon("poi-multi-polygon", coordinates);
    }
    if (!this.bounds) {
      this.map.fitBounds(bounds, { padding: 20, easing: () => 1 });
      this.bounds = bounds;
    }
  };
  addMarker = async ({ id, name, lnglat, icon, rating, ...result }) => {
    if (this.markers[id]) return;
    const el = document.createElement("div");
    el.className = "marker";
    if (icon) el.style.backgroundImage = `url(${icon})`;
    const Popup = await renderToStringWithData(
      <div className="text-dark">
        <p className="mb-0">{name}</p>
        <p className="mb-0 text-muted d-flex align-items-center">
          <span className="spinner-border spinner-border-sm" id={id}></span>{" "}
          <span className="ml-1">Users Within 1 Mile</span>
        </p>
        <Rating value={rating} readOnly />
      </div>
    );
    const popup = new window.mapboxgl.Popup({ offset: 25 }).setHTML(Popup);
    const marker = new window.mapboxgl.Marker(el)
      .setLngLat(lnglat)
      .setPopup(popup)
      .addTo(this.map);
    el.addEventListener("click", async () => {
      this.skipMapClick = true;
      const { data } = await bossClient.query({
        query: LOCATION_SUMMARY_QUERY,
        variables: {
          near: {
            lon: lnglat[0],
            lat: lnglat[1],
            maxMeters: 1600,
            minMeters: 0
          }
        }
      });
      const element = document.getElementById(id);
      if (element) {
        element.classList = "";
        element.textContent = data.locationSummary.count;
      }
    });
    this.markers[id] = marker;
  };
  removeMarkers = keep => {
    Object.keys(this.markers)
      .slice(keep || 0)
      .forEach(id => {
        this.markers[id].remove();
        delete this.markers[id];
      });
  };
  render() {
    return (
      <>
        {super.render()}
        <div style={{ position: "absolute", top: 0, left: 0 }}>
          <Paper className="m-1 p-2">
            <TextField
              variant="outlined"
              label={this.state.loaded ? "Search..." : "Initializing Search..."}
              margin="dense"
              disabled={!this.state.loaded}
              value={this.state.search}
              onChange={this.handleChange}
            />
            <div ref={ref => (this.attr = ref)} />
          </Paper>
        </div>
      </>
    );
  }
}

export function watchNavigatorLocation(cb) {
  if ("geolocation" in navigator) {
    const id = navigator.geolocation.watchPosition(position => {
      if (position.coords.longitude && position.coords.latitude) {
        cb([position.coords.longitude, position.coords.latitude]);
      }
    });
    return () => navigator.geolocation.clearWatch(id);
  }
  return () => {};
}

export function getNavigatorLocation() {
  return new Promise(res => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        position => {
          if (position.coords.longitude && position.coords.latitude) {
            res([position.coords.longitude, position.coords.latitude]);
          } else {
            res(null);
          }
        },
        () => res(null)
      );
    } else {
      res(null);
    }
  });
}

export function getIPLocation() {}

export async function getUserLocation() {
  const geoLonLat = await getNavigatorLocation();
  if (geoLonLat) return geoLonLat;
  const ipLonLat = await getIPLocation();
  if (ipLonLat) return ipLonLat;
  return null;
}

function nw([lon, lat], d = 0.01) {
  return [lon - d / 2, lat + d / 2];
}
function ne([lon, lat], d = 0.01) {
  return [lon + d / 2, lat + d / 2];
}
function se([lon, lat], d = 0.01) {
  return [lon + d / 2, lat - d / 2];
}
function sw([lon, lat], d = 0.01) {
  return [lon - d / 2, lat - d / 2];
}
