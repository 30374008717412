import React from "react";
import ApolloClient, { InMemoryCache } from "apollo-boost";
import { ApolloProvider } from "@apollo/react-hooks";
import { trackError, trackWarning } from "../utils/track";
import { auth } from "firebase";
import gql from "graphql-tag";

// const uri = "http://localhost:8083";
// const uri = "https://bossapi.coinapp.co";
const uri = "https://prod.coinapp.co/manager";

export const bossClient = new ApolloClient({
  uri,
  // fetch,
  onError: ({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      trackError("Boss api gql error", JSON.stringify(graphQLErrors));
    }
    if (networkError) {
      trackWarning("Boss api net error", JSON.stringify(networkError));
    }
  },
  cache: new InMemoryCache({
    typePolicies: {
      EarlyAccess: {
        keyFields: ["uid"],
      },
    },
  } as any),
  request: async (op) => {
    const user = auth().currentUser;
    if (!user) return;
    const token = await user.getIdToken();
    op.setContext({
      headers: {
        "X-Auth-Token": token,
      },
    });
  },
});

export const BossProvider: React.FC = ({ children }) => {
  return <ApolloProvider client={bossClient}>{children}</ApolloProvider>;
};

export const USER_INFO_FRAGMENT = gql`
  fragment UserInfo on UserInfo {
    id
    subActive
    lastMaphash
    xyoRedeemed
    xyoPending
    xyoRedeemPending
    xyoDropped
    xyoCollected
    xyoGifted
    xyoPickedUp
    xyoGeoclaimed
    xyoHodlRewards
    xyoBossBalance
    xyoEnabled
    hodlLastCollectedYYYYMMDD
    recordVersion
    remineMarker
    recentCount
    color
    referrer
    checkInDate
    created
    appVersion
    referrerUid
    addedToDrip
    mineCountNoSentinel
    ibeacons
    remineCount
    subscriptionInfo
    osDevMode
    ownerUID
    osVersion
    mineCount
    recentMarker
    mineCountSentinel
    direction
    activeSubscription
    ip
    lastMinedYYYYMMDD
    verifiedAddressesTotal
    sentinels
    random
    lastMined
    suspended
    lastSync
    ssid
    os
    deviceId
    deviceName
    speed
    photoURL
    email
    bossAccess
    bossAdmin
  }
`;

export const MY_USER_INFO = gql`
  query MyUserInfo {
    myUserInfo {
      ...UserInfo
    }
  }

  ${USER_INFO_FRAGMENT}
`;
