import React, { CSSProperties, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HorizontalLogo from "../Logo/Horizontal";

export const Footer = ({ className }: { className?: string }) => {
  return (
    <div className={className} id="footer">
      <Mobile className="d-lg-none w-100" />
      <Desktop className="d-none d-lg-block w-100 hill-drop-shadow" />
    </div>
  );
};

export const FooterLinks = () => {
  const [isDesktop, setIsDesktop] = useState<boolean | null>(null);
  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        window.navigator.userAgent
      )
    ) {
      // window.location.href = 'coinapp://premium'
      setIsDesktop(false);
    } else {
      setIsDesktop(true);
    }
  }, []);
  return (
    <div className="container-fluid py-3">
      <footer className="p-1">
        <div className="container">
          <div className="row">
            <div className="col-12 offset-sm-2 col-sm-8 text-light pb-4">
              <div className="text-center mb-2">
                <Link to="/" className="link-unstyled">
                  <HorizontalLogo />
                </Link>
              </div>
              <p className="text-center">
                Launched in early 2019, COIN is a mobile app enabling hundreds
                of thousands of people to earn digital assets for validating
                geospatial location data when traveling, commuting, jogging or
                moving about the real world.
              </p>
            </div>
            <div className="offset-1 col-5 offset-sm-2 col-sm-4 offset-md-1 col-md-2 offset-lg-2 col-lg-2 col-xl-2 mb-5">
              <span className="text-secondary-light">GET STARTED</span>
              <a className="d-flex" href="//coinapp.co/1-2-3">
                1 - 2 - 3
              </a>
              <a
                className="d-flex"
                target="_blank"
                href="//coinapp.co/userguide"
              >
                User Guide
              </a>
            </div>
            <div className="col-5 col-md-2 col-lg-2 col-xl-2 mb-5">
              <span className="text-secondary-light">INFORMATION</span>
              <a
                className="d-flex"
                target="_blank"
                href="//coinapp.co/geomining/"
              >
                Geomining
              </a>
              <a
                className="d-flex"
                target="_blank"
                href="//coinapp.co/geodrops/"
              >
                Geodrops
              </a>
              <a
                className="d-flex"
                target="_blank"
                href="//coinapp.co/geoclaims/"
              >
                Geoclaims
              </a>
            </div>
            <div className="offset-1 col-5 offset-sm-2 col-sm-4 offset-md-0 col-md-2 col-lg-2 col-xl-2 mb-5">
              <span className="text-secondary-light">PRODUCTS</span>
              {isDesktop === true ? (
                <>
                  <a
                    className="d-flex"
                    href="https://itunes.apple.com/app/id1450443351"
                  >
                    iOS App
                  </a>
                  <a
                    className="d-flex"
                    href="https://play.google.com/store/apps/details?id=network.xyo.coin"
                  >
                    Android App
                  </a>
                </>
              ) : isDesktop === false ? (
                <>
                  <a className="d-flex" href="coinapp://premium">
                    iOS App
                  </a>
                  <a className="d-flex" href="coinapp://premium">
                    Android App
                  </a>
                </>
              ) : null}
            </div>
            <div className="col-4 col-md-3 col-lg-2 mb-5">
              <span className="text-secondary-light">SOCIALS</span>
              <br />
              <div className="d-flex justify-content-between pt-2">
                <a target="_blank" href="https://www.facebook.com/coinwithus">
                  <i className="fab fa-facebook fa-2x"></i>
                </a>
                <a target="_blank" href="https://twitter.com/coin_with_us">
                  <i className="fab fa-twitter fa-2x"></i>
                </a>
                <a target="_blank" href="https://www.instagram.com/coinwithus">
                  <i className="fab fa-instagram fa-2x"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="container">
        <div className="row small text-primary p-3 mb-5">
          <div className="col-12 my-4 text-primary-light text-center">
            <p>COIN © - 2019 All Rights Reserved</p>
            <p>
              <a href="https://support.coinapp.co/hc/en-us" target="_blank">
                Support
              </a>{" "}
              - <Link to="/terms">Ad Policy</Link>
              {/* -{" "} */}
              {/* <a href="//coinapp.co/terms">Terms of Service</a> -{" "}
              <a href="//coinapp.co/sales-terms">Terms of Sale</a> */}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Desktop = ({
  className,
  style
}: {
  className?: string;
  style?: CSSProperties;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
      style={style}
      viewBox="0 0 1440 120"
    >
      <defs>
        <linearGradient id="b-desktop" x1="100%" x2="0%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="#FF9E7E" />
          <stop offset="100%" stopColor="#23212F" />
        </linearGradient>
        <path
          id="a-desktop"
          d="M0 120h1440V24.916C1116.544 30.856 676.831 38.146 656.061 39 616.703 39 221.574 24.414 0 15v105z"
        />
        <linearGradient id="d-desktop" x1="0%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="#FF9E7E" />
          <stop offset="100%" stopColor="#23212F" />
        </linearGradient>
        <path
          id="c-desktop"
          d="M0 120h1440V32.994L839.438 63.582C800.765 64.739 418.758 11.573 378.148 0 322.554 7.234 0 33.934 0 33.934V120z"
        />
        <path
          id="f-desktop"
          d="M0 120h1440V32.994L839.438 63.582C800.765 64.739 418.758 11.573 378.148 0 322.554 7.234 0 33.934 0 33.934V120z"
        />
        <linearGradient id="h-desktop" x1="0%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="#FF9E7E" />
          <stop offset="100%" stopColor="#23212F" />
        </linearGradient>
        <path
          id="g-desktop"
          d="M0 33.253V120h315c-29.498-6.237-87.135-41.489-85.298-45.24 2.997-6.128 24.886-9.464 43.692-12.09 31.667-4.421 62.117-9.613 90.954-15.51 4.853-.993 9.89-2.086 12.224-3.697 3.931-2.713-1.06-5.758-5.249-8.436-11.06-7.071-17.127-14.83-17.687-22.63C353.301 7.717 365.317 1.995 378 0L0 33.253z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <use fill="#FF9E7E" xlinkHref="#a-desktop" />
        <use fill="url(#b-desktop)" fillOpacity=".75" xlinkHref="#a-desktop" />
        <mask id="e-desktop" fill="#fff">
          <use xlinkHref="#c-desktop" />
        </mask>
        <use fill="url(#d-desktop)" xlinkHref="#c-desktop" />
        <g mask="url(#e-desktop)">
          <use fill="#FF9E7E" xlinkHref="#f-desktop" />
          <use fill="url(#d-desktop)" fillOpacity=".5" xlinkHref="#f-desktop" />
        </g>
        <g mask="url(#e-desktop)">
          <use fill="#FF9E7E" xlinkHref="#g-desktop" />
          <use fill="url(#h-desktop)" fillOpacity=".5" xlinkHref="#g-desktop" />
        </g>
      </g>
    </svg>
  );
};

const Mobile = ({
  className,
  style
}: {
  className?: string;
  style?: CSSProperties;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
      style={style}
      viewBox="0 0 375 120"
    >
      <defs>
        <linearGradient id="b" x1="100%" x2="0%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="#FF9E7E" />
          <stop offset="100%" stopColor="#23212F" />
        </linearGradient>
        <path
          id="a"
          d="M0 120h375V24.916C290.767 30.856 176.258 38.146 170.85 39 160.6 39 57.701 24.414 0 15v105z"
        />
        <linearGradient id="d" x1="0%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="#FF9E7E" />
          <stop offset="100%" stopColor="#23212F" />
        </linearGradient>
        <path
          id="c"
          d="M0 120h375V32.994L218.604 63.582C208.533 64.739 109.052 11.573 98.476 0 83.999 7.234 0 33.934 0 33.934V120z"
        />
        <path
          id="f"
          d="M0 120h375V32.994L218.604 63.582C208.533 64.739 109.052 11.573 98.476 0 83.999 7.234 0 33.934 0 33.934V120z"
        />
        <linearGradient id="h" x1="0%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="#FF9E7E" />
          <stop offset="100%" stopColor="#23212F" />
        </linearGradient>
        <path
          id="g"
          d="M0 33.253V120h82.031c-7.681-6.237-22.691-41.489-22.213-45.24.78-6.128 6.481-9.464 11.378-12.09a163.51 163.51 0 0 0 23.686-15.51c1.264-.993 2.576-2.086 3.184-3.697 1.023-2.713-.276-5.758-1.367-8.436-2.88-7.071-4.46-14.83-4.606-22.63C92.005 7.717 95.135 1.995 98.437 0L0 33.253z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <use fill="#FF9E7E" xlinkHref="#a" />
        <use fill="url(#b)" fillOpacity=".75" xlinkHref="#a" />
        <mask id="e" fill="#fff">
          <use xlinkHref="#c" />
        </mask>
        <use fill="url(#d)" xlinkHref="#c" />
        <g mask="url(#e)">
          <use fill="#FF9E7E" xlinkHref="#f" />
          <use fill="url(#d)" fillOpacity=".5" xlinkHref="#f" />
        </g>
        <g mask="url(#e)">
          <use fill="#FF9E7E" xlinkHref="#g" />
          <use fill="url(#h)" fillOpacity=".5" xlinkHref="#g" />
        </g>
      </g>
    </svg>
  );
};
