import ButtonBase from "@material-ui/core/ButtonBase";
import TextField from "@material-ui/core/TextField";
import React from "react";
import { useAuth } from "../../context";
import { numberFormat } from "../../utils/number";
import { useBalanceTransfer } from "./BalanceTransfer";

export const UserBalance = () => {
  const { userInfo } = useAuth();
  const [{ bossBalance }, { setTrue }] = useBalanceTransfer();
  return userInfo && userInfo.bossAccess ? (
    <div className="position-relative ml-4">
      <TextField
        className="m-0 border-danger"
        value={numberFormat(bossBalance)}
        label="Balance"
        variant="outlined"
        margin="dense"
        disabled={true}
      />
      <ButtonBase
        className="position-absolute"
        style={{
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          width: "100%",
          height: "100%"
        }}
        onClick={ev => setTrue()}
      ></ButtonBase>
    </div>
  ) : null;
};
