import React, { useState } from "react";
import {
  GeodropSummary,
  GeodropSummaryControls,
  GeodropSummaryRangeControls,
  GeodropSummaryRangeProvider,
  CreateGeodropDialog,
  useAdvancedDropList,
  AdvancedDropList
} from "../../components/Geodrops";
import { Footer, AdvancedDropBottomNav } from "../../components/Layout";
import WelcomeDialog from "../../components/Welcome";
import { AdvancedStats } from "./Graph";

export default () => {
  const advancedDropList = useAdvancedDropList();
  const [active, setActive] = useState("count");
  return (
    <GeodropSummaryRangeProvider initial="6,0">
      <div className="container-fluid">
        <div className="row pb-40vh">
          <div className="col-12 pt-2 col-lg-8 offset-lg-2">
            <GeodropSummaryRangeControls
              title="Geodrop Summary"
              className="mb-3"
            />
            <GeodropSummary active={active} setActive={setActive} />
            {/* <AdvancedStats
              advancedDrops={advancedDropList.list}
              countBy={active}
            /> */}
            <AdvancedDropList {...advancedDropList} />
          </div>
        </div>
        <CreateGeodropDialog />
      </div>
      <AdvancedDropBottomNav />
      <WelcomeDialog />
      <Footer />
    </GeodropSummaryRangeProvider>
  );
};
