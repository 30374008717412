import React from "react";
import get from "lodash/get";
import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";
import { fromYYYYMMDD } from "../../utils/date";
import { LineChart } from "react-chartkick";

export function toSeries(name, results, enumerated) {
  const variantResults = groupBy(results, "variant");
  const seriesData = Object.keys(variantResults).reduce((acc, c) => {
    const byDay = keyBy(variantResults[c], "yyyymmdd");
    const getByDayValue = key => yyyymmdd => [
      fromYYYYMMDD(yyyymmdd),
      get(byDay, `${yyyymmdd}.${key}`) || 0
    ];
    return acc.concat([
      {
        name: !isNaN(c) ? `Ad #${Number(c) + 2}` : "Ad #1",
        data: (enumerated || []).map(getByDayValue(name))
      }
    ]);
  }, []);
  return seriesData;
}

export const chartOptions = opts => ({
  curve: true,
  legend: false,
  colors: ["#ee7268", "#09baa6", "#ffc107"],
  library: {
    backgroundColor: "#262525",
    timeline: {
      colorByRowLabel: false,
      showBarLabels: false,
      rowLabelStyle: { color: "#fff" },
      barLabelStyle: { color: "#fff" }
    },
    elements: {
      line: {
        fill: true,
        borderWidth: 20,
        hoverBorderWidth: 100
      },
      point: {
        radius: 2
      }
    },
    ...opts
  }
});

export const SparkChart = ({ library, ...props }) => {
  return (
    <LineChart
      {...chartOptions({
        tooltip: {
          enabled: false
        },
        layout: {
          padding: {
            bottom: 8,
            left: 8,
            right: 8,
            top: 8
          }
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              display: false
            }
          ],
          yAxes: [
            {
              position: "right"
            }
          ]
        },
        ...library
      })}
      curve={false}
      {...props}
    />
  );
};
