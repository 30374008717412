import React, { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import coinLogo from "../../assets/CoinNoText.svg";
import { useUser } from "../../context/auth";
import get from "lodash/get";
import cx from "classnames";
import { storage } from "firebase/app";
import "firebase/storage";

export const uploadFile = async (path, file, metadata) => {
  const storageRef = storage().ref();
  const ref = storageRef.child(path);
  await ref.put(file, metadata);
  const url = await ref.getDownloadURL();
  return url;
};

export const ProfileImage = ({ className }) => {
  const user = useUser();
  const [src, setSrc] = useState(user.photoURL);
  const [uploading, setUploading] = useState(false);
  const uploadCoinImageFile = async ev => {
    try {
      setUploading(true);
      const file = ev.target.files[0];
      if (!file) return;
      if (!user) throw new Error("Must be logged in");
      const fileName = get(file, "name");
      const type = get(file, "type");
      const path = `coin/images/${user.uid}/${fileName}`;
      const photoURL = await uploadFile(path, file, { contentType: type });
      await user.updateProfile({ photoURL });
      setSrc(photoURL);
    } catch (err) {
      // swalError(err.message)
    }
    setUploading(false);
  };
  return (
    <div
      className={cx(
        "profile-photo position-relative rounded-circle overflow-hidden mx-auto border border-danger text-danger cursor-pointer",
        className
      )}
      style={{
        height: "6em",
        width: "6em",
        maxHeight: "6em",
        minHeight: "6em",
        maxWidth: "6em",
        minWidth: "6em"
      }}
    >
      {src ? (
        <DefaultImage className="img-fluid" src={src} defaultSrc={coinLogo} />
      ) : (
        ""
      )}
      <div
        className={cx("profile-overlay", { "opacity-1": !src || uploading })}
      >
        {uploading ? <CircularProgress /> : <i className="fa fa-plus fa-2x" />}
      </div>
      <input
        type="file"
        accept="image/png"
        onChange={uploadCoinImageFile}
        className="opacity-0 position-absolute w-100 h-100 top-0 left-0 cursor-pointer"
      />
    </div>
  );
};

export const DefaultImage = ({ src: initialSrc, defaultSrc, ...props }) => {
  const [src, setSrc] = useState("");
  useEffect(() => {
    const img = document.createElement("img");
    img.onload = () => setSrc(initialSrc);
    img.onerror = () => setSrc(defaultSrc);
    img.src = initialSrc;
  }, [initialSrc]);

  if (!src) return <span />;
  return <img src={src} {...props} />;
};
