import React, { useState, FC } from "react";
import {
  Footer,
  FooterLinks,
  SuccessContent,
  ErrorContent,
  AddFab
} from "../../components/Layout";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
  ActionDialog,
  LoadingButton
} from "../../components/Geodrops/MenuCard";
import { useBooleanControls } from "../../utils/hooks";
import { useAuth, bossClient } from "../../context";
import {
  AccountUsersList,
  SelectAccount,
  ACCOUNT,
  ACCEPT_INVITE,
  DECLINE_INVITE,
  PENDING_ACCOUNTS
} from "../../components/User";
import { SlideInUp } from "../../components/Animate";
import { useMutation, useQuery } from "react-apollo";
import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { parseError } from "../../utils";

export default () => {
  const { initialized, userInfo } = useAuth();
  const [completed, completedControls] = useBooleanControls(false);
  const { data, loading, error } = useQuery(PENDING_ACCOUNTS, {
    skip: !userInfo
  });
  const [acceptInvite, accept] = useMutation(ACCEPT_INVITE, {
    update: cache => {
      completedControls.setTrue();
      bossClient.clearStore();
      bossClient.resetStore();
    }
  });
  const [declineInvite, decline] = useMutation(DECLINE_INVITE, {
    update: () => completedControls.setTrue()
  });
  const invitations = data && data.pendingAccounts;
  return (
    <>
      <div className="my-5" />
      <div className="container" style={{ paddingBottom: "15vh" }}>
        <div className="row">
          <div className="offset-md-4 col-md-4 offset-lg-4 col-lg-4">
            <Card>
              <CardContent>
                {error || accept.error || decline.error ? (
                  <SlideInUp delay={0} config={{}}>
                    <ErrorContent>
                      <h4 className="mb-4">Error</h4>
                      <p>
                        {parseError(error || accept.error || decline.error)}
                      </p>
                    </ErrorContent>
                  </SlideInUp>
                ) : invitations && !invitations.length ? (
                  <>
                    <SlideInUp delay={0} config={{}}>
                      <ErrorContent>
                        <h4 className="mb-4">No Pending Invitations</h4>
                        <Link to="/">Home</Link>
                      </ErrorContent>
                    </SlideInUp>
                  </>
                ) : invitations ? (
                  invitations.map((invite: any) => (
                    <>
                      <SlideInUp delay={0} config={{}}>
                        <h5 className="text-center mb-3">
                          You were invited to {invite.account}.
                        </h5>
                      </SlideInUp>
                      {completed ? (
                        <SlideInUp delay={0} config={{}}>
                          <SuccessContent>
                            <h4 className="mb-4">Success</h4>
                            <Link to="/">Home</Link>
                          </SuccessContent>
                        </SlideInUp>
                      ) : (
                        <SlideInUp delay={0} config={{}}>
                          <div className="d-flex justify-content-center">
                            <LoadingButton
                              loading={decline.loading}
                              disabled={false}
                              onClick={() =>
                                declineInvite({ variables: { id: invite.id } })
                              }
                              variant="contained"
                              color="primary"
                              className="text-white"
                            >
                              Decline
                            </LoadingButton>
                            <LoadingButton
                              loading={accept.loading}
                              onClick={() =>
                                acceptInvite({ variables: { id: invite.id } })
                              }
                              disabled={false}
                              variant="contained"
                              color="secondary"
                              className="text-white ml-2"
                            >
                              Accept
                            </LoadingButton>
                          </div>
                        </SlideInUp>
                      )}
                    </>
                  ))
                ) : (
                  <div className="text-center">
                    <CircularProgress />
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
      <Footer />
      <FooterLinks />
    </>
  );
};
