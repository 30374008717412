import debounce from "lodash/debounce";
import flattenDeep from "lodash/flattenDeep";
import over from "lodash/over";
import { origin } from "quadkeytools";
import {
  featureCollection,
  featureType,
  getChildrenAtPrecision,
  quadkeyOutlineType,
  quadkeyType
} from "../../utils/map";
import Map, { PointsOfInterestMap } from "./";

export class QuadkeyMap extends Map {
  layerId = "current-tile";
  unsubs = [];

  getInitialZoom() {
    if (this.props.value) {
      return this.props.value.length - 2;
    }
    return super.getInitialZoom();
  }

  getUserLocation() {
    if (this.props.value) {
      const { lng, lat } = origin(this.props.value);
      return [lng, lat];
    }
    return super.getUserLocation();
  }

  getCurrentLayer(quadkey) {
    // const quadkeys =
    //   quadkey.length > 14
    //     ? flattenDeep(
    //         getChildrenAtPrecision(
    //           quadkey,
    //           this.props.precision || this.precision
    //         )
    //       ).map(quadkey => ({ quadkey }))
    //     : [
    //         {
    //           quadkey
    //         }
    //       ];
    const quadkeys = [
      {
        quadkey
      }
    ];
    return {
      id: this.layerId,
      source: {
        type: "geojson",
        data: featureCollection(quadkeyType)(quadkeys)
      },
      type: "line",
      layout: {},
      paint: {
        "line-color": "#F89D42",
        "line-opacity": 0.4,
        "line-width": 4
        // "line-width": ["interpolate", ["linear"], ["zoom"], 13, 4, 19, 2, 20, 4]
      }
    };
  }

  async handleMapLoad() {
    const quadkey = this.props.value || this.getCurrentQuadkey();
    this.map.addLayer(this.getCurrentLayer(quadkey));
    this.reposition();
    if (this.props.userMarker) {
      const el = document.createElement("div");
      const el2 = document.createElement("div");
      el2.className = "mapboxgl-user-location-dot";
      el.appendChild(el2);
      const marker = new this.mapboxgl.Marker(el);
      if (this.props.watchUser) {
        this.unsubs.push(
          super.watchNavigatorLocation(ll => {
            marker.setLngLat(ll);
            marker.addTo(this.map);
            if (typeof this.props.watchUser === "function") {
              this.props.watchUser(ll);
            }
          })
        );
      } else {
        const ll = await super.getUserLocation();
        marker.setLngLat(ll);
        marker.addTo(this.map);
      }
    }
  }

  handleMapDrag() {
    this.redraw();
    this.reposition();
    if (typeof this.props.onMapDrag) {
      const { lng, lat } = this.map.getCenter();
      this.props.onMapDrag([lng, lat]);
    }
  }

  handleMapZoom() {
    this.redraw();
    this.reposition();
  }

  redraw = debounce(v => {
    const source = this.map.getSource(this.layerId);
    if (source) {
      const quadkey = this.getCurrentQuadkey();
      const layer = this.getCurrentLayer(quadkey);
      source.setData(layer.source.data);
    }
  }, 17);

  reposition = debounce(() => {
    if (this.props.onChange) {
      const currQuadkey = this.getCurrentQuadkey();
      if (this.currQuadkey !== currQuadkey) {
        this.props.onChange(currQuadkey);
        this.currQuadkey = currQuadkey;
      }
    }
  }, 17);

  componentWillUnmount() {
    over(this.unsubs)();
    this.unsubs = [];
  }
}

export class RadiusMap extends PointsOfInterestMap {
  layerId = "radius-map";
  circleSize = 120;
  metersPerPixel = 60000;

  getRadius() {
    return (
      (this.circleSize * this.metersPerPixel) / Math.pow(2, this.map.getZoom())
    );
  }

  getUserLocation() {
    if (this.props.value) {
      const { lng, lat } = this.props.value;
      return [lng, lat];
    }
    return super.getUserLocation();
  }

  getCurrentLayer() {
    const { lat, lng } = this.map.getCenter();
    return {
      id: this.layerId,
      source: {
        type: "geojson",
        data: featureCollection(featureType)([{ lat, lng }])
      },
      type: "circle",
      paint: {
        "circle-radius": this.circleSize,
        "circle-color": "#088",
        "circle-opacity": 0.4
      }
    };
  }

  getCirclesLayer() {
    return {
      id: this.layerId + "circles",
      source: {
        type: "geojson",
        data: featureCollection(featureType)(this.props.circles)
      },
      type: "circle",
      paint: {
        "circle-radius": 10,
        "circle-color": "#000",
        "circle-opacity": 0.4
      }
    };
  }

  componentDidUpdate() {
    if (!this.map || this.circles === this.props.circles) return;
    this.upsertSource(this.getCirclesLayer());
  }

  handleMapLoad() {
    // this.map.addLayer(this.getCurrentLayer())
    this.reposition();
    this.handleSearch("");
  }

  handleMapDrag() {
    super.handleMapDrag();
    this.redraw();
    this.reposition();
  }

  handleMapZoom() {
    this.redraw();
    this.reposition();
  }

  redraw = debounce(v => {
    const source = this.map.getSource(this.layerId);
    if (source) {
      const layer = this.getCurrentLayer();
      source.setData(layer.source.data);
    }
  }, 200);

  reposition = debounce(() => {
    if (this.props.onChange) {
      const { lat, lng } = this.map.getCenter();
      const radius = this.getRadius();
      this.props.onChange({ lat, lng, radius });
    }
  }, 200);
}
