import moment, { Duration } from "moment";
import partial from "lodash/partial";

export const fromNanoseconds = (
  date?: Date | number | string | { seconds: number; nanoseconds: number }
) => {
  if (!date) return new Date();
  if (!isNaN(Number(date))) return new Date(Number(date));
  if (typeof date === "number") return new Date(date);
  if (typeof date === "string") return new Date(date);
  if (date instanceof Date) return date;
  return new Date((date.seconds + date.nanoseconds * 1e-9) * 1000);
};

export const toYYYYMMDD = (date: Date) => {
  return Number(
    date
      .toISOString()
      .replace(/[^0-9]+/g, "")
      .substring(0, 8)
  );
};

export const fromYYYYMMDD = (date: string | number) => {
  const year = Number(String(date).substring(0, 4));
  const month = Number(String(date).substring(4, 6));
  const day = Number(String(date).substring(6, 8));
  return new Date(year, month - 1, day);
};

export const toYYYYMMDDHH = (date: Date) => {
  return Number(
    date
      .toISOString()
      .replace(/[^0-9]+/g, "")
      .substring(0, 10)
  );
};

export const fromYYYYMMDDHH = (date: string | number) => {
  const year = Number(String(date).substring(0, 4));
  const month = Number(String(date).substring(4, 6));
  const day = Number(String(date).substring(6, 8));
  const hour = Number(String(date).substring(8, 10));
  return new Date(year, month - 1, day, hour);
};

export const subTime = (date: Date, time: number) => {
  return new Date(date.valueOf() - time);
};

export function getCurrentCampaign() {
  const start = getCurrentCampaignStart();
  return toYYYYMMDD(start);
}

export function getPreviousCampaign() {
  const lastWeek = addDays(new Date(), -7);
  setUTCMonday(lastWeek);
  return toYYYYMMDD(lastWeek);
}

export function getCurrentCampaignStart() {
  const today = new Date();
  return setUTCMonday(today);
}

export function getCurrentCampaignEnd() {
  const nextWeek = addDays(new Date(), 7);
  return setUTCMonday(nextWeek);
}

export function addDays(date: Date, days = 0) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate() + days
  );
}

export function setUTCMonday(date: Date) {
  const day = date.getUTCDay() || 7; // Get current day number, converting Sun. to 7
  if (day !== 1) {
    // Only manipulate the date if it isn't Mon.
    date.setUTCHours(-24 * (day - 1), 0, 0, 0); // Set the hours to day number minus 1
  } else {
    date.setUTCHours(0, 0, 0, 0);
  }

  return date;
}

export function enumerateTimeBetween(
  timeframe: Duration,
  start: Parameters<typeof moment>[0],
  end: Parameters<typeof moment>[0]
) {
  const times = [];
  const last = moment(end);
  let curr = moment(start);

  while (curr.isSameOrBefore(last)) {
    times.push(curr.clone().toDate());
    curr.add(timeframe);
  }
  return times;
}

export const enumerateDaysBetween = partial(
  enumerateTimeBetween,
  moment.duration(1, "day")
);

export const enumerateHoursBetween = partial(
  enumerateTimeBetween,
  moment.duration(1, "hour")
);
