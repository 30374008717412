import "chart.js";
import moment from "moment";
import React, { useMemo, useState, useRef } from "react";
import { useQuery } from "@apollo/react-hooks";
import { LineChart } from "react-chartkick";
import { TablePaginationActions, useScrollContext } from "../Layout";
import {
  toYYYYMMDDHH,
  toYYYYMMDD,
  enumerateDaysBetween,
  enumerateHoursBetween,
  fromYYYYMMDD,
  fromYYYYMMDDHH
} from "../../utils/date";
import {
  ADVANCED_DROP_HOURLY_STATS,
  ADVANCED_DROP_DAILY_STATS,
  ADVANCED_DROP_DAILY_STATS_PAGE
} from "./gql";
import { chartOptions } from "../Charts";
// import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import Divider from "@material-ui/core/Divider";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import mapValues from "lodash/mapValues";
import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";
import identity from "lodash/identity";
import size from "lodash/size";
import mergeWith from "lodash/mergeWith";
import get from "lodash/get";
import { useGeodropSummaryRangeContext } from "./Summary";

function mergeNumbers(values) {
  return mergeWith({}, ...values, (obj, src) => {
    if (typeof obj === "number") return obj + src;
  });
}

const getByYYYYMMDD = (results, key) => {
  const byDay = keyBy(results, "yyyymmdd");
  return yyyymmdd => [
    fromYYYYMMDD(yyyymmdd),
    get(byDay, `${yyyymmdd}.${key}`) || 0
  ];
};

const getByYYYYMMDDHH = (results, key) => {
  const byDay = keyBy(results, "yyyymmddhh");
  return yyyymmddhh => [
    fromYYYYMMDDHH(yyyymmddhh),
    get(byDay, `${yyyymmddhh}.${key}`) || 0
  ];
};

function buildSeriesFactory(hourly, start, end) {
  const enumerated = hourly
    ? enumerateHoursBetween(start, end).map(toYYYYMMDDHH)
    : enumerateDaysBetween(start, end).map(toYYYYMMDD);
  return (countBy, hourlyResults, dailyResults) => {
    const variantResults = groupBy(
      hourly ? hourlyResults : dailyResults,
      "variant"
    );
    const seriesData = Object.keys(variantResults).reduce((acc, c) => {
      return acc.concat([
        {
          name: !isNaN(c) ? `Ad #${Number(c) + 2}` : "Ad #1",
          data: (enumerated || []).map(
            (hourly ? getByYYYYMMDDHH : getByYYYYMMDD)(
              variantResults[c],
              countBy
            )
          )
        }
      ]);
    }, []);
    return seriesData;
  };
}

export const AdvancedStats = ({
  advancedId,
  countBy = "count",
  setCountBy
}) => {
  const {
    gte,
    lte,
    setDateValues,
    setRangeValue
  } = useGeodropSummaryRangeContext();
  const [hourlyOptions, dailyOptions, buildSeries] = useMemo(() => {
    const end = moment(lte);
    const start = moment(gte);
    if (!start.isValid() || !end.isValid())
      return [{ skip: true }, { skip: true }];
    const hourly = end.diff(start, "hours") <= 48;
    const yyyymmddhh = {
      GTE: toYYYYMMDDHH(start.toDate()),
      LTE: toYYYYMMDDHH(end.toDate())
    };
    const yyyymmdd = {
      GTE: toYYYYMMDD(start.toDate()),
      LTE: toYYYYMMDD(end.toDate())
    };
    const buildSeries = buildSeriesFactory(hourly, start, end);
    return [
      {
        skip: !hourly,
        variables: { advancedId: { EQ: advancedId }, yyyymmddhh }
      },
      { skip: hourly, variables: { advancedId: { EQ: advancedId }, yyyymmdd } },
      buildSeries
    ];
  }, [gte, lte]);

  const hourlyResult = useQuery(ADVANCED_DROP_HOURLY_STATS, hourlyOptions);
  const dailyResult = useQuery(ADVANCED_DROP_DAILY_STATS, dailyOptions);
  const hourlyResults = get(hourlyResult, "data.advancedDropHourlyStats");
  const dailyResults = get(dailyResult, "data.advancedDropDailyStats");
  const seriesData = buildSeries
    ? buildSeries(countBy, hourlyResults, dailyResults)
    : [];
  const handleRowClick = yyyymmdd => {
    setRangeValue("custom");
    setDateValues({
      lte: moment
        .utc(fromYYYYMMDD(yyyymmdd))
        .endOf("day")
        // .utc(true)
        .toString(),
      gte: moment
        .utc(fromYYYYMMDD(yyyymmdd))
        .startOf("day")
        // .utc(true)
        .toString()
    });
  };
  return (
    <>
      <LineChart {...chartOptions()} data={seriesData} />
      <Divider className="mb-4 d-none d-md-block" />
      <AdvancedStatsTable
        advancedId={advancedId}
        countBy={countBy}
        setCountBy={setCountBy}
        onClick={handleRowClick}
      />
    </>
  );
};

export const AdvancedStatsTable = ({
  countBy,
  setCountBy,
  advancedId,
  onClick
}) => {
  const { gte, lte } = useGeodropSummaryRangeContext();
  const pageIdx = useRef({ 1: 100000000 });
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const lastYYYYMMDD = pageIdx.current[page] || 0;
  const result = useQuery(ADVANCED_DROP_DAILY_STATS_PAGE, {
    variables: { advancedId, perPage, lastYYYYMMDD }
  });
  const results = get(result, "data.advancedDropDailyStatsPage.data");
  const remoteRowCount = get(result, "data.advancedDropDailyStatsPage.total");
  const { rowValues, rows, selected } = useMemo(() => {
    const rowValues = mapValues(groupBy(results, "yyyymmdd"), mergeNumbers);
    const rows = Object.keys(rowValues || {});
    const selected = mapValues(rowValues, (value, key) =>
      moment.utc(fromYYYYMMDD(key)).isBetween(moment.utc(gte), moment.utc(lte))
    );
    return {
      rows,
      rowValues,
      selected
    };
  }, [results, gte, lte]);
  const handleClick = (countBy, rowKey) => {
    if (countBy) setCountBy(countBy);
    if (onClick) onClick(rowKey, rowValues[rowKey]);
  };
  const changePage = p => {
    if (!pageIdx.current[p]) {
      pageIdx.current[p] = Number(rows[rows.length - 1]);
    }
    setPage(p);
  };
  const handleChangePage = (event, newPage) => {
    changePage(newPage + 1);
  };
  const handleChangeRowsPerPage = event => {
    setPerPage(parseInt(event.target.value, 10));
    changePage(1);
  };
  const activeCellStyles = (name, rowKey) =>
    selected[rowKey] && name === countBy
      ? { backgroundColor: "rgba(50, 198, 136, 0.16)", cursor: "pointer" }
      : { cursor: "pointer" };
  return (
    <div className="mb-4 d-none d-md-block">
      <Table size="small">
        <TableHead>
          <TableRow>
            {/* <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ "aria-label": "select all desserts" }}
              />
            </TableCell> */}
            <TableCell>Date</TableCell>
            <TableCell>Drops</TableCell>
            <TableCell>Claimed</TableCell>
            <TableCell>Collections</TableCell>
            <TableCell>Link Clicks</TableCell>
            <TableCell>Conversions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows
            .sort((a, b) => b - a)
            .map(rowKey => (
              <TableRow
                role="checkbox"
                key={rowKey}
                aria-checked={selected[rowKey] || false}
                selected={selected[rowKey] || false}
                tabIndex={-1}
                hover
              >
                {/* <TableCell padding="checkbox">
                  <Checkbox
                    checked={selected[rowKey] || false}
                    inputProps={{ "aria-labelledby": rowKey }}
                  />
                </TableCell> */}
                <TableCell
                  component="th"
                  id={rowKey}
                  scope="row"
                  padding="none"
                  onClick={() => handleClick("", rowKey)}
                >
                  {fromYYYYMMDD(rowKey).toLocaleDateString()}{" "}
                </TableCell>
                <TableCell
                  onClick={() => handleClick("count", rowKey)}
                  style={activeCellStyles("count", rowKey)}
                >
                  {get(rowValues, `${rowKey}.count`) || 0}
                </TableCell>
                <TableCell
                  onClick={() => handleClick("claimed", rowKey)}
                  style={activeCellStyles("claimed", rowKey)}
                >
                  {get(rowValues, `${rowKey}.claimed`) || 0}
                </TableCell>
                <TableCell
                  onClick={() => handleClick("collected", rowKey)}
                  style={activeCellStyles("collected", rowKey)}
                >
                  {get(rowValues, `${rowKey}.collected`) || 0}
                </TableCell>
                {/* <TableCell
                  onClick={() => handleClick("people", rowKey)}
                  style={activeCellStyles("people", rowKey)}
                >
                  {get(rowValues, `${rowKey}.people`) || 0}
                </TableCell> */}
                <TableCell
                  onClick={() => handleClick("linkClicked", rowKey)}
                  style={activeCellStyles("linkClicked", rowKey)}
                >
                  {get(rowValues, `${rowKey}.linkClicked`) || 0}
                </TableCell>
                <TableCell
                  onClick={() => handleClick("converted", rowKey)}
                  style={activeCellStyles("converted", rowKey)}
                >
                  {get(rowValues, `${rowKey}.converted`) || 0}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {remoteRowCount ? (
        <TablePagination
          component="div"
          rowsPerPageOptions={[10]}
          count={remoteRowCount}
          rowsPerPage={perPage}
          page={page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        ></TablePagination>
      ) : null}
    </div>
  );
};
