import React from "react";

export const StatsGraphic = ({ className }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 784.4 733.6"
      className={className}
    >
      <title>personal_finance</title>
      <path
        fill="#FFB8B8"
        d="M624.7,521.2c0,0,97,5,74.4,35.6c-22.6,30.5-82-17.9-82-17.9L624.7,521.2z"
      />
      <path
        fill="#424242"
        stroke="#262523"
        strokeWidth="2px"
        d="M445.9,230.3l19.8,4.9c0,0,11.5,34.6,19.8,65.8s24.7,125.1,24.7,125.1l127.6,89.4l-15.2,34l-170-67
	l-37.9-148.7L445.9,230.3z"
      />
      <polygon
        opacity="0.2"
        enableBackground="new    "
        points="464.5,291.6 457.1,445.8 472.5,491.6 427.5,458.6 "
      />
      <path
        fill="#262523"
        d="M491.2,565.2c0,0,21.4,57.6,24.7,97.1c3.3,39.5,6.6,70.8,6.6,70.8H422l-19.8-29.6v29.6H285.4
	c0,0-32.9-107-19.8-120.2s138.3-69.1,138.3-69.1L491.2,565.2z"
      />
      <rect x="101.5" y="731.6" fill="#EE7268" width="581" height="2" />
      <circle fill="#FFB8B8" cx="365.3" cy="105.2" r="62.5" />
      <path
        fill="#FFB8B8"
        d="M317.5,131.5l-4.9,72.4l62.5,83.9l31.3-79c0,0-28-18.1-11.5-56L317.5,131.5z"
      />
      <path
        fill="#424242"
        stroke="#262523"
        strokeWidth="2px"
        d="M500.2,579.2c0,14.8-41.2,37.9-41.2,37.9v8.2l-16.5,8.2l-36.2-72.4l-9.9,79c0,0-72.4,11.5-95.5,0
	c-23-11.5-34.6-6.6-67.5-18.1s13.2-166.2,13.2-166.2l-32.9-238.7l71.9-36l3.8-1.9l9.9-1.8l15.2-2.8l50.7,34.2l4.1,21.4l2.5,13.2
	l13.2-14.8l9.9-32.7l70.7,39.3l-19.8,158C445.9,393.2,500.2,564.4,500.2,579.2z"
      />
      <path
        fill="#FFB8B8"
        d="M258.3,630.2c0,0,75.7,60.9,39.5,72.4s-56-62.5-56-62.5L258.3,630.2z"
      />
      <path
        fill="#424242"
        stroke="#262523"
        strokeWidth="2px"
        d="M230.3,220.4l-16.5-3.3c0,0-24.7,13.2-26.3,39.5s-23,210.7-23,210.7l69.1,186l56-21.4l-54.3-174.5L276.4,306
	L230.3,220.4z"
      />
      <circle fill="#262523" cx="372.5" cy="264.6" r="6" />
      <circle fill="#262523" cx="372.5" cy="303.6" r="6" />
      <circle fill="#2F2E41" cx="406.5" cy="560.6" r="6" />
      <path
        fill="#262523"
        d="M312.5,41.1l-11.6-4.6c0,0,24.2-26.6,57.8-24.3l-9.5-10.4c0,0,23.1-9.3,44.1,15
	c11.1,12.8,23.8,27.8,31.8,44.7h12.4l-5.2,11.4l18.1,11.4l-18.6-2c1.1,6.2,1.3,12.6,0.5,18.9c-0.8,6.3-4,12-8.9,16l0,0
	c0,0-14.3-29.7-14.3-34.3v11.6c0,0-11.6-10.4-11.6-17.4l-6.3,8.1h-42l6.3-10.4l-24.2,3.5l9.5-12.7l-37.4,52.6
	C303.4,117.9,282,58.4,312.5,41.1z"
      />
      <circle fill="#262523" cx="684.4" cy="383.5" r="100" />
      <circle
        fill="#424242"
        enableBackground="new    "
        cx="684.4"
        cy="383.5"
        r="86"
      />
      <path
        fill="#EE7268"
        d="M680.3,434.3v-12.5c-6.3-0.1-12.9-2.4-16.7-5.5l2.6-8.3c4.5,3.4,10,5.3,15.7,5.3c7.7,0,13-5.1,13-12.1
	c0-6.8-4.3-11-12.3-14.7c-11.1-5-18-10.7-18-21.4c0-10.3,6.4-18.1,16.5-20v-12.5h6.8v12c5,0.1,10,1.6,14.2,4.3l-2.7,8.2
	c-4-2.8-8.8-4.2-13.7-4.2c-8.4,0-11.6,5.7-11.6,10.7c0,6.4,4,9.7,13.5,14.1c11.2,5.2,16.9,11.6,16.9,22.7c0,9.8-6,19-17.2,21.1v12.9
	H680.3z"
      />
      <polygon
        opacity="0.2"
        enableBackground="new    "
        points="250,504.9 290,629.8 258,499.8 "
      />
      <path
        fill="#424242"
        stroke="#262523"
        strokeWidth="2px"
        d="M46.1,260.8H20.7c-2.3,0-4.2-1.9-4.2-4.2v-36.5c0-2.3,1.9-4.2,4.2-4.2h25.4c2.3,0,4.2,1.9,4.2,4.2v36.5
	C50.3,258.9,48.4,260.8,46.1,260.8z M20.7,218c-1.2,0-2.2,1-2.2,2.2v36.5c0,1.2,1,2.2,2.2,2.2h25.4c1.2,0,2.2-1,2.2-2.2v-36.5
	c0-1.2-1-2.2-2.2-2.2H20.7z"
      />
      <path
        fill="#424242"
        stroke="#262523"
        strokeWidth="2px"
        d="M92.5,260.8H69.5c-3,0-5.4-2.4-5.4-5.4v-73.7c0-3,2.4-5.4,5.4-5.4h22.9c3,0,5.4,2.4,5.4,5.4v73.7
	C97.9,258.4,95.4,260.8,92.5,260.8z M69.5,178.3c-1.9,0-3.4,1.5-3.4,3.4v73.7c0,1.9,1.5,3.4,3.4,3.4h22.9c1.9,0,3.4-1.5,3.4-3.4
	v-73.7c0-1.9-1.5-3.4-3.4-3.4L69.5,178.3z"
      />
      <path
        fill="#424242"
        stroke="#262523"
        strokeWidth="2px"
        d="M139.2,260.8H118c-3.5,0-6.3-2.8-6.3-6.3V146.1c0-3.5,2.8-6.3,6.3-6.3h21.2c3.5,0,6.3,2.8,6.3,6.3v108.4
	C145.5,258,142.6,260.8,139.2,260.8z M118,141.8c-2.4,0-4.3,1.9-4.3,4.3v108.4c0,2.4,1.9,4.3,4.3,4.3h21.2c2.4,0,4.3-1.9,4.3-4.3
	V146.1c0-2.4-1.9-4.3-4.3-4.3H118z"
      />
      <rect x="23.9" y="222.9" fill="#EE7268" width="19" height="30.9" />
      <rect x="71.5" y="183.6" fill="#EE7268" width="19" height="70.2" />
      <rect x="119.1" y="147.1" fill="#EE7268" width="19" height="106.7" />
      <path
        fill="#424242"
        stroke="#262523"
        strokeWidth="2px"
        d="M723,145.8H590c-0.6,0-1-0.4-1-1c0,0,0,0,0,0V9.8c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1v134h132c0.6,0,1,0.4,1,1
	S723.6,145.8,723,145.8z"
      />
      <path
        fill="#424242"
        stroke="#262523"
        strokeWidth="2px"
        d="M159,274.8H1c-0.6,0-1-0.4-1-1c0,0,0,0,0,0v-157c0-0.6,0.4-1,1-1s1,0.4,1,1v156h157c0.6,0,1,0.4,1,1
	C160,274.4,159.6,274.8,159,274.8z"
      />
      <path
        fill="#424242"
        stroke="#262523"
        strokeWidth="2px"
        d="M635.9,133.3h-25.4c-2.3,0-4.2-1.9-4.2-4.2V92.6c0-2.3,1.9-4.2,4.2-4.2h25.4c2.3,0,4.2,1.9,4.2,4.2v36.5
	C640.1,131.4,638.2,133.3,635.9,133.3z M610.5,90.4c-1.2,0-2.2,1-2.2,2.2v36.5c0,1.2,1,2.2,2.2,2.2h25.4c1.2,0,2.2-1,2.2-2.2V92.6
	c0-1.2-1-2.2-2.2-2.2H610.5z"
      />
      <path
        fill="#424242"
        stroke="#262523"
        strokeWidth="2px"
        d="M687.9,133.3h-23.4c-2.9,0-5.2-2.3-5.2-5.2V62c0-2.9,2.3-5.2,5.2-5.2h23.4c2.9,0,5.2,2.3,5.2,5.2v66.1
	C693.1,130.9,690.7,133.3,687.9,133.3z M664.5,58.8c-1.8,0-3.2,1.4-3.2,3.2v66.1c0,1.8,1.4,3.2,3.2,3.2h23.4c1.8,0,3.2-1.4,3.2-3.2
	V62c0-1.8-1.4-3.2-3.2-3.2H664.5z"
      />
      <rect x="613.2" y="95.7" fill="#EE7268" width="19" height="30.9" />
      <rect x="666.7" y="65.1" fill="#EE7268" width="19" height="59.9" />
      <circle fill="#EE7268" cx="623" cy="68.8" r="9" />
      <circle fill="#EE7268" cx="676" cy="34.8" r="9" />
    </svg>
  );
};
