import React from "react";

export const StandOutGraphic = ({ className }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 959.3 686"
      className={className}
    >
      <title>stand_out</title>
      <path
        opacity="0.5"
        fill="#262523"
        d="M747.1,311.1c-1.8-4.6,0.8-6.9,1.8-11s-2.3-16.9-2.3-16.9s-10.8-44.3-14.1-53
	c-1.4-3.7-4.5-6.2-7.5-7.9c-0.2-1.1-0.5-1.9-0.9-2.1c-3-1.5-10.4-4.7-15.3-5.6c-0.9-1.6-2-3.1-3.3-4.4c0.9-1.7,1.3-3.5,1.2-5.4
	c-0.3-4.8-4-9.2-3.5-14c0.4-3.7,3-6.7,4.1-10.2c0.9-3.2,0.4-6.6-1.4-9.4c0.8,0.8,1.5,1.6,2.3,2.4c2.1-3.2,1.9-7.4-0.6-10.3
	c-2.4-2.7-6.4-3.6-8.3-6.7c-1.9-3.1-0.9-7.4-2.7-10.6c-4.4-7.8-15.8-2.3-22.6-5c-2.8-1.1-4.9-2.4-8.1-2.3c-2.7,0.2-5.3,0.6-7.9,1.5
	c-3.7,1.1-6.8,3.3-9,6.5c0.2-0.2,0.5-0.5,0.7-0.7c1.6-1.3,4.5-1.2,5.4,0.7c0.2,0.5,0.3,1,0.3,1.5c-0.1,2.3-2.1,4-4.1,5.2
	s-4.2,2.1-5.5,4c-2.1,3.1-0.9,7.3-0.9,11.1c0,4.9-2.3,9.5-6.1,12.5l7.2-3c-3.5,6-5.7,12.7-6.7,19.6l9.8-8.1
	c-3.6,5.8-5.8,12.3-6.4,19.1l7.8-8c0.4,0.6,0.5,1.3,0.5,2.1l-0.8,3.2c-0.3,0.6-0.6,1.2-1,1.8c-1.3,2.2-2.6,4.8-1.4,7.1l0,0
	c-1.2,4.2-3.1,8.4-7.3,10.4c4.6,0.5,9.5-1.8,11.5-5.5c0.3,0.7,0.8,1.3,1.2,1.9c0.1,0.2,0.3,0.3,0.4,0.5l-1.2,0.8l-3.1,2.1
	c0,0-20.2,4.1-19,11.8c0.1,0.3,0.1,0.7,0.2,1.1l-5.8,9.9l-8.5,28.7c0,0-4.4,9.2-3.6,13.1s0,5.4,0,12.3s-3.3,9.7,0,15.4
	s13.3,22,13.3,22l2.2,1.6c-0.1,1-0.1,2,0.1,3c2,10.3,21.3,108.4,21.3,108.4s-9.7,77.6,10,82.5c2.5,0.6,4.9,1.2,7.3,1.7l3,19.6
	l-1.8,24.9c0,0-13.6,30.8,11.3,32.5s13.6-26.7,13.6-26.7c1.3-2.4,1.8-5.2,1.5-7.9c-0.3-2.3-2.4-14.2-4.5-25.6
	c9.4-1.1,7.7-11.1,6.4-15.5c0.5-0.3,1-0.8,1.1-1.4c0.3-1-0.4-5-1.6-10.7c-1-5.6-2.6-14.7-3.8-20.8l6.1,1l25.9-151.7
	c0,0,0.2-2.4,0.1-5.9c3.5-1.8,7.2-3.4,10.9-4.8c8.5-3.3,11.8-15.6,14.6-20.8S748.9,315.7,747.1,311.1z M655.2,214.5
	c0.8-0.6,1.8-0.9,2.8-1c-0.2,1.2-2.3,1.5-4,2.2C654.3,215.2,654.7,214.8,655.2,214.5L655.2,214.5z M642.3,290.8
	c2.1,3.4-1.7,12.7-5.1,20c-1.9-1.7-4-1.1-1.6-5.9c1.6-3,2.6-6.3,2.8-9.7c0-0.6,0.3-4.9,2.8-6.7C641.5,289.3,641.8,290.1,642.3,290.8
	L642.3,290.8z M634.5,320.3c1.2-0.5,3-1.2,5.4-2c-1.2,1.5-2.5,3.3-3.8,5.3C635,322.7,634.4,321.5,634.5,320.3L634.5,320.3z
	 M717.6,323.6c-1.6-1.7-2.8-3.1-3.9-4.5c1.9,0.5,3.9,1.1,5.9,1.6c1,0.1,2,0.1,3-0.2c0.1,1.8,0.1,4.3,0.1,4.3l-2.8,1.9
	C719.3,325.7,718.5,324.6,717.6,323.6L717.6,323.6z M725.5,314.7C725.5,314.8,725.5,314.8,725.5,314.7c-0.2-0.9-0.5-1.9-0.8-2.9
	c-1.7-5.3-2.6-14.7-3.1-22.7l4.8,16.3C726.6,305.4,726.8,313.6,725.5,314.7L725.5,314.7z"
      />
      <path
        opacity="0.5"
        fill="#262523"
        d="M249.9,262.6l4.4-1l-18.1-50.9l-39-14.4c0,0-0.1,0.2-0.4,0.5c-3.3-3.2-4.7-7.9-5.3-11.6
	c11.8-4.8,17.5-18.2,12.7-29.9c-0.5-1.3-1.2-2.6-2-3.8c3.2-0.8,4.4-3.7,5.4-7.2c-0.7-0.2-1.3-0.3-2-0.4c0.5-1.1,1-2.3,1.5-3.4
	c-0.9-0.1-1.8-0.2-2.7-0.2c0.2-1.1,0.5-2.1,0.7-3.2c-1.1,0.8-2.3,1.6-3.4,2.3c0.8-2.3,0.3-4.8-1.2-6.6c-1.5-1.8-3.5-3.2-5.7-4
	c-8.8-3.5-18.9-1.7-26,4.6l-0.2-1c-6.9,5.3-11.5,13.2-12.6,21.9c-1.1,8.6,3,17.3,8.4,24.1c0-0.3-0.1-0.6-0.1-0.9
	c0.2,0.3,0.5,0.6,0.7,0.9l0.7,25l-1.6,1.4c0,0-27.7,12.5-28.7,27.5l0,0c-1.7,7.3-2.5,45.1-2.5,45.1h2.3l-2.3,14.8l-14.8,57.5
	c0,0-18.9,24.6-2.5,29.6c10.2,3.1,13.1-8.8,13.9-18.2c0.6,2.3,2.4,3.9,5.9,4.2c0.3,0,0.6,0,0.9,0.1c-7.8,20.1-2.5,85.4-2.5,85.4
	c-4.1,8.2,44.4,64.9,44.4,64.9l2.9-1.6l0.6,1.3c-1.6,0.2-2.7,0.3-2.7,0.3v25.5c0,0-7.4,25.5,2.5,30.4c2.6,1.3,5.2,0.3,7.8-1.7
	c-0.5,7.9-1.2,19.6-1.2,22.3c0,4.1,10.7,11.5,10.7,11.5s2.5,9,22.2,9s4.9-23.8,4.9-23.8l-10.7-23.8c0,0-0.8,0.4-3.2-1.6l0.9-9.5
	l4.8,0.4l6.6-96.1c0,0,5.8-85.4,6.6-98.6c0.2-4.2,0.1-8.4-0.3-12.6l24.1-49.8L249.9,262.6z M232.1,294.5l-2.5,8.6v-19L232.1,294.5z
	 M129.5,355.4c0-3-0.1-5.1-0.1-5.1l16.1-32.1l-0.5,9.9C145,328.2,132.1,344.4,129.5,355.4z M167.2,448.1l15.6-55l9,66.5l-0.6,24.2
	c-1.1-2.2-2-4.5-2.6-6.9C186.1,467,167.2,448.1,167.2,448.1z"
      />
      <path
        opacity="0.5"
        fill="#262523"
        d="M389.5,578.9c-7.7-3.2-22.6-10-24.5-13.6c-1.8-3.6-10.9-31.7-10.9-31.7l-2.3,0.2
	c-0.3-12.8-1.6-62.2-3.6-68.1c0,0,9.5-78.3-9.5-109.6l-3.4-13.8c1.1-5.5,1-18.4,0.3-20.6c-0.9-2.7,10.4-11.3,7.2-16.8
	s-11.8-24-11.8-24s0.9-11.8-11.3-22.6c0,0-3.6-20.2-19.7-20.5c-2.2-3.8-5.2-9.8-4.3-12.5c1-2.9,4.6-11.9,6.7-16.9
	c1.1-0.5,2.2-1.1,3.2-1.8c12.8-5.7,21.8-19.5,21.8-35.6c0-17.2-10.3-31.8-24.6-36.7c2.4-6.3-0.8-13.4-7.1-15.8
	c-6.3-2.4-13.4,0.8-15.8,7.1c-0.5,1.4-0.8,2.8-0.8,4.3c-2.6-6.2,0.3-13.4,6.5-16c1.5-0.6,3.1-1,4.8-1c0.8,0,1.6,0.1,2.4,0.2
	c-6.1-2.9-13.4-0.3-16.3,5.8c-2.4,5-1.1,11,3.2,14.6c0.1,0.4,0.3,0.9,0.5,1.3c-13.4,5.4-23,19.5-23,36.1c0,15.4,8.2,28.7,20.1,34.8
	c-0.1,5-1.3,13.4-8.2,18.6c-3.6-1.6-8.5-3.4-11.7-2.6c-1.6-1.3-4.1-1.7-7.5,1.7c-6.8,6.8-12.7,24.5-12.7,35.3s1.4,73.8,1.4,73.8
	s-7.2,2.7-2.3,6.8c1,0.9,2.3,1.3,3.7,1.4l4.9,36.7c0,0-3.2,35.3,9.1,35.8c1,0.1,1.9-0.2,2.7-0.7l-9.1,41c0,0-20.8,13.6-37.1,40.3
	c-8.4,13.8-12.6,22.2-14.7,27.2l-3-1.9L182.6,539c0,0-23.1,14-18.1,24.5s9.5,13.1,9.5,13.1l3.2-1.4l5.9,10.9
	c0,0,15.4,22.2,40.3,10.9c2.4-3.2,2.4-7.6,0-10.9c-4.1-5.9-14.5-18.6-14.5-18.6l6.8-34.4l-1.4-0.9l43.1-52.1c0,0,12.7-7.7,17.2-14.5
	s32.6-77,32.6-77l15.8,73.4c0,0-0.9,26.7,3.2,43.9c2.2,9.7,3.7,19.5,4.3,29.3l-1.2,0.1l2.7,20.4c0,0-11.8,29-3.6,31.2
	c5.8,1.5,11.7,2.6,17.7,3.2l0.9-3.2c0,0,34.9,19,47.5,5C394.5,592,397.2,582,389.5,578.9z M265.4,356.5c1.4,1.4,0.9,7.7,0.9,7.7
	l-2.7,17.7l-2.7,12.2c-0.4-5.8-1.1-11.5-2.3-17.2l-1.3-33.8c0.2,0.2,0.5,0.3,0.8,0.3c0.9,0,0.5-7.7,0.5-7.7s0.5-1.4,1.2-3.5
	c1.8,5.3,3.9,8.7,6.5,9.4l0.5,5.9c0,0-5.9,2.7-0.5,2.7c0.3,0,0.6-0.1,0.9-0.1l-0.5,2.4C266.8,352.5,264.1,355.2,265.4,356.5z"
      />
      <ellipse fill="#424242" cx="381.8" cy="594" rx="364" ry="6" />
      <ellipse fill="#424242" cx="509.2" cy="609.5" rx="400.4" ry="15.5" />
      <ellipse fill="#424242" cx="435.3" cy="632.5" rx="196.5" ry="15.5" />
      <ellipse fill="#424242" cx="719.3" cy="651" rx="87.5" ry="8" />
      <ellipse fill="#424242" cx="599.3" cy="678" rx="41.5" ry="8" />
      <ellipse fill="#424242" cx="261.3" cy="644" rx="41.5" ry="8" />
      <polygon
        fill="#FFB8B8"
        points="466.3,553.5 470.3,581.5 490.3,579.5 488.3,550.5 "
      />
      <polygon
        fill="#FFB8B8"
        points="371.3,553.5 367.3,581.5 347.3,579.5 349.3,550.5 "
      />
      <path
        fill="#262523"
        d="M342.3,263.5c0,0-18,21-14,54s11,108,11,108l5,133l32-2l5-113l16-91l43,106c0,0,21,99,22,99s30-3,30-3
	l-14-117c0,0-22-170-35-173S342.3,263.5,342.3,263.5z"
      />
      <path
        opacity="0.5"
        fill="#262523"
        d="M626,247.4c0,0-26.3-45.2-27.1-50.1s-14-14-14-14c-4.5-4.5-11.9-5.9-19.6-5.8
	c2.9-2.4-7.5-4-8.3-5.7c-0.8-1.6-3.3-1.6-6.6-4.1c-3.3-2.5-11.5-0.4-11.5-0.4c-1.2-2.6-2.1-5.4-2.5-8.2c3.5-4.1,5.4-9.4,5.4-14.8
	c0-0.1,0-0.1,0-0.2c0.2,0.3,0.4,0.7,0.6,1c1.5-9.2,4.3-16.9-0.9-25.3c-3.7-6-9.4-10.4-16.1-12.3l-4.9,3.9l1.9-4.6
	c-1.6-0.2-3.2-0.3-4.9-0.3l-4.4,5.7l1.8-5.5c-7.8,1-14.8,5.1-19.4,11.5c-5.6,8.1-5.3,19.5,0.3,27.7c0-0.1,0-0.1,0.1-0.2
	c0.5,7.8,4.9,14.8,11.8,18.6l1.4,11.2l0,0c-1.3,1.2-2.9,1.9-4.6,2c-2.5,0-4.9,6.6-4.9,6.6s-1.4,0.7-2.9,4
	c-3.9,2.6-15.7,10.9-20.1,16.7c-2.6,1.3-5.4,4.1-4.9,10.5c0.8,11.5,0,64.9,0,64.9s-7.4,45.2-4.9,48.5s-0.8,14-0.8,14l2.5,0.5v2.7
	c0,0-7.4,34.4,4.1,32.9c18.9-2.5,4.9-30.4,4.9-30.4v-3.2l3.3,0.7c0,0,1.6-11.5,4.9-12.3c1.1-0.3,2.5-3.4,3.7-7.2
	c0.7,6.1,1.2,9.7,1.2,9.7l5.1-1.1l1.4,5.3c0.4,0.2,0.7,0.4,1.1,0.5l-1.1,6.1c0,0-0.8,6.6,0,10.7c0.4,2.1,15.7,46.4,30.7,89.7
	l-0.3,10.5c0,0-7.4,8.2-5.8,14s-4.1,53.4-4.1,53.4l-4.9,23.8l1.2,0.4l-1.1,4.2c-1,0.5-1.9,1.2-2.6,2c-6.6,7.4-14.8,28.8-14.8,28.8
	s-14.8,22.2,8.2,21.4s24.6-9,24.6-9s3.3-21.4,3.3-22.2s-1.6-9.3-1.6-9.3l-0.6-0.7l0.4-10.2l4.3,1.3c0,0,4.1-21.4,7.4-23.8
	c1.1-0.9,4.2-10.5,7.7-22.8c3.6,10.3,7,45.8,8.1,49c-0.4,0.5-0.7,1.1-1,1.7c-3.7,7.1-7.8,13.9-12.3,20.5c0,0-26.3,15.6,0,19.7
	c0,0,14.8,0.8,22.2-4.1s27.1-12.3,27.1-14s-4.9-25.8-9.9-23.2l0,0L580,551l3.3-1.3l-20.8-93.4c0.5-2,0.9-4,1.1-6
	c0-4.3,10.6-88.1,13.3-109.3c11.9,3.4,22.8-21.3,22.8-21.3l2.7-4.9l3.9,2.4c0,0,3.3-8.2,8.2-9s14-44.4,14-50.1
	C628.4,254.4,627.6,250.7,626,247.4z M587.7,311.8c-0.8-1.3-1.7-2.5-2.8-3.6c2.1-5.6,3.8-11.4,4.9-17.3c1.6-9-2.5-38.6-2.5-38.6
	l-0.1-2c4,5.3,9.1,12.4,9.1,14.3c0,1.9,0.9,3.8,2.5,4.9l-4.9,17.3c0,0-3.3,8.2-4.1,12.3c-0.5,2.4-0.8,4.9-0.8,7.4l1.2,0.7
	L587.7,311.8z"
      />
      <path
        fill="#262523"
        d="M492.3,575.5c0,0-18-12-25,2s-1,24-1,24s6,4,3,8s-4,30,22,26s13-31,13-31L492.3,575.5z"
      />
      <path
        fill="#262523"
        d="M345.3,575.5c0,0,18-12,25,2s1,24,1,24s-6,4-3,8s4,30-22,26s-13-31-13-31L345.3,575.5z"
      />
      <path
        fill="#FFB8B8"
        d="M366.8,69c0,0,9,25,2,39s11,30,11,30s47-24,41-36s-16-28-14-38L366.8,69z"
      />
      <circle fill="#FFB8B8" cx="384.3" cy="46.5" r="32" />
      <path
        fill="#EE7268"
        d="M361.3,106.5c0,0,32.1,26.7,56.1-11.1l24.9,14.1l13,152c0,0-73,42-108,6l5-141L361.3,106.5z"
      />
      <polygon
        fill="#424242"
        stroke="#262523"
        strokeWidth="2px"
        points="371.4,91.5 326.3,121.5 342.3,195.5 321.3,310.5 355.3,259.5 "
      />
      <path
        fill="#424242"
        stroke="#262523"
        strokeWidth="2px"
        d="M411.2,83.1l11.1,7.3l45,16l-7,124l19,80l-27,14c0,0-5-38-36-57S404.1,88.8,411.2,83.1z"
      />
      <path
        fill="#FFB8B8"
        d="M336.3,277.5l13,9c0,0-16.5,17.5-19.5,14.5c-1.8-1.7-2.7-4.1-2.5-6.5l-3-7L336.3,277.5z"
      />
      <path
        fill="#FFB8B8"
        d="M422.7,275.5l-4.4,6.9c0,0-13,6-10,10s28,13,33,4l5-9L422.7,275.5z"
      />
      <path
        fill="#424242"
        stroke="#262523"
        strokeWidth="2px"
        d="M332.3,120.5l-7,1L313.8,196c0,0-4.5,13.5,0.5,45.5s10,52.8,10,52.8l22-15.8l-10-26l13-95L332.3,120.5z"
      />
      <path
        fill="#424242"
        stroke="#262523"
        strokeWidth="2px"
        d="M451.3,104.5l16,2c0,0,18,19,21,39s2,77,2,77l-46,73l-25-18l35-62l-17-74L451.3,104.5z"
      />
      <path
        fill="#262523"
        d="M414.8,17.5c-4.8-7.9-12.4-13.6-21.3-16.3L387,6.5l2.5-6.1c-2.1-0.3-4.3-0.4-6.4-0.4l-5.8,7.5l2.4-7.3
	c-10.3,1.3-19.5,6.8-25.5,15.2c-7.4,10.7-8.7,25.7-1.4,36.5c2-6.2,4.5-12,6.5-18.2c1.6,0.2,3.2,0.2,4.8,0l2.4-5.7l0.7,5.5
	c7.6-0.7,18.8-2.1,26-3.4l-0.7-4.2l4.2,3.5c2.2-0.5,3.5-1,3.4-1.3c5.3,8.6,10.6,14.1,16,22.7C418,38.7,421.6,28.6,414.8,17.5z"
      />
      <ellipse fill="#FFB8B8" cx="413" cy="45.5" rx="2.2" ry="4.3" />
      <path
        fill="#EE7268"
        d="M759.1,611c0.9,31.6-17.5,43.1-40.7,43.8c-0.5,0-1.1,0-1.6,0c-1.1,0-2.1,0-3.2-0.1
	c-20.9-0.9-37.5-12-38.4-41.3c-0.9-30.4,36.8-69.8,39.6-72.8l0,0c0.1-0.1,0.2-0.2,0.2-0.2S758.2,579.5,759.1,611z"
      />
      <path
        fill="#3F3D56"
        d="M716.8,650.1l14.7-21.9l-14.7,24.2l0,2.5c-1.1,0-2.1,0-3.2-0.1l0.7-31.6l0-0.2l0,0l0.1-3l-16.1-23.4
	l16.1,21.1l0.1,0.6l0.6-23.9l-13.9-24.2l13.9,20l-0.1-49.5l0-0.2l0,0.2l0.9,39l12.7-15.8l-12.6,19.2l0.3,21.4l11.7-20.9l-11.6,24
	l0.2,11.9l17-29.1l-16.9,33.2L716.8,650.1z"
      />
      <path
        fill="#EE7268"
        d="M223.1,564c0.9,31.6-17.5,43.1-40.7,43.8c-0.5,0-1.1,0-1.6,0c-1.1,0-2.1,0-3.2-0.1
	c-20.9-0.9-37.5-12-38.4-41.3c-0.9-30.4,36.8-69.8,39.6-72.8l0,0c0.1-0.1,0.2-0.2,0.2-0.2S222.2,532.5,223.1,564z"
      />
      <path
        fill="#3F3D56"
        d="M180.8,603.1l14.7-21.9l-14.7,24.2l0,2.5c-1.1,0-2.1,0-3.2-0.1l0.7-31.6l0-0.2l0,0l0.1-3l-16.1-23.4
	l16.1,21.1l0.1,0.6l0.6-23.9l-13.9-24.2l13.9,20l-0.1-49.5l0-0.2l0,0.2l0.9,39l12.7-15.8l-12.6,19.2l0.3,21.4l11.7-20.9l-11.6,24
	l0.2,11.9l17-29.1l-16.9,33.2L180.8,603.1z"
      />
      <path
        fill="#EE7268"
        d="M623.7,655.5c0.5,18.5-10.3,25.3-23.9,25.7c-0.3,0-0.6,0-0.9,0c-0.6,0-1.3,0-1.9,0c-12.3-0.5-22-7-22.5-24.3
	c-0.5-17.8,21.6-41,23.3-42.7l0,0c0.1-0.1,0.1-0.1,0.1-0.1S623.1,637,623.7,655.5z"
      />
      <path
        fill="#3F3D56"
        d="M598.8,678.4l8.6-12.8l-8.6,14.2l0,1.4c-0.6,0-1.3,0-1.9,0l0.4-18.6l0-0.1l0,0l0-1.8L588,647l9.4,12.4l0,0.4
	l0.3-14l-8.2-14.2l8.2,11.8l-0.1-29.1l0-0.1l0,0.1l0.5,22.9l7.4-9.3l-7.4,11.3l0.2,12.5l6.8-12.2l-6.8,14.1l0.1,7l10-17.1l-9.9,19.5
	L598.8,678.4z"
      />
      <path
        fill="#2F2E41"
        d="M959.6,51.2c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0.1,0,0.1L959.6,51.2z"
      />
      <path
        fill="#262523"
        d="M819.8,581.8l74.1-110.1l-73.9,122l0.2,12.4c-5.4,0-10.8,0-16.1-0.3l3.7-159.3l-0.1-1.2l0.1-0.2l0.4-15.1
	L727,312.2l81,106.5l0.3,3.2l2.8-120.4L741,179.4l70.3,101L810.5,31l0-0.8l0,0.8l4.7,196.5l63.9-79.8l-63.6,96.8l1.4,107.7
	L875.6,247L817,367.9l0.8,59.9l85.4-146.4l-85.2,167.3L819.8,581.8z"
      />
      <path
        fill="#262523"
        d="M63.5,577.5l50.4-75l-50.3,83l0.1,8.4c-3.7,0-7.3,0-10.9-0.2l2.5-108.4l-0.1-0.8l0.1-0.2l0.2-10.2L0.4,394.1
	l55.1,72.5l0.2,2.2l1.9-81.9L9.9,303.7l47.8,68.7l-0.5-169.7l0-0.6l0,0.6l3.2,133.7l43.4-54.3L60.5,348l0.9,73.3l40-71.5L61.6,432
	l0.5,40.7l58.1-99.6l-58,113.8L63.5,577.5z"
      />
    </svg>
  );
};
