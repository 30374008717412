export function localStore<V>(key: string) {
  const set = safe((value: V) => {
    localStorage.setItem(key, JSON.stringify(value));
    return value;
  });
  const get = safe(() => {
    const value = localStorage.getItem(key);
    if (!value) return null;
    return JSON.parse(value) as V;
  });
  const rm = safe(() => {
    localStorage.removeItem(key);
    return null;
  });
  return { set, get, rm };
}

export function safe<T extends (...args: any) => any>(fn: T) {
  return function(...args: Parameters<typeof fn>): ReturnType<T> | null {
    try {
      return fn(...args);
    } catch (e) {
      return null;
    }
  };
}
