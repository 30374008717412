import React, { FC } from "react";

export const SuccessContent: FC = ({ children }) => {
  return (
    <div className="text-center">
      <i
        className="fa fa-check-circle text-success p-3 mt-4"
        style={{ fontSize: 60 }}
      />
      {children}
    </div>
  );
};

export const ErrorContent: FC = ({ children }) => {
  return (
    <div className="text-center">
      <i
        className="fa fa-times-circle text-danger p-3 mt-4"
        style={{ fontSize: 60 }}
      />
      {children}
    </div>
  );
};
