import React, {
  FC,
  useReducer,
  Dispatch,
  useContext,
  createContext,
  useEffect,
  useRef,
  useMemo
} from "react";

export type IAsyncState = ReturnType<typeof asyncState>;
export type IAsyncControls = ReturnType<typeof bindAsyncControls>;
export type IBooleanControls = ReturnType<typeof bindBooleanControls>;
export type Action<V = any> = { type: string; payload: V };
export type AsyncCb<A = any, V = any> = (
  args: A
) => Promise<V | null> | V | null;

const SET_TRUE = "SET_TRUE";
const SET_FALSE = "SET_FALSE";
const TOGGLE = "TOGGLE";
const SET_LOADING = "SET_LOADING";
const SET_DATA = "SET_DATA";
const SET_ERROR = "SET_ERROR";
const ASYNC_START = "ASYNC_START";
const ASYNC_SUCCESS = "ASYNC_SUCCESS";
const ASYNC_ERROR = "ASYNC_ERROR";

export function asyncState<V>(
  loading: boolean = false,
  data: V | null = null,
  error: string | null = null
) {
  return {
    loading,
    data,
    error
  };
}

export function booleanReducer(state = false, { type }: Action) {
  switch (type) {
    case SET_TRUE:
      return true;
    case SET_FALSE:
      return false;
    case TOGGLE:
      return !state;
    default:
      return state;
  }
}

export function asyncReducer<V>(
  state = asyncState<V>(),
  { type, payload }: Action<V>
) {
  switch (type) {
    case SET_LOADING:
      return asyncState(payload as any, state.data, state.error);
    case SET_DATA:
      return asyncState(state.loading, payload, state.error);
    case SET_ERROR:
      return asyncState(state.loading, state.data, payload as any);
    case ASYNC_START:
      return asyncState(true, null, null);
    case ASYNC_SUCCESS:
      return asyncState(false, payload, null);
    case ASYNC_ERROR:
      return asyncState(false, null, payload as any);
    default:
      return state;
  }
}

export function bindBooleanControls(dispatch: Dispatch<any>) {
  return {
    setTrue: bindAction<void>(dispatch, SET_TRUE),
    setFalse: bindAction<void>(dispatch, SET_FALSE),
    toggle: bindAction<void>(dispatch, TOGGLE)
  };
}

export function bindAsyncControls<V>(dispatch: Dispatch<any>) {
  return {
    setLoading: bindAction<boolean>(dispatch, SET_LOADING),
    setData: bindAction<V | null>(dispatch, SET_DATA),
    setError: bindAction<string | null>(dispatch, SET_ERROR),
    asyncStart: bindAction<void>(dispatch, ASYNC_START),
    asyncSuccess: bindAction<V>(dispatch, ASYNC_SUCCESS),
    asyncError: bindAction<string>(dispatch, ASYNC_ERROR)
  };
}

export function useBooleanControls(
  initial: boolean = false
): [boolean, IBooleanControls] {
  const [state, dispatch] = useReducer(booleanReducer, initial);
  const controls = bindBooleanControls(dispatch);
  return [state, controls];
}

export function useAsyncControls(
  initial = asyncState()
): [IAsyncState, IAsyncControls] {
  const [state, dispatch] = useReducer(asyncReducer, initial);
  const controls = bindAsyncControls(dispatch);
  return [state, controls];
}

export function useAsyncMethod<A, V>(
  callback: AsyncCb<A, V>
): [IAsyncState, AsyncCb<A, V>] {
  const mounted = useRef(true);
  const [state, controls] = useAsyncControls();
  async function handleCallback(args: A) {
    try {
      controls.asyncStart();
      const data = await callback(args);
      if (mounted.current) {
        controls.asyncSuccess(data);
      }
      return data;
    } catch (e) {
      if (mounted.current) {
        controls.asyncError(e.message);
      } else {
        console.warn("Component unmounted error", e.message);
      }
    }
    return null;
  }
  useEffect(
    () => () => {
      mounted.current = false;
    },
    []
  );
  return [state, handleCallback];
}

export function bindAction<V>(
  dispatch: any,
  type: string
): (v: V) => Action<V> {
  return payload => dispatch({ type, payload });
}

export function createBoundContext<V, C>(
  fn: (initial: V) => [V, C],
  initial: V
) {
  const Context = createContext<[V, C]>([initial, {} as C]);
  const Provider: FC = ({ children }) => {
    const value = fn(initial);
    return <Context.Provider value={value}>{children}</Context.Provider>;
  };
  return {
    Provider,
    useContext: () => useContext(Context)
  };
}
