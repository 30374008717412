import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import React, { createContext, useContext, useState, FC, useRef } from "react";
import { ScrollProvider } from "../Geodrops/TemplateForm";

interface Props {
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (ev: any, page: number) => number;
}

export function TablePaginationActions({
  count,
  page,
  rowsPerPage,
  onChangePage
}: Props) {
  function handleFirstPageButtonClick(event: any) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event: any) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event: any) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event: any) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div
      className="ml-2"
      style={{
        flexShrink: 0,
        maxWidth: "100%"
      }}
    >
      <IconButton
        className="d-none d-md-inline"
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        className="d-none d-md-inline"
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
}

const defaultContext = {
  page: 1,
  perPage: 12
};

export type IPaginationContext = typeof defaultContext;

export interface IPaginationActions {
  setPage: (n: number) => any;
  setPerPage: (n: number) => any;
  handleChangePage: (ev: any, n: number) => any;
  handleChangePerPage: (ev: any) => any;
}

export const TablePaginationContext = createContext<
  IPaginationContext & IPaginationActions
>({
  ...defaultContext,
  setPage: (n: number) => console.warn("Table context not initialized"),
  setPerPage: (n: number) => console.warn("Table context not initialized"),
  handleChangePage: (ev: any, n: number) =>
    console.warn("Table context not initialized"),
  handleChangePerPage: (ev: any) =>
    console.warn("Table context not initialized")
});

export const TablePaginationProvider: FC<{
  initial?: IPaginationContext;
}> = ({ children, initial = defaultContext }) => {
  const scrollRef = useRef<any>(null);
  const [page, setPage] = useState(initial.page || 1);
  const [perPage, setPerPage] = useState(initial.perPage || 1);
  const changePage = (p: number) => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
    setPage(p);
  };
  const handleChangePage = (event: any, newPage: number) => {
    changePage(newPage + 1);
  };
  const handleChangePerPage = (event: any) => {
    setPerPage(parseInt(event.target.value, 10));
    changePage(1);
  };
  return (
    <ScrollProvider value={scrollRef.current}>
      <TablePaginationContext.Provider
        value={{
          page,
          perPage,
          setPage,
          setPerPage,
          handleChangePage,
          handleChangePerPage
        }}
      >
        {children}
      </TablePaginationContext.Provider>
    </ScrollProvider>
  );
};

export const useTablePagination = () => useContext(TablePaginationContext);
