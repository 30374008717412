import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useRef
} from "react";
import mapValues from "lodash/mapValues";
import omitBy from "lodash/omitBy";
import set from "lodash/set";
import get from "lodash/get";

const FormContext = createContext();

export default ({ initial, onSubmit, onChange, id, children, validator }) => {
  const initialChanged = useRef(false);
  const [{ values, errors, touched }, setState] = useState({
    values: initial || {},
    errors: {},
    touched: {}
  });
  const handleSubmit = ev => {
    ev.preventDefault();
    const e = computeAllErrors({ values, errors, touched }, validator);
    if (Object.keys(e).length) {
      setState(s => ({ ...s, errors: e }));
      return;
    }
    onSubmit(values);
  };
  const handleChange = (event, value) => {
    const name = typeof event === "string" ? event : event.target.name;
    const text =
      value !== undefined
        ? value
        : event.target.type === "number"
        ? Number(event.target.value)
        : event.target.value;
    setState(s => {
      const values = { ...set(s.values, name, text) };
      const errors = computeTouchedErrors({ ...s, values }, validator);
      const ns = {
        ...s,
        values,
        errors
        // touched: { ...set(s.touched, name, true) },
      };
      if (onChange) {
        onChange(ns);
      }
      return ns;
    });
  };
  const handleBlur = event => {
    const name = event.target.name;
    setState(s => ({ ...s, touched: { ...set(s.touched, name, true) } }));
  };
  useEffect(() => {
    if (initial) {
      setState(s => ({ ...s, values: initial }));
    }
  }, [initial]);
  return (
    <FormContext.Provider
      value={{ values, errors, touched, handleChange, handleBlur }}
    >
      <form onSubmit={handleSubmit} id={id}>
        {typeof children === "function"
          ? children({ values, errors, touched, handleChange })
          : children}
      </form>
    </FormContext.Provider>
  );
};

export const FormField = ({
  component: Component,
  name,
  type,
  label,
  helperText,
  ...props
}) => {
  const { values, errors, handleChange, handleBlur } = useContext(FormContext);
  const options =
    type === "checkbox" || type === "radio"
      ? {
          checked: get(values, name) || false
        }
      : {
          value: get(values, name) || "",
          error: !!get(errors, name),
          helperText: get(errors, name) || helperText
        };
  return (
    <Component
      {...props}
      name={name}
      onChange={handleChange}
      onBlur={handleBlur}
      label={label}
      type={type}
      {...options}
    />
  );
};

const computeErrors = fn => (state, validator) =>
  validator
    ? omitBy(
        mapValues(validator, (f, n) => f && fn(state, f, n)),
        v => !v
      )
    : {};

const computeAllErrors = computeErrors((state, fn, name) => {
  return fn(get(state.values, name), state.values) || "";
});

const computeTouchedErrors = computeErrors((state, fn, name) => {
  if (get(state.errors, name) || get(state.touched, name)) {
    return fn(get(state.values, name), state.values) || "";
  }
  return "";
});
