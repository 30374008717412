import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import Hidden from "@material-ui/core/Hidden";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import get from "lodash/get";
import cx from "classnames";
import { getNumber } from "../../utils/number";

const SkeletonTypography = ({ loading, children, ...props }) => {
  return loading ? (
    <Skeleton
      variant="text"
      width={40}
      className="skeleton"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        margin: 0,
      }}
    />
  ) : (
    <Typography {...props}>{children}</Typography>
  );
};

const getDate = (v, str) => {
  const value = get(v, str);
  if (!value) return "";
  const date = new Date(value);
  return date;
};

export const AdvancedListDetails = ({ advancedDrop, loading }) => {
  const fq = get(advancedDrop, "frequency");
  return (
    <List disablePadding>
      <ListItem dense disableGutters>
        <ListItemText primary="Status" />
        <Hidden smDown>
          <SkeletonTypography
            variant="body2"
            loading={loading}
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              maxWidth: 140,
            }}
          >
            {statusTextShort(advancedDrop) || ""}
          </SkeletonTypography>
        </Hidden>
        <StatusIcon advancedDrop={advancedDrop} />
      </ListItem>
      <Divider />
      {get(advancedDrop, "rejectionReason") ? (
        <>
          <ListItem dense disableGutters>
            <ListItemText primary="Reason" />
            <SkeletonTypography variant="body2" loading={loading}>
              {get(advancedDrop, "rejectionReason")}
            </SkeletonTypography>
          </ListItem>
          <Divider />
        </>
      ) : null}
      <ListItem dense disableGutters>
        <ListItemText
          primary={get(advancedDrop, "startTime") ? "Start" : "Created"}
        />
        <SkeletonTypography variant="body2" loading={loading}>
          <ShortMobileDate
            value={
              get(advancedDrop, "startTime") || get(advancedDrop, "createdTime")
            }
          ></ShortMobileDate>
        </SkeletonTypography>
      </ListItem>
      <Divider />
      <ListItem dense disableGutters>
        <ListItemText primary="End" />
        <SkeletonTypography variant="body2" loading={loading}>
          <ShortMobileDate
            value={get(advancedDrop, "endTime")}
            defaultValue={advancedDrop ? "Ongoing" : ""}
          ></ShortMobileDate>
        </SkeletonTypography>
      </ListItem>
      <Divider />
      {get(advancedDrop, "executingFinished") ? (
        <>
          <ListItem dense disableGutters>
            <ListItemText primary="Last Execution" />
            <SkeletonTypography variant="body2" loading={loading}>
              <ShortMobileDate
                value={get(advancedDrop, "executingFinished")}
              ></ShortMobileDate>
            </SkeletonTypography>
          </ListItem>
          <Divider />
        </>
      ) : null}
      {getNumber(advancedDrop, "currentDailySpend") && (
        <>
          <ListItem dense disableGutters>
            <ListItemText
              primary={
                <span>
                  <strong>Current Daily Spend</strong>
                </span>
              }
            />
            <SkeletonTypography variant="body2" loading={loading}>
              {getNumber(advancedDrop, "currentDailySpend") || ""}
            </SkeletonTypography>
          </ListItem>
          <Divider />
        </>
      )}
      <ListItem dense disableGutters>
        <ListItemText
          primary={
            <span>
              <strong>Daily Budget</strong>
            </span>
          }
        />
        <SkeletonTypography variant="body2" loading={loading}>
          {/* {getNumber(advancedDrop, "currentDailySpend") || ""} /{" "} */}
          {getNumber(advancedDrop, "maxDailySpend") || ""}
        </SkeletonTypography>
      </ListItem>
      <Divider />
      {getNumber(advancedDrop, "currentSpend") && (
        <>
          <ListItem dense disableGutters>
            <ListItemText
              primary={
                <span>
                  <strong>Current Spend</strong>
                </span>
              }
            />
            <SkeletonTypography variant="body2" loading={loading}>
              {getNumber(advancedDrop, "currentSpend") || ""}
            </SkeletonTypography>
          </ListItem>
          <Divider />
        </>
      )}
      <ListItem dense disableGutters>
        <ListItemText
          primary={
            <span>
              <strong>Balance Remaining</strong>
            </span>
          }
        />
        <SkeletonTypography variant="body2" loading={loading}>
          {getNumber(advancedDrop, "currentBalance") || "0"}
        </SkeletonTypography>
      </ListItem>
      <Divider />
    </List>
  );
};

const ShortMobileDate = ({ value, defaultValue = "" }) => {
  const date = value && new Date(value);
  return date ? (
    <>
      <span className="d-md-none">{date.toLocaleDateString()}</span>
      <span className="d-none d-md-inline">{date.toLocaleString()}</span>{" "}
    </>
  ) : (
    <span>{defaultValue}</span>
  );
};

function statusTextShort(advancedDrop) {
  const text = statusText(advancedDrop);
  const begin = text.indexOf(": ");
  if (begin === -1) return text;
  const end = text.indexOf(".");
  if (end > begin) return text.slice(begin + 2, end + 1);
  return text.slice(begin + 2);
}

function statusText(advancedDrop) {
  const minDropAmount = advancedDropMinAmount(advancedDrop);
  return !advancedDrop
    ? ""
    : advancedDrop.status === "approved"
    ? advancedDrop.active
      ? advancedDrop.currentBalance < minDropAmount
        ? "Deposit COIN"
        : "Active"
      : advancedDrop.lastExecutionError
      ? advancedDrop.lastExecutionError
      : "Inactive"
    : advancedDrop.status === "rejected"
    ? "Rejected"
    : advancedDrop.currentBalance < minDropAmount
    ? "Deposit COIN"
    : "In Review";
}

function StatusIcon({ advancedDrop }) {
  const minDropAmount = advancedDropMinAmount(advancedDrop);
  return (
    <Tooltip title={statusText(advancedDrop)}>
      <i
        style={advancedDrop ? {} : { color: "rgba(0, 0, 0, 0.2)" }}
        className={cx("fa fa-circle ml-2", {
          "text-success":
            advancedDrop &&
            advancedDrop.currentBalance >= minDropAmount &&
            advancedDrop.status === "approved" &&
            advancedDrop.active,
          "text-warning":
            advancedDrop &&
            (!advancedDrop.status ||
              advancedDrop.status === "in_review" ||
              advancedDrop.currentBalance < minDropAmount),
          "text-danger":
            advancedDrop &&
            advancedDrop.status &&
            advancedDrop.status !== "in_review" &&
            (!advancedDrop.active || advancedDrop.status === "rejected"),
        })}
      />
    </Tooltip>
  );
}

export function advancedDropMinAmount(advancedDrop) {
  return advancedDrop
    ? Math.min(...advancedDropVariants(advancedDrop).map((v) => v.amount))
    : 0;
}

export function advancedDropMaxAmount(advancedDrop) {
  return advancedDrop
    ? Math.max(...advancedDropVariants(advancedDrop).map((v) => v.amount))
    : 0;
}

export function advancedDropVariants(advancedDrop) {
  return advancedDrop
    ? [advancedDrop.drop].concat(advancedDrop.variants || [])
    : [];
}
