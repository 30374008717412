import React, {
  Component,
  createContext,
  useContext,
  useEffect,
  useCallback,
  useState,
  useMemo,
  FC,
} from "react";
import { initializeApp, User, auth, firestore } from "firebase/app";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { configureRollbar, trackInfo } from "../utils/track";
import { bossClient, MY_USER_INFO } from "./bossapi";
import { useQuery } from "@apollo/react-hooks";
import "firebase/firestore";
import "firebase/auth";

export { auth } from "firebase/app";

interface IUserInfo {
  xyoBossBalance: number;
  xyoCollected: number;
  xyoGifted: number;
  bossAccess: boolean;
  bossAdmin: boolean;
}

interface IAuthContext {
  signOut: () => Promise<void>;
  refetchUserInfo: () => Promise<any>;
  initialized: boolean;
  userInfoLoading: boolean;
  userInfo: IUserInfo | null | undefined;
  user: User | null;
}

initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
});

const db = firestore();
const Context = createContext<IAuthContext>({
  signOut: () => Promise.resolve(),
  refetchUserInfo: () => Promise.resolve(),
  initialized: false,
  userInfoLoading: false,
  userInfo: null,
  user: null,
});

export const firebaseUI = require("firebaseui");
export const ui =
  firebaseUI.auth.AuthUI.getInstance() || new firebaseUI.auth.AuthUI(auth());

export function useAuth() {
  return useContext(Context);
}

export function useUser() {
  const { user, initialized } = useAuth();
  return user;
}

export function useUserInfo() {
  const { userInfo } = useAuth();
  return userInfo;
}

export function useAuthUi() {
  return ui;
}

export const AuthProvider: FC = ({ children }) => {
  const [initialized, setInitialized] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  // const [userInfo, setUserInfo] = useState<IUserInfo | null | undefined>(null);
  const myUserInfo = useQuery(MY_USER_INFO, { skip: !user });

  const signOut = useCallback(async () => {
    await auth().signOut();
    await bossClient.clearStore();
    await bossClient.resetStore();
  }, []);

  const value = useMemo(
    () => ({
      initialized,
      user,
      userInfo: myUserInfo?.data?.myUserInfo,
      refetchUserInfo: myUserInfo.refetch, 
      userInfoLoading: !user || myUserInfo?.loading,
      signOut,
    }),
    [initialized, user, myUserInfo, signOut]
  );

  useEffect(() => {
    return auth().onAuthStateChanged(async (user) => {
      configureRollbar(user);
      trackInfo("Auth state changed", user);
      setUser(user);
      setInitialized(true);
    });
  }, []);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const PrivateRoute = ({
  to,
  path,
  ...props
}: RouteProps & { to: string }) => {
  const { initialized, user } = useAuth();
  if (!initialized) return null;
  if (!user) return <Redirect to={`/auth?redirect=${path}`} />;
  return <Route path={path} {...props} />;
};

export const InvitedRoute = ({ path, ...props }: RouteProps) => {
  const { initialized, user, userInfo, userInfoLoading } = useAuth();
  if (initialized && !user) {
    return <Redirect to={`/auth?redirect=${path}`} />;
  }
  if (!userInfoLoading && (!userInfo || !userInfo.bossAccess)) {
    return <Redirect to="/beta" />;
  }
  if (userInfoLoading) return null;
  return <Route path={path} {...props} />;
};

export const InvitedAdminRoute = ({ path, ...props }: RouteProps) => {
  const { initialized, user, userInfo, userInfoLoading } = useAuth();
  if (initialized && !user) {
    return <Redirect to={`/auth?redirect=${path}`} />;
  }
  if (!userInfoLoading && (!userInfo || !userInfo.bossAdmin)) {
    return <Redirect to="/beta" />;
  }
  if (userInfoLoading) return null;
  return <Route path={path} {...props} />;
};
