import Slider from "@material-ui/core/Slider";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import get from "lodash/get";
import React, {
  useState,
  useRef,
  useEffect,
  createContext,
  useContext,
} from "react";
import { useUserInfo } from "../../context/auth";
import { useBooleanControls, useAsyncMethod } from "../../utils/hooks";
import Map, { initializeGoogleMaps, PointsOfInterestMap } from "../Map";
import { QuadkeyMap, RadiusMap } from "../Map/Select";
import { QuadkeyMap as StaticQuadkeyMap } from "../Map/Static";
import Form, { FormField } from "../Form";
import StepForm from "../Form/Steps";
import { linkExp, frequencyToHumanReadable } from "./helpers";
import DropPreview from "./Preview";
import { DateTimePicker } from "@material-ui/pickers";
import Dialog from "@material-ui/core/Dialog";
import DropListDetails from "./ListDetails";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Paper from "@material-ui/core/Paper";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import PlusIcon from "@material-ui/icons/Add";
import MinusIcon from "@material-ui/icons/Close";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  ADVANCED_DROPS,
  CREATE_ADVANCED_DROP,
  CREATE_DROP,
  LOCATION_SUMMARY_QUERY,
  UPDATE_ADVANCED_DROP,
} from "./gql";
import {
  featureCollection,
  featureType,
  getChildrenAtPrecision,
} from "../../utils/map";
import flattenDeep from "lodash/flattenDeep";
import { origin } from "quadkeytools";
import { useSnackbar } from "../Snackables";
import debounce from "lodash/debounce";
import pick from "lodash/pick";
import { Query } from "react-apollo";
import Checkbox from "@material-ui/core/Checkbox";
import ButtonBase from "@material-ui/core/ButtonBase";
import { ErrorDisplay, WarningDisplay, InfoDisplay } from "./MenuCard";
import { UploadGeoDropImageField } from "../Upload";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import Fab from "@material-ui/core/Fab";
import cx from "classnames";

export const ScrollContext = createContext(null);
export const ScrollProvider = ScrollContext.Provider;
export const useScrollElement = () => useContext(ScrollContext);
export const useScrollToBottom = () => {
  const scrollElement = useContext(ScrollContext);
  return () =>
    window.requestAnimationFrame(() => {
      if (scrollElement) {
        scrollElement.scrollTop = scrollElement.scrollHeight;
      }
    });
};

const ButtonTextField = (props) => {
  const handleChange = (ev) => {
    if (props.onChange) {
      props.onChange(ev);
    }
  };
  return (
    <TextField select {...props} onChange={handleChange}>
      <MenuItem value="Learn More">Learn More</MenuItem>
      <MenuItem value="Install Now">Install Now</MenuItem>
      <MenuItem value="Visit Website">Visit Website</MenuItem>
      <MenuItem value="Check it Out">Check it Out</MenuItem>
      <MenuItem value="Download">Download</MenuItem>
    </TextField>
  );
};

export const DropFormFields = ({ values, handleChange, maxAmount }) => {
  return (
    <>
      <div className="col-12">
        <FormField
          id="amount"
          name="amount"
          component={TextField}
          label="Reward Amount"
          variant="outlined"
          margin="normal"
          type="number"
          min={10}
          max={maxAmount / (values.quantity || 1)}
          fullWidth
        />
        <Slider
          min={10}
          max={maxAmount / (values.quantity || 1)}
          valueLabelDisplay="auto"
          value={values.amount}
          onChange={(_, v) => handleChange("amount", v)}
        />
      </div>
      <div className="col-12">
        <FormField
          id="imageUrl"
          name="imageUrl"
          component={UploadGeoDropImageField}
          label=""
          variant="outlined"
          helperText="(Optional)"
          margin="normal"
          fullWidth
        />
      </div>
      <div className="col-12">
        <FormField
          id="message"
          name="message"
          component={TextFieldPerspective}
          multiline={true}
          rowsMax={2}
          rows={2}
          label="Note"
          variant="outlined"
          margin="normal"
          fullWidth
        />
      </div>
      <div className="col-12">
        {/* <FormField
          id="link"
          name="link"
          component={TextField}
          label="Learn More URL"
          variant="outlined"
          helperText="(Optional)"
          margin="normal"
          fullWidth
        /> */}
        <div className="row">
          <div className="col-3">
            <FormField
              id="buttonText"
              name="buttonText"
              component={ButtonTextField}
              label="Button Text"
              variant="outlined"
              helperText="(Optional)"
              margin="normal"
              fullWidth
            />
          </div>
          <div className="col-9">
            <FormField
              id="link"
              name="link"
              component={TextField}
              label="Learn More URL"
              variant="outlined"
              margin="normal"
              fullWidth
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const DropTileFields = ({ values, errors, handleChange }) => {
  const prevQuadkey = useRef(values.fullQuadkey || "");
  const scrollToBottom = useScrollToBottom();
  return (
    <>
      <div className="col-12">
        <FormControlLabel
          control={
            <Checkbox
              id="restrictFullQuadkey"
              name="restrictFullQuadkey"
              type="checkbox"
              checked={Boolean(
                values.fullQuadkey || values.restrictFullQuadkey
              )}
              onChange={(ev) => {
                const { checked } = ev.target;
                handleChange("fullQuadkey", checked ? prevQuadkey.current : "");
                handleChange("restrictFullQuadkey", checked);
                if (checked) {
                  scrollToBottom();
                }
              }}
              variant="inline"
              margin="normal"
            />
          }
          label="Restrict to tile"
        />
      </div>
      {(values.fullQuadkey || values.restrictFullQuadkey) && (
        <div className="col-12">
          <QuadkeySelect
            id="fullQuadkey"
            name="fullQuadkey"
            precision={20}
            value={values.fullQuadkey}
            onChange={(ev) => {
              prevQuadkey.current = ev.target.value;
              handleChange(ev);
            }}
            label="Any Tile"
            variant="outlined"
            helperText="Restricted to tile (Optional)"
            margin="normal"
            fullWidth
          />
        </div>
      )}
      {errors && errors.fullQuadkey && (
        <div className="col-12 d-flex">
          <p className="text-danger">{errors.fullQuadkey}</p>
        </div>
      )}
    </>
  );
};

export const DropConditionFields = ({ values, errors, handleChange }) => {
  const prevCondition = useRef(values.condition || "");
  const scrollToBottom = useScrollToBottom();
  return (
    <>
      <div className="col-12">
        <FormControlLabel
          control={
            <Checkbox
              id="hasCondition"
              name="hasCondition"
              type="checkbox"
              checked={Boolean(values.condition || values.hasCondition)}
              onChange={(ev) => {
                const { checked } = ev.target;
                handleChange(
                  "condition",
                  checked ? prevCondition.current : null
                );
                handleChange("hasCondition", checked);
                if (checked) {
                  scrollToBottom();
                }
              }}
              variant="inline"
              margin="normal"
            />
          }
          label="Has Condition"
        />
      </div>
      {(values.condition || values.hasCondition) && (
        <div className="col-12">
          <ConditionSelect
            id="condition"
            name="condition"
            precision={20}
            value={values.condition}
            onChange={(value) => {
              prevCondition.current = value;
              handleChange("condition", value);
            }}
            label="Drop Condition"
            variant="outlined"
            margin="normal"
            fullWidth
          />
        </div>
      )}
      {errors && errors.condition && (
        <div className="col-12 d-flex">
          <p className="text-danger">{errors.condition}</p>
        </div>
      )}
    </>
  );
};

export const DropLocationFields = ({ values, errors, handleChange }) => {
  const prevQuadkey = useRef(values.quadkey || "");
  const scrollToBottom = useScrollToBottom();
  return (
    <>
      <div className="col-12">
        <FormControlLabel
          control={
            <Checkbox
              id="restrictQuadkey"
              name="restrictQuadkey"
              type="checkbox"
              checked={Boolean(values.quadkey || values.restrictQuadkey)}
              onChange={(ev) => {
                const { checked } = ev.target;
                handleChange("quadkey", checked ? prevQuadkey.current : "");
                handleChange("restrictQuadkey", checked);
                if (checked) {
                  scrollToBottom();
                }
              }}
              variant="inline"
              margin="normal"
            />
          }
          label="Restrict to users in region"
        />
      </div>
      {(values.quadkey || values.restrictQuadkey) && (
        <div className="col-12">
          <QuadkeySelect
            id="quadkey"
            name="quadkey"
            value={values.quadkey}
            onChange={(ev) => {
              prevQuadkey.current = ev.target.value;
              handleChange(ev);
            }}
            label="Any Tile"
            variant="outlined"
            helperText="Restricted to users in region (Optional)"
            margin="normal"
            fullWidth
          />
        </div>
      )}
      {errors && errors.quadkey && (
        <div className="col-12">
          <p className="text-danger">{errors.quadkey}</p>
        </div>
      )}
    </>
  );
};

export const DropEmailFields = ({ values, handleChange }) => {
  const prevEmail = useRef(values.email || "");
  return (
    <>
      <div className="col-12">
        <FormControlLabel
          control={
            <Checkbox
              id="restrictEmail"
              name="restrictEmail"
              type="checkbox"
              checked={Boolean(values.email || values.restrictEmail)}
              onChange={(ev) => {
                const { checked } = ev.target;
                handleChange("email", checked ? prevEmail.current : "");
                handleChange("restrictEmail", checked);
              }}
              variant="inline"
              margin="normal"
            />
          }
          label="Restrict to email"
        />
      </div>
      {(values.email || values.restrictEmail) && (
        <div className="col-12">
          <TextField
            id="email"
            name="email"
            value={values.email}
            onChange={(ev) => {
              prevEmail.current = ev.target.value;
              handleChange(ev);
            }}
            label="Any Email"
            variant="outlined"
            helperText="Restrict to Email (Optional)"
            margin="normal"
            fullWidth
          />
        </div>
      )}
    </>
  );
};

export const DropTemplateForm = ({
  id,
  initial,
  onSubmit,
  hasTemplate,
  hasQuantity,
  error,
}) => {
  const userInfo = useUserInfo();
  const xyoBossBalance = get(userInfo, "xyoBossBalance") || 0;
  const validator = createDropValidator(xyoBossBalance);
  return (
    <Form
      id={id}
      initial={initial}
      onSubmit={onSubmit}
      validator={{
        template: (v) => {
          if (hasTemplate && !v) return "Required";
          return "";
        },
        ...validator,
      }}
    >
      {(formContext) => (
        <div className="row flex-row-reverse">
          <div className="col-md-8 col-lg-9">
            <div className="row">
              {hasTemplate && (
                <div className="col-12">
                  <FormField
                    id="template"
                    name="template"
                    component={TextField}
                    label="Template Title"
                    variant="outlined"
                    helperText="Name of the template"
                    margin="normal"
                    fullWidth
                  />
                </div>
              )}
              {hasQuantity && (
                <div className="col-12">
                  <TextField
                    id="quantity"
                    name="quantity"
                    label="Quantity"
                    variant="outlined"
                    margin="normal"
                    type="number"
                    value={formContext.values.quantity}
                    onChange={(ev) =>
                      formContext.handleChange(
                        "quantity",
                        parseInt(ev.target.value, 10)
                      )
                    }
                    min={1}
                    max={xyoBossBalance / formContext.values.amount}
                    fullWidth
                  />
                  <Slider
                    min={1}
                    max={xyoBossBalance / formContext.values.amount}
                    valueLabelDisplay="auto"
                    value={formContext.values.quantity}
                    onChange={(_, v) =>
                      formContext.handleChange("quantity", parseInt(v, 10))
                    }
                  />
                </div>
              )}
              <DropFormFields maxAmount={xyoBossBalance} {...formContext} />
              {/* <DropTileFields {...formContext} /> */}
              {/* <DropLocationFields {...formContext} /> */}
              {/* <DropConditionFields {...formContext} /> */}
              <DropEmailFields {...formContext} />
              <div className="col-12 mt-2">
                <ErrorDisplay error={error} />
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-3">
            <Typography gutterBottom>Preview</Typography>
            <DropPreview drop={formContext.values} className="mx-auto" />
          </div>
        </div>
      )}
    </Form>
  );
};

const DropStepFormBase = ({
  id,
  initialDrop,
  initialVariants,
  initialOptions,
  onConfirm,
  onCancel,
  loading,
}) => {
  const [step, setStep] = useState(0);
  const [drop, setDrop] = useState(initialDrop);
  const [variants, setVariants] = useState(initialVariants);
  const [options, setOptions] = useState(initialOptions);
  const variantOffset = variants ? variants.length : 0;
  console.log({ options });
  return (
    <>
      <DialogContent>
        <div className="row flex-md-row-reverse">
          <div className="col">
            <StepForm step={step} setStep={setStep}>
              <DropAdvancedForm
                id={id}
                label="Options"
                drop={drop}
                initial={options}
                onSubmit={(opts) => {
                  setStep(step + 1);
                  setOptions(opts);
                }}
              />
              <DropDetailsForm
                id={id}
                label="Details"
                currentBalance={(options && options.currentBalance) || 0}
                onSubmit={(values) => {
                  setStep(step + 1);
                  setDrop(values);
                }}
                onChange={({ values }) => setDrop(values)}
                initial={drop}
              />
              {(variants || []).map((variant, i) => (
                <DropDetailsForm
                  id={id}
                  // label={`Variation #${i + 1}`}
                  currentBalance={(options && options.currentBalance) || 0}
                  onSubmit={(values) => {
                    setStep(step + 1);
                    setVariants(variants.map((v, j) => (i === j ? values : v)));
                  }}
                  onChange={({ values }) => {
                    setVariants(variants.map((v, j) => (i === j ? values : v)));
                  }}
                  initial={variant}
                />
              ))}
              <DropDetailsConfirm
                label="Confirm"
                drop={drop}
                options={options}
              />
            </StepForm>
          </div>
          <div
            className="mt-2 mt-md-0 d-flex flex-column align-items-center"
            style={{ maxWidth: 290 }}
          >
            {/* <Typography gutterBottom>
              {variants && variants.length
                ? step <= 1
                  ? 'Ad #1'
                  : variants && variants[step - 2]
                  ? `Ad #${step}`
                  : 'Ad #1'
                : 'Preview'}
            </Typography> */}
            <ButtonBase
              className="mb-4"
              variant="contained"
              component="div"
              TouchRippleProps={{
                style: { color: "rgba(0,0,0,0.4)" },
              }}
            >
              <DropPreview
                drop={
                  step <= 1
                    ? drop
                    : variants && variants[step - 2]
                    ? variants[step - 2]
                    : drop
                }
              />
            </ButtonBase>
            <Grid container spacing={1}>
              {variants && variants.length
                ? [drop].concat(variants).map((d, i) => (
                    <Grid item key={i} xs={4}>
                      <Button
                        size="small"
                        className="position-relative mb-1 px-0 summary-card"
                        style={{ minWidth: "100%" }}
                        onClick={() => setStep(i + 1)}
                        color={step - 1 === i ? "primary" : "default"}
                        margin="normal"
                      >
                        <span>Ad #{i + 1}</span>
                        {/* {i ? (
                          <ButtonBase
                            color="primary"
                            onClick={ev => {
                              ev.stopPropagation()
                              setVariants(
                                variants.filter((v, i) => i + 2 !== step),
                              )
                              setStep(step - 1)
                            }}
                            style={{
                              position: 'absolute',
                              top: -8,
                              right: -8,
                              width: 10,
                              height: 10,
                            }}
                          >
                            <MinusIcon fontSize="small" />
                          </ButtonBase>
                        ) : null} */}
                      </Button>
                    </Grid>
                  ))
                : null}
              {variants && variants.length >= 5 ? null : (
                <Grid item marginBottom>
                  <Button
                    size="small"
                    onClick={() => {
                      setVariants(
                        variants && variants.length
                          ? [].concat(variants, {
                              ...variants[variants.length - 1],
                            })
                          : [
                              {
                                ...drop,
                              },
                            ]
                      );
                      setStep(variants ? variants.length + 2 : 2);
                    }}
                  >
                    <PlusIcon className="mr-2" />{" "}
                    {variants && variants.length ? "" : "Add Variation"}
                  </Button>
                </Grid>
              )}
            </Grid>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        {variants && variants.length && variants[step - 2] ? (
          <>
            <div style={{ width: 290 }} />
            <Button
              size="small"
              color="primary"
              className="mr-auto"
              onClick={() => {
                setVariants(variants.filter((v, i) => i + 2 !== step));
                setStep(step - 1);
              }}
            >
              Remove
            </Button>
          </>
        ) : null}
        {step ? (
          <Button onClick={() => setStep(step - 1)} color="primary">
            Back
          </Button>
        ) : (
          <Button onClick={onCancel} color="primary">
            Cancel
          </Button>
        )}
        {step === 2 + variantOffset ? (
          <Button
            color="primary"
            onClick={() =>
              onConfirm(pickAdvancedDropUpdate({ ...options, drop, variants }))
            }
            disabled={loading}
          >
            Confirm{" "}
            {loading && (
              <span className="spinner-border spinner-border-sm ml-2" />
            )}
          </Button>
        ) : (
          <Button color="primary" form={id} type="submit">
            Next
          </Button>
        )}
      </DialogActions>
    </>
  );
};

export const UpdateDropStepForm = ({
  id,
  advancedDrop,
  onSuccess,
  onCancel,
}) => {
  const [{}, { setSnackbar }] = useSnackbar();
  const [updateAdvancedDrop, advanced] = useMutation(UPDATE_ADVANCED_DROP, {
    variables: {},
    update: () =>
      setSnackbar({
        message: "Advanced drop updated successfully",
      }),
  });
  const onConfirm = async ({ options, drop, variants }) => {
    await updateAdvancedDrop({
      variables: {
        id: advancedDrop.id,
        variants,
        drop,
        options,
      },
    });
    if (onSuccess) {
      onSuccess();
    }
  };
  return (
    <DropStepFormBase
      id={id}
      loading={advanced.loading}
      onCancel={onCancel}
      onConfirm={onConfirm}
      initialDrop={{ ...advancedDrop.drop }}
      initialOptions={{ ...advancedDrop }}
      initialVariants={advancedDrop.variants || []}
    />
  );
};

export const CreateDropStepForm = ({ id, initial, onSuccess, onCancel }) => {
  const refetchQueries = [
    {
      query: ADVANCED_DROPS,
      variables: {
        page: 1,
        perPage: 10,
        field: "updatedTime",
        order: -1,
      },
    },
  ];
  const [{}, { setSnackbar }] = useSnackbar();
  const [createAdvancedDrop, advanced] = useMutation(CREATE_ADVANCED_DROP, {
    refetchQueries,
    variables: {},
    update: () =>
      setSnackbar({
        message: "Advanced drop created successfully",
      }),
  });
  const onConfirm = async ({ options, drop, variants }) => {
    await createAdvancedDrop({
      variables: {
        variants,
        drop,
        options: {
          active: false,
          ...options,
        },
      },
    });
    if (onSuccess) {
      onSuccess();
    }
  };
  return (
    <DropStepFormBase
      id={id}
      loading={advanced.loading}
      onCancel={onCancel}
      onConfirm={onConfirm}
      initialDrop={initial}
      initialVariants={[]}
      initialOptions={{
        // maxSpend: 10,
        maxDailySpend: 10,
        startTime: new Date().toString(),
      }}
    />
  );
};

export const DropDetailsForm = ({
  id,
  onSubmit,
  onChange,
  initial,
  currentBalance,
}) => {
  const userInfo = useUserInfo();
  const xyoBossBalance = get(userInfo, "xyoBossBalance") || 0;
  const maxAmount = xyoBossBalance + (currentBalance || 0);
  const handleSubmit = async (values) => {
    const title = await analyzeText.sanitize(values.title);
    const message = await analyzeText.sanitize(values.message);
    onSubmit({ ...values, title, message });
  };
  return (
    <Form
      id={id}
      initial={initial}
      onChange={onChange}
      onSubmit={handleSubmit}
      validator={createDropValidator(maxAmount)}
    >
      {(formContext) => (
        <div className="row">
          <div className="col-12">
            <FormField
              id="title"
              name="title"
              component={TextFieldPerspective}
              label="Title"
              variant="outlined"
              margin="normal"
              fullWidth
            />
          </div>
          <DropFormFields maxAmount={maxAmount} {...formContext} />
          {/* <DropTileFields {...formContext} /> */}
          {/* <DropLocationFields {...formContext} /> */}
          {/* <DropConditionFields {...formContext} /> */}
        </div>
      )}
    </Form>
  );
};

const SelectFrequency = (props) => {
  const handleChange = (ev, element) => {
    props.onChange(ev, element.props.value);
  };
  return (
    <TextField select {...props} onChange={handleChange}>
      <MenuItem value="">Choose...</MenuItem>
      <MenuItem value="daily">Every Day</MenuItem>
      <MenuItem value="hourly">Every Hour</MenuItem>
      <MenuItem value="half_hour">Every 30 Minutes</MenuItem>
      <MenuItem value="quarter_hour">Every 15 Minutes</MenuItem>
      <MenuItem value="minutely">Every Minute</MenuItem>
    </TextField>
  );
};

export const DropAdvancedForm = ({ id, onSubmit, initial, drop }) => {
  const userInfo = useUserInfo();
  const prevEndDate = useRef(null);
  const [ongoing, setOngoing] = useState(!initial.endTime);
  const xyoBossBalance = get(userInfo, "xyoBossBalance") || 0;
  return (
    <Form
      id={id}
      onSubmit={onSubmit}
      initial={initial}
      validator={createDropOptionsValidator(userInfo)}
    >
      {({ values, handleChange }) => (
        <>
          <FormField
            id="title"
            name="title"
            component={TextField}
            label="Campaign Title"
            variant="outlined"
            margin="normal"
            fullWidth
          />
          {/* <FormField
            id="maxSpend"
            name="maxSpend"
            component={TextField}
            label="Total Budget"
            variant="outlined"
            margin="normal"
            type="number"
            min={10}
            max={xyoBossBalance}
            fullWidth
          />
          <Slider
            min={10}
            max={xyoBossBalance}
            valueLabelDisplay="auto"
            value={values.maxSpend}
            onChange={(_, v) => handleChange("maxSpend", v)}
          /> */}
          <FormField
            id="maxDailySpend"
            name="maxDailySpend"
            component={TextField}
            label="Daily Budget"
            variant="outlined"
            margin="normal"
            type="number"
            min={10}
            max={xyoBossBalance}
            fullWidth
          />
          <Slider
            min={10}
            max={xyoBossBalance}
            valueLabelDisplay="auto"
            value={values.maxDailySpend}
            onChange={(_, v) => handleChange("maxDailySpend", v)}
          />
          <FormField
            id="startTime"
            name="startTime"
            component={DateTimeSelect}
            label="Start Time"
            variant="inline"
            inputVariant="outlined"
            helperText={
              <Tooltip title="Actual start time is dependent on content approval, which can take up to three business days.">
                <span>
                  When to start the advanced drop{" "}
                  <i className="fa fa-info ml-1" />
                </span>
              </Tooltip>
            }
            margin="normal"
            fullWidth
          />
          <div className="d-flex align-items-center">
            <FormControlLabel
              control={
                <Checkbox
                  id="ongoing"
                  name="ongoing"
                  type="checkbox"
                  checked={ongoing}
                  onChange={(ev) => {
                    const value = ev.target.checked;
                    setOngoing(value);
                    handleChange(
                      "endTime",
                      value ? "" : moment().add(1, "day").toString()
                    );
                  }}
                  variant="inline"
                  margin="normal"
                />
              }
              label="Run as ongoing"
            />
          </div>
          {!ongoing && (
            <FormField
              id="endTime"
              name="endTime"
              component={DateTimeSelect}
              label="End Time"
              variant="inline"
              inputVariant="outlined"
              helperText="When to end the advanced drop"
              margin="normal"
              fullWidth
            />
          )}
        </>
      )}
    </Form>
  );
};

const DropDetailsConfirm = ({ drop, options }) => {
  const message = getDropDetailsConfirmationWarning(drop, options);
  return (
    <>
      <DropListDetails drop={drop} dailyBudget={options.maxDailySpend} />
      <WarningDisplay message={message} />
    </>
  );
};

const getDropDetailsConfirmationWarning = (drop, options) => {
  let message = "This drop will repeat";
  if (options && options.frequency)
    message += ` <b>${frequencyToHumanReadable(
      options.frequency
    ).toLowerCase()}</b>`;
  else message += " <b>daily</b>";
  if (options && options.endTime)
    message += ` until <b>${new Date(options.endTime).toLocaleString()}</b>.`;
  else message += " until <b>canceled</b>.";
  return message;
};

export const DateTimeSelect = (pickerProps) => {
  return (
    <DateTimePicker
      {...pickerProps}
      onChange={(pickerValue) => {
        pickerProps.onChange({
          target: {
            name: pickerProps.name,
            value: pickerValue.toString(),
          },
        });
      }}
    ></DateTimePicker>
  );
};

export const DropConditionGroup = (props) => {
  const and = props.value && props.value.AND;
  const or = props.value && props.value.OR;
  const onClickAddAnd = () => {};
  const onClickAddOr = () => {};
  return (
    <>
      {(or || [props.value]).map((value) => (
        <>
          <Paper className="p-1 px-2">
            <ConditionSelect {...props} value={value} />
            <div className="text-right mb-2">
              <Fab
                color="secondary"
                size="small"
                variant="extended"
                onClick={onClickAddAnd}
              >
                + And
              </Fab>
            </div>
          </Paper>
          <div className="text-right my-2 mr-2">
            <Fab size="small" variant="extended">
              + Or
            </Fab>
          </div>
        </>
      ))}
      <div className="text-right my-2 mr-2">
        <Fab
          color="secondary"
          size="small"
          variant="extended"
          onClick={onClickAddOr}
        >
          + Or
        </Fab>
      </div>
    </>
  );
};

const ConditionSelect = (props) => {
  const { data } = useQuery(ADVANCED_DROPS, {
    variables: {
      page: 1,
      perPage: 100,
      field: "createdTime",
      order: -1,
    },
  });
  const value = (props.value && props.value.NOT) || props.value;
  const advancedDrops =
    (data && data.advancedDrops && data.advancedDrops.data) || [];
  const selectedAdvancedDrop = advancedDrops.find(
    (advancedDrop) => advancedDrop.id === (value && value.advancedId)
  );
  const variantValue = JSON.stringify(value && value.variant);
  const actionValue = !value
    ? ""
    : value.collected
    ? "collected"
    : value.linkClicked
    ? "linkClicked"
    : value.converted
    ? "converted"
    : "";
  const negated = !!(props.value && props.value.NOT);
  return (
    <>
      <div className="d-flex">
        <TextField
          select
          fullWidth
          helperText="Received Advanced Drop"
          value={(selectedAdvancedDrop && selectedAdvancedDrop.id) || ""}
          onChange={(ev) => {
            props.onChange({
              advancedId: ev.target.value,
              variant: variantValue,
              [actionValue]: true,
            });
          }}
        >
          {advancedDrops.map((drop) => (
            <MenuItem key={drop.id} value={drop.id}>
              {drop.title}
            </MenuItem>
          ))}
        </TextField>
        {selectedAdvancedDrop && (
          <TextField
            select
            fullWidth
            className="ml-2"
            helperText="Variant"
            value={variantValue}
            onChange={(ev) => {
              props.onChange({
                advancedId:
                  (selectedAdvancedDrop && selectedAdvancedDrop.id) || "",
                variant: JSON.parse(ev.target.value),
                [actionValue]: true,
              });
            }}
          >
            <MenuItem value={"null"}>
              {selectedAdvancedDrop.drop.title}
            </MenuItem>
            {(selectedAdvancedDrop.variants || []).map((variant, i) => (
              <MenuItem key={i} value={String(i)}>
                {variant.title}
              </MenuItem>
            ))}
          </TextField>
        )}
        {selectedAdvancedDrop && (
          <TextField
            className="ml-2"
            helperText="Action"
            onChange={(ev) => {
              props.onChange({
                advancedId:
                  (selectedAdvancedDrop && selectedAdvancedDrop.id) || "",
                variant: JSON.parse(variantValue),
                [ev.target.value]: true,
              });
            }}
            value={actionValue}
            fullWidth
            select
          >
            <MenuItem>Required Action</MenuItem>
            <MenuItem value="collected">Collected</MenuItem>
            <MenuItem value="linkClicked">Clicked</MenuItem>
            <MenuItem value="converted">Converted</MenuItem>
          </TextField>
        )}
        {selectedAdvancedDrop && (
          <FormControlLabel
            className="d-block ml-2"
            label={
              <FormHelperText id="negate" className="text-center">
                Negate
              </FormHelperText>
            }
            control={
              <Checkbox
                id="negate"
                name="negate"
                type="checkbox"
                className="p-1"
                checked={negated}
                onChange={(ev) => {
                  const value = ev.target.checked;
                  const condition = {
                    advancedId:
                      (selectedAdvancedDrop && selectedAdvancedDrop.id) || "",
                    variant: JSON.parse(variantValue),
                    [actionValue]: true,
                  };
                  props.onChange(value ? { NOT: condition } : condition);
                }}
                variant="inline"
                margin="normal"
              />
            }
          />
        )}
      </div>
    </>
  );
};

const QuadkeySelect = (props) => {
  const [value, setValue] = useState(props.value);
  const [open, modalControls] = useBooleanControls(false);
  const onSubmit = () => {
    modalControls.setFalse();
    props.onChange({
      target: {
        value,
        name: props.name,
      },
    });
  };
  return (
    <>
      <div className="d-flex">
        <TextField {...props} onClick={modalControls.setTrue} />
        {props.value && (
          <ButtonBase
            onClick={modalControls.setTrue}
            style={{
              marginTop: 16,
              marginBottom: 8,
              marginLeft: 8,
              height: 56,
              borderRadius: 4,
            }}
          >
            <StaticQuadkeyMap
              quadkey={props.value}
              style={{
                minWidth: 56,
                height: 56,
                borderRadius: 4,
              }}
            />
          </ButtonBase>
        )}
      </div>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="md"
        onClose={modalControls.setFalse}
        aria-labelledby="form-dialog-title"
        scroll="body"
      >
        <div
          id="form-dialog-title"
          className="d-flex align-items-center py-2 px-4"
        >
          <Typography
            variant="h6"
            className="flex-grow-1"
            style={{ lineHeight: 1 }}
          >
            Select Drop Location
          </Typography>
        </div>
        <DialogContent>
          <DialogContentText>
            Drops will be restricted to users in the following location:
            <code className="d-block text-danger">{value}</code>
          </DialogContentText>
          <div className="position-relative" style={{ height: 400 }}>
            <QuadkeyMap
              precision={props.precision}
              id="geodrop-location"
              value={props.value}
              userCenter={true}
              userMarker={true}
              onChange={setValue}
            />
            <div className="crosshair-h" />
            <div className="crosshair-v" />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={modalControls.setFalse} color="primary">
            Cancel
          </Button>
          <Button onClick={onSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const LocationVolume = ({ data, loading }) => {
  return (
    <div style={{ position: "absolute", top: 0, right: 0 }}>
      <Paper className="m-1">
        <WarningDisplay
          icon={
            loading && (
              <span className="spinner-border spinner-border-sm mr-2" />
            )
          }
          message={`${
            get(data, "locationSummary.count")
              ? `Approximately ${get(data, "locationSummary.count")}`
              : "--"
          } Users Yesterday`}
        />
      </Paper>
    </div>
  );
};

const LocationSelect = (props) => {
  const placesElement = useRef(null);
  const [value, setMultiPolygon] = useState(props.value);
  const [open, modalControls] = useBooleanControls(false);
  const { data, loading } = useQuery(
    LOCATION_SUMMARY_QUERY,
    toGeowithinVariables({ coordinates: (value || []).map((v) => [v]) })
  );
  const onSubmit = () => {
    modalControls.setFalse();
    props.onChange({
      target: {
        value,
        name: props.name,
      },
    });
  };
  return (
    <>
      <div className="row">
        <div className="col">
          <TextField {...props} value={value} onClick={modalControls.setTrue} />
        </div>
      </div>
      <div ref={placesElement} />
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="md"
        onClose={modalControls.setFalse}
        aria-labelledby="form-dialog-title"
        scroll="body"
      >
        <div
          id="form-dialog-title"
          className="d-flex align-items-center py-2 px-4"
        >
          <Typography
            variant="h6"
            className="flex-grow-1"
            style={{ lineHeight: 1 }}
          >
            Select Drop Location
          </Typography>
        </div>
        <DialogContent>
          <DialogContentText>
            Drops will be restricted to users near the following locations:{" "}
            <span className="d-flex align-items-center">
              {loading && (
                <span className="spinner-border spinner-border-sm mr-2" />
              )}{" "}
              {get(data, "locationSummary.count")
                ? `Approximately ${get(data, "locationSummary.count")}`
                : !loading && "--"}{" "}
              Users Yesterday
            </span>
          </DialogContentText>
          <div className="position-relative" style={{ height: 400 }}>
            <RadiusMap
              id="geodrop-radius-location"
              value={props.value}
              userCenter={true}
              onMultiPolygonChange={setMultiPolygon}
              drawPolygon={true}
              onSelectedLocationsChange={setMultiPolygon}
              circles={get(data, "locationSummary.data")}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={modalControls.setFalse} color="primary">
            Cancel
          </Button>
          <Button onClick={onSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function toGeowithinVariables(props) {
  return {
    variables: {
      geoWithin: {
        type: "MultiPolygon",
        coordinates: props.coordinates,
      },
    },
    skip: !props.coordinates,
  };
}

export const DropFenceForm = ({ id, onSubmit, initial }) => {
  return (
    <Form id={id} onSubmit={onSubmit} initial={initial}>
      {({ values, handleChange }) => (
        <>
          <FormField
            id="name"
            name="name"
            component={TextField}
            label="Title"
            variant="outlined"
            helperText="The name of the geo fence"
            margin="normal"
            fullWidth
          />
          <Query
            query={LOCATION_SUMMARY_QUERY}
            {...toGeowithinVariables({
              coordinates: (values.coordinates || []).map((v) => [v]),
            })}
          >
            {({ data, loading }) => (
              <div className="position-relative" style={{ height: 400 }}>
                <RadiusMap
                  id="geodrop-location"
                  value={values.coordinates}
                  userCenter={true}
                  drawPolygon={true}
                  debugMultiPolygon={true}
                  circles={get(data, "locationSummary.data")}
                  onMultiPolygonChange={(v) => {
                    handleChange("coordinates", v);
                  }}
                  onSelectedLocationsChange={(v) => {
                    handleChange("coordinates", v);
                  }}
                />
                <LocationVolume data={data} loading={loading} />
              </div>
            )}
          </Query>
          <FormHelperText className="ml-2">
            Drops will be restricted to users near these locations
          </FormHelperText>
        </>
      )}
    </Form>
  );
};

function analyzeTextFactory() {
  const analysis = {};
  async function sanitize(text) {
    try {
      const { value, error } = await analyze(text);
      if (error) return "";
      if (value >= 0.5) return "";
      return text;
    } catch (e) {
      return "";
    }
  }
  async function analyze(text) {
    if (!text) return null;
    if (analysis[text]) return analysis[text];
    const res = await fetch(
      `https://vision.coinapp.co/analyzeText?text=${encodeURI(text)}`
    );
    const data = await res.json();
    analysis[text] = data;
    // await new Promise((r) => setTimeout(() => r(), 1000));
    return data;
  }
  return { analyze, sanitize };
}

const analyzeText = analyzeTextFactory();

export function TextFieldPerspective(props) {
  const [{ data, loading }, checkPerspective] = useAsyncMethod(
    analyzeText.analyze
  );
  useEffect(() => {
    const id = setTimeout(() => checkPerspective(props.value), 400);
    return () => clearTimeout(id);
  }, [props.value]);
  return (
    <>
      <TextField {...props} />
      <p className={cx({ "d-none": !loading && !data })}>
        <i
          className={cx("fa", {
            "fa-spinner skeleton": loading,
            "fa-circle text-success": data && data.value < 0.5,
            "fa-times text-danger": data && data.value >= 0.5,
          })}
        />
        {data && data.error ? (
          <span>{data.error}</span>
        ) : data ? (
          <span className="ml-1">
            {data.value < 0.5
              ? `Likely follows community guidelines `
              : `May violate community guidelines `}
          </span>
        ) : null}
      </p>
    </>
  );
}

export function createDropOptionsValidator(userInfo) {
  const xyoBossBalance = get(userInfo, "xyoBossBalance") || 0;
  return {
    // maxSpend: v => {
    //   if (!v || v < 10) return "Amount must be greater than 10";
    //   if (v > xyoBossBalance) return "Insufficient balance";
    //   return "";
    // },
    // maxDailySpend: v => {
    //   if (!v || v < 10) return "Amount must be greater than 10";
    //   if (v > xyoBossBalance) return "Insufficient balance";
    //   return "";
    // }
  };
}

export function createDropValidator(maxAmount) {
  return {
    amount: (v, vs) => {
      if (!v || v < 10) return "Amount must be greater than 10";
      if (Math.floor(v) !== v) return "Amount must be an integer";
      if (v > maxAmount) return "Insufficient balance";
      return "";
    },
    title: (v) => {
      if (!v) return "Title is required";
      if (v && v.length > 40) return "Maximum characters exceeded";
      return "";
    },
    message: (v) => {
      if (!v) return "Note is required";
      if (v && v.length > 140) return "Maximum characters exceeded";
      return "";
    },
    link: (v) => {
      if (!v) return "URL is required";
      // if (!v) return ""; // Link not required
      if (v.match(linkExp)) return "";
      return "URL is not valid";
    },
    quadkey: (v, vs) => {
      if (!vs.restrictQuadkey && !v) return "";
      if (!v) return "Required";
      if (!v.match(/^(0|1|2|3)*$/)) return "Can only include 0 1 2 or 3";
      if (v.length > 20) return "Must have a precision of less than 20";
      return "";
    },
    imageUrl: (v) => {
      if (!v) return "Image is required";
      return "";
    },
  };
}

export function pickAdvancedDropUpdate(advancedDrop) {
  const dropInputFields = [
    "title",
    "amount",
    "template",
    "latitude",
    "longitude",
    "fee",
    "link",
    "buttonText",
    "utm_source",
    "utm_campaign",
    "utm_medium",
    "imageUrl",
    "quadkey",
    "fullQuadkey",
    "email",
    "message",
    "condition.NOT",
    "condition.advancedId",
    "condition.variant",
    "condition.collected",
    "condition.linkClicked",
    "condition.converted",
  ];

  const dropOptionFields = [
    "active",
    "maxSpend",
    "maxDailySpend",
    "startTime",
    "endTime",
    "title",
  ];

  return {
    options: pick(advancedDrop, dropOptionFields),
    drop: pick(advancedDrop && advancedDrop.drop, dropInputFields),
    variants: ((advancedDrop && advancedDrop.variants) || []).map((v) =>
      pick(v, dropInputFields)
    ),
  };
}
