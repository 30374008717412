export const trackError = (...m: any[]) => {
  try {
    (window as any).Rollbar.error(...m);
    console.error(...m);
  } catch (e) { }
};

export const trackWarning = (...m: any[]) => {
  try {
    (window as any).Rollbar.warning(...m);
    console.warn(...m);
  } catch (e) { }
};

export const trackInfo = (...m: any[]) => {
  try {
    // (window as any).Rollbar.info(...m);
    console.info(...m);
  } catch (e) { }
};

export const configureRollbar = (user: any) => {
  if (!user || !user.uid) return;
  try {
    const code_version = process.env.REACT_APP_GIT_SHA;
    console.log({ code_version });
    (window as any).Rollbar.configure({
      payload: {
        person: {
          id: user.uid,
          email: user.email
        },
        client: {
          javascript: {
            code_version,
            source_map_enabled: true,
            guess_uncaught_frames: true
          }
        }
      }
    });
  } catch (e) {
    console.log(e);
  }
};
