import React, { FC } from "react";
import { useAuth } from "../../context/auth";
import { ProfileImage } from "./ProfileImage";

export const UserInfoDisplay: FC = ({ children }) => {
  const { user } = useAuth();
  return user ? (
    <div className="d-flex flex-column align-items-center">
      <ProfileImage className="" />
      <div className="flex-grow-1 text-center">
        <h3 className="mt-1 mb-0 nowrap">{user.displayName || ""}</h3>
        <div className="mb-2 nowrap">{user.email || ""}</div>
      </div>
      {children}
    </div>
  ) : null;
};
