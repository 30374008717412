import React, { useState, useRef, useMemo } from "react";
import { Toc, useContentDialogContentStyles, LoadingButton } from "./MenuCard";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TablePagination from "@material-ui/core/TablePagination";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DialogContent from "@material-ui/core/DialogContent";
import { AdvancedListDetails } from "./AdvancedListDetails";
import { AdvancedStats } from "./AdvancedStats";
import { GeodropSummary } from "./Summary";
import { ScrollProvider } from "./TemplateForm";
import Code from "../Layout/Code";
import { TablePaginationActions, useTablePagination } from "../Layout";
import { AdvancedDropVariantPreviews } from "./Advanced";
import { QuadkeyMap, RadiusMap } from "../Map/Select";
import {
  ADVANCED_DROP_EXECUTION_PAGE,
  ADVANCED_DROP_EXECUTION,
  EXECUTE_ADVANCED_DROP,
  UPSERT_RING_RECENT,
} from "./gql";
import { useQuery, useMutation } from "@apollo/react-hooks";
import get from "lodash/fp/get";
import size from "lodash/size";
import CircularProgress from "@material-ui/core/CircularProgress";

const getId = get("id");
const get_Id = get("_id");
const getAdvancedDropExecutionLog = get("data.advancedDropExecution.log");
const getAdvancedDropExecutionData = get("advancedDropsExecution.data");
const getAdvancedDropExecutionPage = get("advancedDropsExecution.page");
const getAdvancedDropExecutionTotal = get("advancedDropsExecution.total");

export const AdvancedDropDebug = ({ id, advancedDrop, onCancel, loading }) => {
  const contentRef = useRef(null);
  const advancedId = getId(advancedDrop);
  const [fullMaphash, setFullMaphash] = useState("");
  const [location, setLocation] = useState(null);
  const [center, setCenter] = useState(null);
  const classes = useContentDialogContentStyles();
  const { page, perPage, setPage, setPerPage } = useTablePagination({
    page: 1,
    perPage: 10,
  });
  const variables = {
    page,
    perPage,
    search: {
      advancedId: { EQ: advancedId },
    },
  };
  const {
    selectedExecution,
    setSelectedExecution,
    advancedDropExecution,
    advancedDropExecutionPage: { data, refetch, networkStatus },
  } = useDebugLog({
    variables,
    skip: !advancedId,
  });
  const [upsertRingRecent, ringRecent] = useMutation(UPSERT_RING_RECENT, {
    variables: {
      advancedId,
    },
  });
  const [executeAdvancedDrop, execution] = useMutation(EXECUTE_ADVANCED_DROP, {
    onCompleted: () => refetch({ variables }),
    variables: {
      advancedId,
    },
  });
  const handleExecute = async () => {
    console.log(fullMaphash, center, location);
    const [longitude, latitude] = center || location;
    const input = {
      fullMaphash,
      longitude,
      latitude,
    };
    await upsertRingRecent({
      variables: { input },
    });
    console.log("Location set, executing drop");
    await executeAdvancedDrop({});
    console.log("Drop executed");
  };
  return (
    <>
      {(networkStatus === 1 || networkStatus === 2) && <LinearProgress />}
      <DialogContent className={classes.content} ref={contentRef}>
        <div className="row flex-md-nowrap">
          <div className="col mb-4">
            <Toc>
              <AdvancedListDetails
                advancedDrop={advancedDrop}
                loading={loading}
              />
              <div className="position-relative my-4" style={{ height: 200 }}>
                {advancedId && (
                  <QuadkeyMap
                    precision={20}
                    id="ring-location"
                    value={fullMaphash}
                    userCenter={true}
                    userMarker={true}
                    watchUser={setLocation}
                    onChange={setFullMaphash}
                    onMapDrag={setCenter}
                  />
                )}
                <div className="crosshair-h" />
                <div className="crosshair-v" />
              </div>
              <LoadingButton
                fullWidth
                variant="contained"
                disabled={!(fullMaphash && (center || location))}
                loading={ringRecent.loading || execution.loading}
                onClick={handleExecute}
              >
                Execute
              </LoadingButton>
              {/* <AdvancedDropVariantPreviews
                advancedDrop={advancedDrop}
                loading={loading}
                advancedDrop
                className="d-none d-md-block"
              /> */}
            </Toc>
          </div>
          <div className="col-12 col-md-8">
            <Toc>
              <DebugLog
                data={data}
                variables={variables}
                networkStatus={networkStatus}
                advancedDropExecution={advancedDropExecution}
                selectedExecution={selectedExecution}
                setSelectedExecution={setSelectedExecution}
              />
            </Toc>
          </div>
        </div>
      </DialogContent>
    </>
  );
};

export const useDebugLog = (options) => {
  const [selectedExecution, setSelectedExecution] = useState(null);
  const advancedDropExecution = useQuery(ADVANCED_DROP_EXECUTION, {
    variables: {
      id: get_Id(selectedExecution),
    },
    skip: !get_Id(selectedExecution),
  });
  const advancedDropExecutionPage = useQuery(ADVANCED_DROP_EXECUTION_PAGE, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });
  return {
    selectedExecution,
    setSelectedExecution,
    advancedDropExecution,
    advancedDropExecutionPage,
  };
};

export const DebugLog = ({
  data,
  networkStatus,
  advancedDropExecution,
  selectedExecution,
  setSelectedExecution,
}) => {
  const executions = getAdvancedDropExecutionData(data) || [];
  const remoteRowCount = getAdvancedDropExecutionTotal(data) || 0;
  const classes = useContentDialogContentStyles();
  const {
    page,
    perPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useTablePagination();
  return (
    <>
      {networkStatus === 1 || networkStatus === 2 || networkStatus === 4 ? (
        <LinearProgress />
      ) : null}
      {networkStatus !== 1 && networkStatus !== 2 && !executions.length ? (
        <Card>
          <CardContent className="py-5">
            <Typography variant="h6" align="center" className="mb-0">
              This advanced geodrop had no previous executions.
            </Typography>
          </CardContent>
        </Card>
      ) : (
        executions.map((execution) => {
          return (
            <ExpansionPanel
              className="w-100"
              key={get_Id(execution)}
              expanded={get_Id(selectedExecution) === get_Id(execution)}
              onClick={() =>
                setSelectedExecution((v) =>
                  v === execution ? null : execution
                )
              }
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={execution._id}
                id={execution._id}
              >
                <Box display="flex" alignItems="center" width="100%">
                  {execution.status === "executing" ? (
                    <CircularProgress
                      className="mr-1"
                      style={{ height: 16, width: 16 }}
                    />
                  ) : null}
                  <span>
                    <Typography className={classes.heading}>
                      Total Spent {execution.total}{" "}
                    </Typography>
                    <Typography className={classes.heading}>
                      Total Campaigns {size(execution.advancedIds)}{" "}
                    </Typography>
                    <Typography className={classes.heading}>
                      Execution Time{" "}
                      {(execution.executionMs / 1000).toLocaleString(
                        undefined,
                        {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        }
                      )}{" "}
                      seconds{" "}
                    </Typography>
                  </span>
                  <span className="flex-grow-1" />
                  <ShortMobileDate value={execution.executionDate} />
                </Box>
              </ExpansionPanelSummary>
              {get_Id(selectedExecution) === get_Id(execution) &&
                advancedDropExecution.loading && <LinearProgress />}
              <ExpansionPanelDetails
                style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
                onClick={(ev) => ev.stopPropagation()}
              >
                {get_Id(selectedExecution) === get_Id(execution) &&
                getAdvancedDropExecutionLog(advancedDropExecution) ? (
                  <pre className="text-white m-2">
                    <code>
                      {getAdvancedDropExecutionLog(advancedDropExecution)}
                    </code>
                  </pre>
                ) : (
                  <div />
                )}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        })
      )}

      {remoteRowCount ? (
        <TablePagination
          component="div"
          rowsPerPageOptions={[10]}
          count={remoteRowCount}
          rowsPerPage={perPage}
          page={page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        ></TablePagination>
      ) : null}
    </>
  );
};

const ShortMobileDate = ({ value, defaultValue = "" }) => {
  const date = value && new Date(value);
  return date ? (
    <>
      <span className="d-md-none">{date.toLocaleDateString()}</span>
      <span className="d-none d-md-inline">{date.toLocaleString()}</span>{" "}
    </>
  ) : (
    <span>{defaultValue}</span>
  );
};
