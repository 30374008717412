import React from "react";
import termsImg1 from "./termsImg_1.jpg";
import termsImg2 from "./termsImg_2.jpg";
import termsImg3 from "./termsImg_3.jpg";
import { Footer, FooterLinks } from "../../components/Layout";

export default () => {
  return (
    <div className="bg-white">
      <div className="container">
        <img
          src={termsImg1}
          className="w-100"
          alt="COIN Boss Ad Policy Page 1 of 3"
        />
        <img
          src={termsImg2}
          className="w-100"
          alt="COIN Boss Ad Policy Page 2 of 3"
        />
        <img
          src={termsImg3}
          className="w-100"
          alt="COIN Boss Ad Policy Page 3 of 3"
        />
      </div>
      <Footer />
      <div className="position-relative bg-dark">
        <FooterLinks />
      </div>
    </div>
  );
};
