import React, { useEffect, useState } from "react";
import hljs from "highlight.js";
import "highlight.js/styles/kimbie.dark.css";

// let _hljs: any, _html: any;

interface IProps {
  content: string;
  className?: string;
}

// async function initialize() {
//   if (_hljs) return _hljs;
//   const [hljs] = await Promise.all([
//     import("highlight.js"),
//     require("highlight.js/styles/kimbie.dark.css")
//   ]);
//   _hljs = hljs;
//   return _hljs;
// }

export default ({ className, content }: IProps) => {
  const [__html, setHtml] = useState("");
  useEffect(() => {
    const { value } = hljs.highlightAuto(content);
    setHtml(value);
  }, []);

  return (
    <pre className={className}>
      <code dangerouslySetInnerHTML={{ __html }}></code>
    </pre>
  );
};
