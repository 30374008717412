import React, { useState, createContext, useContext, FC } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import {
  ErrorDisplay,
  InfoDisplay,
  SuccessDisplay
} from "../components/Geodrops/MenuCard";

const AlertContext = createContext<
  [
    {
      info?: string;
      error?: Error | string;
      success?: string;
    },
    {
      setInfo: (e: any) => void;
      setError: (message: string) => void;
      setSuccess: (message: string) => void;
    }
  ]
>([
  {
    info: "",
    success: "",
    error: ""
  },
  {
    setInfo: () => console.warn("AlertProvider not initialized"),
    setError: () => console.warn("AlertProvider not initialized"),
    setSuccess: () => console.warn("AlertProvider not initialized")
  }
]);

export const useAlertContext = () => useContext(AlertContext);

export const AlertProvider: FC = ({ children }) => {
  const [info, setInfo] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  return (
    <AlertContext.Provider
      value={[
        { info, error, success },
        { setInfo, setError, setSuccess }
      ]}
    >
      {children}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={!!error || !!info || !!success}
        autoHideDuration={6000}
        onClose={() => {
          setError("");
          setSuccess("");
          setInfo("");
        }}
      >
        {error ? (
          <ErrorDisplay error={error} className="" />
        ) : info ? (
          <InfoDisplay message={info} className="" />
        ) : success ? (
          <SuccessDisplay message={success} className="" />
        ) : null}
      </Snackbar>
    </AlertContext.Provider>
  );
};
