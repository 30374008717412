export function initializeMapbox() {
  if ('mapboxgl' in window) return
  return Promise.all([
    appendStylesheet('https://api.mapbox.com/mapbox.js/v3.0.1/mapbox.css'),
    appendStylesheet(
      'https://api.tiles.mapbox.com/mapbox-gl-js/v0.52.0/mapbox-gl.css',
    ),
    appendScript(
      'https://api.tiles.mapbox.com/mapbox-gl-js/v0.52.0/mapbox-gl.js',
    ),
    appendScript('https://api.mapbox.com/mapbox.js/v3.0.1/mapbox.js'),
    appendScript(
      'https://api.mapbox.com/mapbox.js/plugins/turf/v3.0.11/turf.min.js',
    ),
  ])
}

export async function initializeGoogleMaps() {
  if ('google' in window) return
  return appendScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS}&libraries=places`,
  )
}

function appendStylesheet(href) {
  const link = document.createElement('link')
  link.rel = 'stylesheet'
  link.href = href
  document.head.appendChild(link)
}

function appendScript(src) {
  return new Promise((res, rej) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => res()
    script.onerror = () => rej()
    document.body.appendChild(script)
  })
}
