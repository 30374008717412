import React, { useState, FC } from "react";
import {
  Footer,
  FooterLinks,
  SuccessContent,
  AddFab
} from "../../components/Layout";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import {
  ActionDialog,
  LoadingButton
} from "../../components/Geodrops/MenuCard";
import { useBooleanControls } from "../../utils/hooks";
import { parseError, emailRegEx } from "../../utils";
import {
  AccountUsersList,
  SelectAccount,
  ACCOUNT_INVITE,
  ACCOUNTS
} from "../../components/User";
import { SlideInUp } from "../../components/Animate";
import { useMutation } from "react-apollo";
import Paper from "@material-ui/core/Paper";

export default () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [open, modalControls] = useBooleanControls(false);
  const [completed, completedControls] = useBooleanControls(false);
  const [inviteToAccount, { loading, error }] = useMutation(ACCOUNT_INVITE);
  const onSubmit = async (ev: any) => {
    ev.preventDefault();
    if (!email) {
      setMessage("Email is required");
      return;
    }
    if (!emailRegEx.test(email)) {
      setMessage("Invalid email");
      return;
    }
    setMessage("");
    await inviteToAccount({
      variables: { email },
      refetchQueries: [{ query: ACCOUNTS }]
    });
    completedControls.setTrue();
  };
  const closeModal = () => {
    modalControls.setFalse();
    completedControls.setFalse();
  };
  return (
    <>
      <div className="my-5" />
      <div className="container" style={{ paddingBottom: "15vh" }}>
        <Paper>
          <SelectAccount />
          <AccountUsersList />
        </Paper>
        <div className="my-5 text-center">
          <AddFab onClick={() => modalControls.setTrue()} />
        </div>
      </div>
      <Footer />
      <FooterLinks />
      <ActionDialog
        title="Invite User"
        open={open}
        loading={false}
        onCancel={closeModal}
        cancelText={completed ? "Done" : "Cancel"}
        maxWidth="sm"
        content={
          <CardContent>
            {completed ? (
              <SlideInUp delay={0} config={{}}>
                <SuccessContent>
                  <h4 className="mb-4">Success</h4>
                </SuccessContent>
              </SlideInUp>
            ) : (
              <form onSubmit={onSubmit} id="invite-user">
                <TextField
                  name="email"
                  label="Email"
                  className="mb-2"
                  placeholder="example@gmail.com"
                  value={email}
                  onChange={ev => setEmail(ev.target.value)}
                  fullWidth
                />

                <p className="text-center text-danger mb-0">
                  {parseError(message || error)}
                </p>
              </form>
            )}
          </CardContent>
        }
        action={
          completed ? (
            <></>
          ) : (
            <LoadingButton
              type="submit"
              loading={loading}
              disabled={false}
              variant="contained"
              color="secondary"
              className="text-white"
              form="invite-user"
            >
              Invite
            </LoadingButton>
          )
        }
      />
    </>
  );
};
