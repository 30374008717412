import React, { Suspense } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import {
  AuthProvider,
  BossProvider,
  useAuth,
  InvitedRoute,
  InvitedAdminRoute,
  AlertProvider,
} from "./context";
import coinTheme from "@xyo-network/coin-material-ui/build/themes/coin";
import {
  Dash,
  Beta,
  Auth,
  Account,
  Details,
  UpdateDetails,
  Invitation,
  Terms,
} from "./routes";
import { Navbar, AdvancedDropBottomNavProvider } from "./components/Layout";
import { BalanceTransferProvider } from "./components/User/BalanceTransfer";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import CssBaseline from "@material-ui/core/CssBaseline";
import LinearProgress from "@material-ui/core/LinearProgress";
import MomentUtils from "@date-io/moment";

const Admin = React.lazy(() => import("./routes/Admin"));
const AdminAuctions = React.lazy(() => import("./routes/AdminAuctions"));
const AdminPriceConversion = React.lazy(
  () => import("./routes/AdminPriceConversion")
);

export const Boss = () => {
  return (
    <Switch>
      <Route path="/auth" component={Auth} exact />
      <Route path="/beta" component={Beta} exact />
      <Route path="/terms" component={Terms} exact />
      <Route path="/invitation" component={Invitation} exact />
      <InvitedRoute path="/account" component={Account} exact />
      <InvitedAdminRoute path="/admin/price" exact>
        <Suspense fallback={<div />}>
          <AdminPriceConversion />
        </Suspense>
      </InvitedAdminRoute>
      <InvitedAdminRoute path="/admin/auctions" exact>
        <Suspense fallback={<div />}>
          <AdminAuctions />
        </Suspense>
      </InvitedAdminRoute>
      <InvitedAdminRoute path="/admin">
        <Suspense fallback={<div />}>
          <Admin />
        </Suspense>
      </InvitedAdminRoute>
      <InvitedRoute path="/" component={Dash} />
    </Switch>
  );
};

export const BossLoader = () => {
  const { userInfo } = useAuth();
  if (userInfo !== null) return null;
  return <LinearProgress />;
};

export default () => {
  return (
    <MuiThemeProvider theme={coinTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <CssBaseline />
        <BrowserRouter>
          <BossProvider>
            <AuthProvider>
              <AlertProvider>
                <BalanceTransferProvider>
                  <AdvancedDropBottomNavProvider>
                    <BossLoader />
                    <Navbar />
                    <Boss />
                  </AdvancedDropBottomNavProvider>
                </BalanceTransferProvider>
              </AlertProvider>
            </AuthProvider>
          </BossProvider>
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};
